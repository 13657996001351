import { fsTypeDropbox, FS, DropboxConfig, fsCopyProps } from "./fs";
import {
  len,
  sleep,
  startTimer,
  inflect,
  throwIf,
  dateStringToMs,
} from "./util";
import * as filepath from "./filepath";
import { getDropboxClient } from "./fs-dropbox-login";
import { FSEntry, newDirFSEntry } from "./fsentry";

let disablePreCaching = true;

class FileSystemDropbox extends FS {
  _dbx = null;

  /** @type {DropboxConfig} */
  constructor(config) {
    super(fsTypeDropbox);
    this.dropboxConfig = config;
  }

  async getDbx() {
    if (this._dbx) {
      return this._dbx;
    }
    const c = this.dropboxConfig;
    this._dbx = await getDropboxClient(c.accessToken, c.refreshToken);
    return this._dbx;
  }

  /**
   * read fs entries in a given directory
   * @param {string} dirPath - directory name
   * @param {boolean} force - if true, don't use cached values
   * @param {import("svelte/store").Writable} progress
   * @return {Promise<FSEntry[]>}
   */
  async readDir(dirPath, force, progress) {
    console.log("FileSystemDropbox.readDir:", dirPath);

    progress.set(
      `reading dir ${dirPath}, ${this.dirs.size} dirs, ${this.filesCount} files`
    );
    if (!force) {
      const de = this.dirs.get(dirPath);
      if (de) {
        console.log(`FileSystemDropbox.get: got from cache for ${dirPath}`);
        return de.fsentries;
      }
    }

    const dur = startTimer();
    const dbx = await this.getDbx();
    let entries = await dbxGetEntriesInDir(dbx, dirPath);
    console.log(
      `FileSystemDropbox.readDir, dbxGetEntriesInDir: got ${len(
        entries
      )} entries for ${dirPath} in ${dur()} ms`
    );

    let dir = newDirFSEntry(dirPath, dbxEntriesToFSEntries(entries));

    this.cacheDir(dir);
    if (!disablePreCaching && dirPath == "/" && this.dirs.size == 1) {
      this.readDirRecur(["/"]);
    }
    return dir.fsentries;
  }

  /**
   * get publicly visible URL
   * @param {string} path
   */
  async getPublicURL(path) {
    console.log(`FSDropbox.getPublicURL: path=${path}`);

    // TODO: maybe cache the links?
    // https://dropbox.github.io/dropbox-sdk-js/Dropbox.html#filesGetTemporaryLink__anchor
    let arg = {
      path: path,
    };
    const dbx = await this.getDbx();
    let rsp = await dbx.filesGetTemporaryLink(arg);
    console.log("rsp:", rsp);
    let result = rsp.result;
    console.log("result:", result);
    return result.link;
  }

  /**
   * Generic download function that works using getPublicURL
   * Some filesystems might implement optimized version
   * @param {string} path
   * @param {import("./http").HttpProgress} httpProgress
   * @returns {Promise<Blob>}
   */
  async downloadFile(path, httpProgress) {
    console.log(`FSDropbox.downloadFile: path=${path}`);
    // https://dropbox.github.io/dropbox-sdk-js/Dropbox.html#filesDownload__anchor
    // https://dropbox.github.io/dropbox-sdk-js/global.html#FilesDownloadArg
    let arg = {
      path: path,
    };
    const dbx = await this.getDbx();
    let rsp = await dbx.filesDownload(arg);
    // fileBlob is not documented in API docs
    let blob = rsp.result.fileBlob;
    return blob;
  }

  /**
   * @param {string} path
   * @param {Blob} file
   * @param {import("./http").HttpProgress} httpProgress
   */
  async uploadFile(path, file, httpProgress) {
    let mode = {
      ".tag": "add",
    };
    let arg = {
      contents: file,
      path: path,
      mode: mode,
      autorename: true,
    };
    // https://dropbox.github.io/dropbox-sdk-js/Dropbox.html#filesUpload
    const dbx = await this.getDbx();
    await dbx.filesUpload(arg);
  }

  /**
   * rename a file
   * @param {string} path
   * @param {string} newPath
   */
  async rename(path, newPath) {
    console.log(`FSDropbox.rename: path=${path}, newPath=${newPath}`);
    throwIf(path == newPath, "path must be != newPath");
    // https://dropbox.github.io/dropbox-sdk-js/Dropbox.html#filesMoveV2__anchor
    let arg = {
      from_path: path,
      to_path: newPath,
      autorename: true,
      allow_ownership_transfer: false,
    };
    const dbx = await this.getDbx();
    let rsp = await dbx.filesMoveV2(arg);
    console.log("rsp:", rsp);
    let result = rsp.result;
    console.log("result:", result);
  }

  /**
   * create a new directory
   * @param {string} dirPath - full path for the directory
   */
  async createDirectory(dirPath) {
    let arg = {
      path: dirPath,
      autorename: true,
    };
    console.log("before dbx.filesCreateFolderV2 path:", dirPath);
    // https://dropbox.github.io/dropbox-sdk-js/Dropbox.html#filesUpload
    const dbx = await this.getDbx();
    let res = await dbx.filesCreateFolderV2(arg);
    console.log("after dbx.filesCreateFolderV2: res:", res);
  }

  // TODO: use a different method
  /**
   * @param {string} path
   */
  async deleteFile(path) {
    let paths = [path];
    let entries = [];
    for (let p of paths) {
      p = filepath.stripDirSlash(p);
      const e = {
        path: p,
      };
      entries.push(e);
    }
    let arg = {
      entries: entries,
    };
    const dbx = await this.getDbx();
    let rsp = await dbx.filesDeleteBatch(arg);
    let res = rsp.result; // FilesDeleteBatchLaunch
    console.log("filesDeleteBatch: rsp:", rsp);
    let tag = res[".tag"];
    if (tag == "complete") {
      console.log("complete:", res);
      // TODO: look at the result?
      return;
    }
    if (tag != "async_job_id") {
      // what is "other" tag?
      console.log("unexpected rsp:", res);
      throw new Error(`unsupported tag '${tag}'`);
    }
    const jobId = res["async_job_id"];
    let arg2 = {
      async_job_id: jobId,
    };
    let n = 0;
    while (n < 10) {
      const dbx = await this.getDbx();
      rsp = await dbx.filesDeleteBatchCheck(arg2);
      res = rsp.result;
      console.log("filesDeleteBatchCheck: rsp:", rsp);
      tag = res[".tag"];
      if (tag == "complete") {
        return;
      }
      if (tag == "failed") {
        // TODO: pass error message
        throw new Error("delete failed");
      }
      n++;
      await sleep(1000);
      console.log("re-trying dbx.filesDeleteBatchCheck() n:", n);
    }
    throw new Error("delete failed: taking too long");
  }

  /**
   * delete files and directories in this filesystem
   * @param {string[]} paths
   * @param {import("svelte/store").Writable} progress
   */
  async deleteFiles(paths, progress) {
    let entries = [];
    for (let p of paths) {
      p = filepath.stripDirSlash(p);
      const e = {
        path: p,
      };
      entries.push(e);
    }
    let arg = {
      entries: entries,
    };
    let nFiles = len(entries);
    let s = inflect("file", nFiles);
    progress.set(`deleting ${nFiles} ${s}`);
    const dbx = await this.getDbx();
    let rsp = await dbx.filesDeleteBatch(arg);
    let res = rsp.result; // FilesDeleteBatchLaunch
    console.log("filesDeleteBatch: rsp:", rsp);
    let tag = res[".tag"];
    if (tag == "complete") {
      console.log("complete:", res);
      // TODO: look at the result?
      return;
    }
    if (tag != "async_job_id") {
      // what is "other" tag?
      console.log("unexpected result:", res);
      throw new Error(`unsupported tag '${tag}'`);
    }
    const jobId = res["async_job_id"];
    let arg2 = {
      async_job_id: jobId,
    };
    let n = 0;
    while (n < 10) {
      const dbx = await this.getDbx();
      rsp = await dbx.filesDeleteBatchCheck(arg2);
      res = rsp.result;
      console.log("filesDeleteBatchCheck: rsp:", rsp);
      tag = res[".tag"];
      if (tag == "complete") {
        return;
      }
      if (tag == "failed") {
        // TODO: pass error message
        throw new Error("delete failed");
      }
      n++;
      await sleep(1000);
      console.log("re-trying dbx.filesDeleteBatchCheck() n:", n);
    }
    throw new Error("delete failed: taking too long");
  }
}

//  * @property {string} .tag - "directory" | "file"

/**
 * @typedef {Object} DropboxEntry
 * @property {string} name
 * @property {string} path_lower
 * @property {string} path_display
 * @property {string} id
 * @property {string} [client_modified] - "2019-10-07T05:44:01Z"
 * @property {string} [content_hash] - "b4b9617dafd82eca0bd597c98393e1804f4606cbfd393e46ac9a3e5afd5cb592"
 * @property {boolean} [is_downloadable]
 * @property {string} [rev]
 * @property {string} [server_modified] - "2019-10-07T05:44:01Z"
 * @property {number} [size]
 */

/**
 * @typedef ListFolderResult
 * @property {string} cursor
 * @property {boolean} has_more
 * @property {DropboxEntry[]} entries
 */

export const prop_dbx_rev = "rev";
export const prop_dbx_content_hash = "content_hash";
export const prop_dbx_client_modified = "client_modified";

const fileProps = [
  prop_dbx_rev,
  prop_dbx_content_hash,
  prop_dbx_client_modified,
];

/**
 * @param {*} dbx
 * @param {string} dirName
 * @returns {Promise<DropboxEntry[]>}
 */
async function dbxGetEntriesInDir(dbx, dirName) {
  if (dirName == "/") {
    dirName = "";
  }
  // https://dropbox.github.io/dropbox-sdk-js/global.html#FilesListFolderArg
  let arg = { path: dirName };
  // https://dropbox.github.io/dropbox-sdk-js/Dropbox.html#filesListFolder__anchor
  let rsp = await dbx.filesListFolder(arg);
  /** @type {ListFolderResult} */
  let result = rsp.result;
  let entries = result.entries;
  while (result.has_more) {
    let argCont = {
      cursor: result.cursor,
    };
    rsp = await dbx.filesListFolderContinue(argCont);
    result = rsp.result;
    entries.push(...result.entries);
  }
  return entries;
}

/**
 * @param {DropboxConfig} c
 */
export function makeFileSystemDropbox(c) {
  // console.log("makeFileSystemDropbox, config:", c);
  let fs = new FileSystemDropbox(c);
  return fs;
}

// set to false for debugging code that handles network failures
let disallowNoAccess = true;

function dbxEntriesToFSEntries(entries) {
  let res = [];
  for (let e of entries) {
    const si = e.sharing_info;
    if (disallowNoAccess && si && si.no_access) {
      continue;
    }
    const tag = e[".tag"];
    // console.log("e:", e);
    let isFile = tag === "file";
    let isFolder = tag === "folder";
    throwIf(!(isFile || isFolder), `unknown entry ${JSON.stringify(e)}`);
    let size = isFile ? e.size : -1;
    let lastMod = isFile ? dateStringToMs(e.server_modified) : 0;
    let entries = isFile ? null : [];
    let meta = [null, e.name, size, lastMod, entries];
    fsCopyProps(fileProps, e, meta);
    let fe = FSEntry.fromMeta(meta);
    res.push(fe);
  }
  return res;
}
