import { len, startTimer, throwIf } from "./util";
import { apiFetch, mkURL, throwIfFetchFailed, xhrPut } from "./http";
import { fsTypeS3, FS } from "./fs";
import { FSEntry, newDirFSEntry } from "./fsentry";

let disablePreCaching = true;

class FileSystemS3 extends FS {
  /**
   * @param {import("./fs").S3Config} s3Config
   */
  constructor(s3Config) {
    super(fsTypeS3);
    this.s3Config = s3Config;
  }

  /**
   * @param {string} dirPath - directory name
   * @param {boolean} force - if true, don't use cached values
   * @param {import("svelte/store").Writable} progress
   * @return {Promise<FSEntry[]>}
   */
  async readDir(dirPath, force, progress) {
    console.log("FileSystemS3.readDir:", dirPath);
    throwIf(dirPath == "", `empty dirName`);

    progress.set(
      `reading dir ${dirPath}, ${this.dirs.size} dirs, ${this.filesCount} files`
    );
    if (!force) {
      const de = this.dirs.get(dirPath);
      if (de) {
        console.log(`FileSystemS3.get: got from cache for ${dirPath}`);
        return de.fsentries;
      }
    }

    const dur = startTimer();
    let entries = await s3GetFiles(this.s3Config, dirPath);
    console.log(
      `"FileSystemS3.readDir: got ${len(
        entries
      )} entries for ${dirPath} in ${dur()} ms`
    );
    let dir = newDirFSEntry(dirPath, entries);
    this.cacheDir(dir);

    if (!disablePreCaching && dirPath == "/" && this.dirs.size == 1) {
      this.readDirRecur(["/"]);
    }
    return dir.fsentries;
  }

  /**
   * @param {string} path
   * @returns {Promise<string>}
   */
  async getPublicURL(path) {
    console.log(`FSS3.getPublicURL: path=${path}`);

    // TODO: maybe cache the links?
    let c = this.s3Config;
    let url = mkAuthURL(c, `/api/s3/getpublicurl`, "path", path);
    let response = await apiFetch(url);
    throwIfFetchFailed(response);
    let js = await response.json();
    url = js.url;
    console.log("FSS3.getPublicURL: url:", url);
    url = mkURL(`https://workers.filerion.com/api/fetch`, "url", url);
    console.log("FSS3.getPublicURL: url proxied by fetch:", url);
    return url;
  }

  /**
   * @param {string} path
   * @param {Blob} file
   * @param {import("./http").HttpProgress} httpProgress
   */
  async uploadFile(path, file, httpProgress) {
    console.log("s3.uploadFile: ${path}");
    path = s3SanitizePath(path);
    let c = this.s3Config;
    let url = mkAuthURL(c, `/api/s3/upload`, "path", path);
    await xhrPut(url, file, httpProgress);
  }

  /**
   * @param {string} path
   * @param {string} newPath
   */
  async rename(path, newPath) {
    console.log(`s3.rename: path=${path}, newPath=${newPath}`);
    throwIf(path == newPath, "path must be != newPath");
    let c = this.s3Config;
    let url = mkAuthURL(c, `/api/s3/rename`, "path", path, "newPath", newPath);
    let response = await apiFetch(url);
    throwIfFetchFailed(response);
  }

  /**
   * @param {string} path - full path for the directory
   */
  async createDirectory(path) {
    console.log(`s3.createDirectory: path=${path}`);
    let c = this.s3Config;
    let url = mkAuthURL(c, `/api/s3/createdirectory`, "path", path);
    let response = await apiFetch(url);
    throwIfFetchFailed(response);
  }

  /**
   * @param {string} path
   */
  async deleteFile(path) {
    path = s3SanitizePath(path);
    let c = this.s3Config;
    let url = mkAuthURL(c, `/api/s3/deletefile`, "path", path);
    console.log(`s3.deleteFile: path: ${path}, uri: ${url}`);
    let rsp = await apiFetch(url);
    await throwIfFetchFailed(rsp);
  }
}

/**
 * @param {import("./fs").S3Config} opts
 * @param {string} url
 * @param {string[]} args
 * @returns {string}
 */
function mkAuthURL(opts, url, ...args) {
  let params = [
    "secret",
    opts.secret,
    "access",
    opts.access,
    "bucket",
    opts.bucket,
    "endpoint",
    opts.endpoint,
  ];
  params.push(...args);
  return mkURL(url, ...params);
}

export function s3SanitizePath(s) {
  s = s || "";
  if (s.startsWith("/")) {
    return s.substring(1);
  }
  return s;
}

export function s3SanitizeDir(s) {
  s = s3SanitizePath(s);
  if (s != "" && !s.endsWith("/")) {
    return (s += "/");
  }
  return s;
}

export async function testS3Connection(S3Config) {
  let url = mkAuthURL(S3Config, `/api/s3/test`);
  console.log("testS3:", url);
  return await apiFetch(url);
}

/**
 * @param {import("./fs").S3Config} c
 * @param {string} dir
 * @returns {Promise<FSEntry[]>}
 */
async function s3GetFiles(c, dir) {
  dir = s3SanitizeDir(dir);
  let url = mkAuthURL(c, `/api/s3/list`, "dir", dir);
  console.log(`s3.getFiles: dirName: '${dir}'`);
  let rsp = await apiFetch(url);
  await throwIfFetchFailed(rsp);
  let entriesMeta = await rsp.json();
  /** @type {FSEntry[]} */
  let res = [];
  for (let meta of entriesMeta) {
    let e = FSEntry.fromMeta(meta);
    res.push(e);
  }
  return res;
}

/**
 * @param {import("./fs").S3Config} s3Config
 * @returns {FileSystemS3}
 */
export function makeFileSystemS3(s3Config) {
  return new FileSystemS3(s3Config);
}
