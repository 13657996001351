<script>
  import {
    createEventDispatcher,
    beforeUpdate,
    afterUpdate,
    setContext,
  } from "svelte";
  import { writable } from "svelte/store";

  import SortAscending from "./icons/SortAscending.svelte";
  import SortDescending from "./icons/SortDescending.svelte";
  import Spinner from "./Spinner.svelte";
  import FileListTopNav from "./FileListTopNav.svelte";
  import ContextMenu from "./ContextMenu.svelte";
  import Overlay from "./Overlay.svelte";
  import DialogDelete from "./DialogDelete.svelte";
  import DialogUploadFiles from "./DialogUploadFiles.svelte";
  import DialogNewDirectory from "./DialogNewDirectory.svelte";
  import { menuItemSeparator } from "./MenuItem";
  import ShowImage from "./ShowImage.svelte";
  import DialogRename from "./DialogRename.svelte";
  import DialogDownload from "./DialogDownload.svelte";
  import DialogFileProperties from "./DialogFileProperties.svelte";
  import ShowText from "./ShowText.svelte";
  import { MenuItem } from "./MenuItem";

  import Close from "./icons/Close.svelte";
  import File from "./icons/File.svelte";
  import Folder from "./icons/Folder.svelte";

  import {
    len,
    fmtSize,
    inflect,
    copyTextToClipboard,
    getAllFileEntries,
    truncatedText,
    isDev,
    fmtTimeMs,
  } from "./util";
  import * as filepath from "./filepath";
  import * as array from "./array";
  import { sortDown, sortUp, sortNone, sortFiles } from "./sort";
  import { showTemporaryMessage } from "./message";
  import {
    FileInfo,
    fsTypeDropbox,
    fsTypeGoogleDrive,
    fsTypeLocal,
    fsTypeOneDrive,
    fsTypeS3,
  } from "./fs";
  import { NodesCollection, remember } from "./actions/measure";
  import { focus } from "./actions/focus";
  import { setShowUploadInfo, showUploadInfo, canCopyMove } from "./store";
  import { bookmarkFromFSState } from "./bookmarks";
  import { captureEvent } from "./http";
  import DialogCopyMoveFile from "./DialogCopyMoveFile.svelte";
  import { CopyInfo, fsCopyMove } from "./fs-copy";
  import { newDirFSEntry } from "./fsentry";

  /**
   * @typedef {import('./fsentry').FSEntry} FSEntry
   */

  const dispatch = createEventDispatcher();

  /** @type {import("./fs").FSDirState} */
  export let fsState;
  export let currBookmark = bookmarkFromFSState(fsState);
  export let pickOtherFSState;

  fsState.onReloadCurrentDir = () => {
    reloadCurrentDir();
  };

  let fs = fsState.fs;
  let currDirFull = fsState.currDir; // TODO: better name

  let showingTextPreview = false;
  /** @type {?string} */
  let textPreviewURL = null;
  /** @type {?string} */
  let textPreviewName = null;

  let showingImagePreview = false;
  /** @type {?string} */
  let imagePreviewURL = null; // = "https://www.sumatrapdfreader.org/img/homepage.png";
  /** @type {?string} */
  let imagePreviewName = null;
  /** @type {?string} */
  let urlToInvoke = null;

  // console.log("FielList: fs:", fs);
  let nameNodes = new NodesCollection();
  let sizeNodes = new NodesCollection();
  let lastModNodes = new NodesCollection();

  // maps unique fsentry id (name) to FSEntry
  let selectedEntries = {};
  // keeps track of last selection
  // TODO: need to fix after sorting
  let lastSelectedIdx = -1;

  /** @type {HTMLInputElement} */
  let filesEl;

  let progressMsg = writable("");
  export let errorMsg = "";

  /** @type {FSEntry[]} */
  let entries = [];
  /** @type {FSEntry[]} */
  let sortedEntries = [];

  let nameSort = sortNone;
  let sizeSort = sortNone;
  let lastModSort = sortNone;

  let nFiles = 0;
  let nDirs = 0;
  let totalSize = 0;

  $: sortEntries(nameSort, sizeSort, lastModSort);
  $: updateStatusString(selectedEntries);

  /*
  const fileCopySubMenu = [
    { label: "File Name", handler: cmdCopyFileNames },
    { label: "File Path", handler: cmdCopyFilePaths },
  ];
  */

  let currentContextMenu;

  function filesSelected(ev) {
    // console.log("filesSelected:", ev);
    // console.log("files:", filesEl.files);
    filesToUpload = [];
    for (let f of filesEl.files) {
      /** @type {FileInfo} */
      let fi = new FileInfo();
      fi.file = f;
      fi.name = f.name;
      fi.size = f.size;

      filesToUpload.push(fi);
      console.log(`file: ${f.name}, fullName: ${fi.name}`);
    }
    showDialogUploadFiles = true;
  }

  function dismissContextMenu() {
    console.log("dismissContextMenu");
    currentContextMenu = null;
  }

  setContext("fnDismissContextMenu", dismissContextMenu);

  function hideImagePreview() {
    console.log("hideImagePreview");
    // imagePreviewURL = null;
    showingImagePreview = false;
    if (urlToInvoke) {
      URL.revokeObjectURL(urlToInvoke);
      urlToInvoke = null;
    }
  }

  function hideTextPreview() {
    console.log("hideTextPreview");
    showingTextPreview = false;
    // textPreviewURL = null;
    textPreviewName = null;
    if (urlToInvoke) {
      URL.revokeObjectURL(urlToInvoke);
      urlToInvoke = null;
    }
  }

  function onBeforeUpdate() {
    // TODO: this triggers when doing selection
    // console.log("beforeUpdate");
    // nameNodes.clear();
    // sizeNodes.clear();
  }

  function onAfterUpdate() {
    // console.log(`afterUpdate: ${len(nameNodes.nodes)} nodes`);
    let tableDx = elTable.clientWidth;
    let tableParentDx = elTableParent.clientWidth;
    // console.log(`elTable       width: ${tableDx}`);
    // console.log(`elTableParent width: ${tableParentDx}`);
    let maxSizeDx = sizeNodes.maxWidth();
    let maxModTimeDx = lastModNodes.maxWidth();
    let namesDx = tableParentDx - maxSizeDx - maxModTimeDx - 2;
    if (namesDx < 160) {
      namesDx = 160;
    }
    // console.log(`max names: ${nameNodes.maxWidth()}`);
    // console.log(`max sizes: ${maxSizes}`);
    // console.log(`namesDx  : ${namesDx}`);
    if (tableDx - 3 <= tableParentDx) {
      return;
    }

    // console.log("resizing down to:", namesDx);
    // resize name elements
    for (let n of nameNodes.nodes) {
      n.style.width = namesDx + "px";
      n.style.maxWidth = namesDx + "px";
    }
  }

  beforeUpdate(onBeforeUpdate);
  afterUpdate(onAfterUpdate);

  function cmdRefresh() {
    console.log("cmdRefresh:");
    reloadCurrentDir();
  }

  /**
   * @param {string} s
   * @param {string[]} exts
   */
  function fileHasExt(s, exts) {
    s = s.toLowerCase();
    for (let ext of exts) {
      if (s.endsWith(ext)) {
        return true;
      }
    }
    return false;
  }

  async function previewTextFile(file) {
    let fileName = file.name;
    let path = filepath.join(fsState.currDir, fileName);
    progressMsg.set(`previewing text ${path}`);
    let url;
    try {
      url = await fs.getPublicURL(path);
    } catch (e) {
      errorMsg = e;
      progressMsg.set("");
      return;
    }

    progressMsg.set("");
    textPreviewURL = url;
    textPreviewName = fileName;
    if (fs.type == fsTypeLocal) {
      urlToInvoke = url;
    }
    let ext = filepath.ext(fileName);
    showingTextPreview = true;
    captureEvent("previewTextFile", { fs: fs.type, ext: ext });
  }

  async function previewImageFile(file) {
    let fileName = file.name;
    let path = filepath.join(fsState.currDir, fileName);
    progressMsg.set(`previewing image ${path}`);
    let url;

    try {
      url = await fs.getPublicURL(path);
    } catch (e) {
      progressMsg.set("");
      errorMsg = e;
      console.log("previewImageFile: getPublicURL failed with ", e);
      return;
    }
    progressMsg.set("");
    imagePreviewURL = url;
    imagePreviewName = fileName;
    if (fs.type == fsTypeLocal) {
      urlToInvoke = url;
    }
    let ext = filepath.ext(fileName);
    showingImagePreview = true;
    captureEvent("previewImageFile", { fs: fs.type, ext: ext });
  }

  function cmdViewFile() {
    console.log("cmdViewFile:");
    let files = contextMenuViewableImageFiles;
    if (len(files) > 0) {
      let file = files[0];
      previewImageFile(file);
      return;
    }
    files = contextMenuViewableTextFiles;
    console.log("viewableTextFiles:", files);
    if (len(files) > 0) {
      let file = files[0];
      previewTextFile(file);
      return;
    }
    // TODO: more, video and audio
  }

  function openFile(e) {
    if (fileHasExt(e.name, imgExts)) {
      previewImageFile(e);
      return;
    }
    if (fileHasExt(e.name, txtExts)) {
      previewTextFile(e);
      return;
    }
    // TODO: more
  }

  function findEntryIdxById(id) {
    let idx = -1;
    for (let e of sortedEntries) {
      ++idx;
      if (e.id == id) {
        return idx;
      }
    }
    return -1;
  }

  function findEntryById(id) {
    let idx = findEntryIdxById(id);
    if (idx >= 0) {
      return sortedEntries[idx];
    }
    return null;
  }

  /**
   * elements representing fsentries have data-id attribute which
   * is  unique id (name) of the file
   * @param {MouseEvent} ev
   */
  function getEntryFromContextMenuEvent(ev) {
    /** @type {HTMLElement}*/
    // @ts-ignore
    let el = ev.target;
    while (el) {
      if (el.id == "fm-file-list-wrap") {
        // reached wrapper element without finding any
        // console.log("reached wrapper element");
        return null;
      }
      let id = el.dataset.id;
      if (id) {
        return findEntryById(id);
      }
      el = el.parentElement;
    }
    return null;
  }

  /**
   * @param {string} s
   * @return {boolean}
   */
  function isPathDir(s) {
    return s.endsWith("/");
  }

  function cmdCopyFileNames() {
    console.log("cmCopyFileName:");
    let items = contextMenuEntries;
    if (len(items) == 0) {
      return;
    }
    let names = [];
    for (let e of items) {
      names.push(e.name);
    }
    let s = names.join("\n");
    copyTextToClipboard(s);
    let n = len(names);
    showTemporaryMessage(`Copied ${n} file ${inflect("name", n)} to clipboard`);
    captureEvent("copyFileNames", { fs: fs.type, count: n });
  }

  function cmdCopyFilePaths() {
    console.log("cmdCopyFilePaths:");
    let items = contextMenuEntries;
    if (len(items) == 0) {
      return;
    }
    let paths = [];
    for (let e of items) {
      let path = filepath.join(fsState.currDir, e.name);
      paths.push(path);
    }
    let s = paths.join("\n");
    copyTextToClipboard(s);
    let n = len(paths);
    showTemporaryMessage(`Copied ${n} file ${inflect("path", n)} to clipboard`);
    captureEvent("copyFilePaths", { fs: fs.type, count: n });
  }

  let showDialogNewDirectory = false;

  function cmdUploadFiles() {
    filesEl.click();
  }

  function cmdNewDirectory() {
    console.log("cmdNewDirectory");
    showDialogNewDirectory = true;
  }

  function newDirectoryAllowNested() {
    if (fs.type == fsTypeLocal) {
      return true;
    }
    return false;
  }

  setContext("fnNewDirectory", doNewDirectory);

  function doNewDirectory(dirName) {
    showDialogNewDirectory = false;
    console.log(`doNewDirectory: ${dirName}`);
    progressMsg.set(`Creating new directory ${dirName}`);
    fs.createDirectory(dirName).then(onDone).catch(fsOnError);
    function onDone() {
      console.log(`finished creating new directory`);
      progressMsg.set(null);
      reloadCurrentDirMaybeDelayed();
      captureEvent("createDirectory", { fs: fs.type });
    }
  }

  /** @type {FSEntry} */
  let entryToRename = null;
  let showingFileRename = false;
  /** @type {?string} */
  let filePathToRename = "";
  let fileRenameIgnoreCase = false;
  let fileRenameSameDir = false;

  function cmdRenameFile() {
    console.log("cmdRenameFile:");

    fileRenameIgnoreCase = false;
    switch (fs.type) {
      case fsTypeDropbox:
      case fsTypeLocal:
        fileRenameIgnoreCase = true;
    }

    fileRenameSameDir = false;
    switch (fs.type) {
      case fsTypeLocal:
      case fsTypeGoogleDrive:
      case fsTypeOneDrive: // TODO: support cross-dir rename
        fileRenameSameDir = true;
    }
    filePathToRename = filepath.join(fsState.currDir, entryToRename.name);
    showingFileRename = true;
  }

  setContext("fnRename", doRename);

  function doRename(o) {
    showingFileRename = false;
    filePathToRename = null;
    let { path, newPath } = o;
    console.log(`doRename: path=${path}, newPath=${newPath}`);
    progressMsg.set(`renaming ${path} to ${newPath}`);
    fs.rename(path, newPath).then(onDone).catch(fsOnError);
    function onDone() {
      console.log(`finished renaming files`);
      captureEvent("renameFiles", { fs: fs.type, path, newPath });
      progressMsg.set(null);
      reloadCurrentDirMaybeDelayed();
    }
  }

  let showingFileProperties = false;
  /** @type {FSEntry|null}*/
  let filePropertiesEntry = null;
  /** @type {string} */
  let filePropertiesPath = null;

  function cmdFileProperties() {
    console.log("cmdFileProperties");

    let e = firstFileEntry;
    filePropertiesPath = filepath.join(fsState.currDir, e.name);
    filePropertiesEntry = e;
    showingFileProperties = true;
  }

  let showDialogDownload = false;
  let filesToDownload = [];

  function cmdDownloadFiles() {
    console.log("cmdDownloadFiles");
    // TODO: for now, don't download directories
    let items = contextMenuFiles;
    if (len(items) == 0) {
      console.log("cmdDownloadFiles: no selected files");
      return;
    }
    let paths = [];
    for (let e of items) {
      let path = filepath.join(fsState.currDir, e.name);
      paths.push(path);
    }
    filesToDownload = paths;

    showDialogDownload = true;
  }

  function triggerURLDownload(url, name) {
    console.log("triggerURLDownload: url", url, "name:", name);
    // TODO: position off-screen?
    let el = document.createElement("a");
    el.setAttribute("href", url);
    el.setAttribute("download", name);
    // el.setAttribute("target", "_blank");
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
  }

  setContext("fnDownloadFiles", doDownload);

  async function doDownload(paths) {
    showDialogDownload = false;
    let nFiles = len(paths);
    console.log("doDownload:", paths, "nFiles:", nFiles);

    for (let path of filesToDownload) {
      progressMsg.set(`downloading ${path}`);
      let parts = filepath.split(path);
      let fileName = parts.name;

      let url;
      try {
        url = await fs.getPublicURL(path);
      } catch (e) {
        console.log(`doDownload: getPublicURL failed with`, e);
        progressMsg.set(null);
        errorMsg = e;
        return;
      }
      progressMsg.set(null);
      triggerURLDownload(url, fileName);
    }

    captureEvent("downloadFiles", { fs: fs.type, count: nFiles });
  }

  let showDialogDelete = false;
  /** @type {import("./fs").FileInfo[]} */
  let filesToDelete = [];

  function cmdDeleteFiles() {
    console.log("cmdDeleteFiles:");
    let items = contextMenuEntries;
    if (len(items) == 0) {
      console.log("cmdDeleteFiles: no selected files");
      return;
    }
    let paths = [];
    for (let e of items) {
      let path = filepath.join(fsState.currDir, e.name);
      if (e.isDir) {
        path += "/";
      }
      let fi = new FileInfo();
      fi.path = path;
      fi.name = e.name;
      fi.size = e.size;
      paths.push(fi);
    }
    filesToDelete = paths;
    showDialogDelete = true;
  }

  function reloadCurrentDirMaybeDelayed() {
    // delaying refreshing the files because there might be delay for s3
    // list to see the changes from delete
    // TODO: this is a work-around anyway. Maybe manually substract deleted
    // files and refresh in 10 secs
    let delay = fs.type == fsTypeS3;
    if (delay) {
      setTimeout(() => {
        reloadCurrentDir();
      }, 500);
    } else {
      reloadCurrentDir();
    }
  }

  /**
   * delete files / directories
   * @param {string[]} paths
   */
  function doDelete(paths) {
    showDialogDelete = false;
    console.log("doDelete:", paths);
    let nFiles = len(paths);
    progressMsg.set(`deleting ${nFiles} ${inflect("file", nFiles)}`);
    fs.deleteFiles(paths, progressMsg).then(onDone).catch(fsOnError);
    function onDone() {
      progressMsg.set(null);
      console.log(`finished deleting files`);
      reloadCurrentDirMaybeDelayed();
      captureEvent("deleteFiles", { fs: fs.type, count: nFiles });
    }
  }

  setContext("fnDeleteFiles", doDelete);

  function sortEntries(nameSort, sizeSort, lastModSort) {
    // console.log("sortEntries", nameSort, sizeSort, lastModSort);
    sortedEntries = [...entries];
    sortedEntries.push(newDirFSEntry(".."));
    sortFiles(sortedEntries, nameSort, sizeSort, lastModSort);
  }

  let elWrapper;

  function cycleSort(prevSort) {
    if (prevSort == sortNone && sizeSort != sortNone) {
      return sortUp;
    }
    if (prevSort == sortNone || prevSort == sortUp) {
      return sortDown;
    }
    return sortUp;
  }

  // mimicking how Windows File Explorer acts
  function cycleSortSize(prevSort) {
    if (prevSort == sortNone && nameSort != sortNone) {
      return sortUp;
    }
    if (prevSort == sortNone || prevSort == sortUp) {
      return sortDown;
    }
    return sortUp;
  }

  function changeNameSort() {
    let newSort = cycleSort(nameSort);
    console.log("changeNameSort:", newSort);
    nameSort = newSort;
    sizeSort = sortNone;
    lastModSort = sortNone;
    captureEvent("changesort", { type: "name" });
  }

  function changeSizeSort() {
    let newSort = cycleSortSize(sizeSort);
    console.log("changeSizeSort:", newSort);
    nameSort = sortNone;
    sizeSort = newSort;
    lastModSort = sortNone;
    captureEvent("changesort", { type: "size" });
  }

  function changeLastModSort() {
    let newSort = cycleSortSize(lastModSort);
    console.log("changeLastModSort:", newSort);
    nameSort = sortNone;
    sizeSort = sortNone;
    lastModSort = newSort;
    captureEvent("changesort", { type: "lastmod" });
  }

  let nSelectedFiles = 0;
  let nSelectedDirs = 0;
  let selectedSize = 0;
  function updateStatusString(selected) {
    nSelectedFiles = 0;
    nSelectedDirs = 0;
    selectedSize = 0;
    for (let e of Object.values(selected)) {
      if (e.isDir) {
        nSelectedDirs++;
      } else {
        nSelectedFiles++;
        // in Google Drive some files don't have sizes
        if (e.size) {
          selectedSize += e.size;
        }
      }
    }
  }

  function nameSuffix(e) {
    let suff = "";
    if (e.isDir && e.name != "..") {
      suff = "/";
    }
    // console.log("nameSuffix:", e, "suff:", suff);
    return suff;
  }

  function updateFileStats(entries) {
    console.log("updateFileStats");
    nFiles = 0;
    nDirs = 0;
    totalSize = 0;
    for (let e of entries) {
      if (e.isDir) {
        // TODO: maybe just decrement 1 becaues there's always one ".." dir
        if (e.name != "..") {
          nDirs++;
        }
      } else {
        nFiles++;
        totalSize += e.size | 0;
      }
    }
    console.log("nFiles:", nFiles, "nDirs:", nDirs);
    updateStatusString(selectedEntries);
  }

  function fsOnError(err) {
    progressMsg.set(null);
    let s = err.toString();
    if (err.status) {
      s = `Failed with status ${err.status}`;
    }
    errorMsg = s;
    console.error(s);
  }

  function filterEntries(entries) {
    let res = [];
    for (let e of entries) {
      if (e.name == ".keep.filerion") {
        continue;
      }
      res.push(e);
    }
    return res;
  }

  function onGotEntries(newEntries) {
    console.log(`onGotEntries: ${len(newEntries)} entries`);
    entries = filterEntries(newEntries);
    sortedEntries = [...entries];
    sortedEntries.push(newDirFSEntry(".."));
    sortFiles(sortedEntries, nameSort, sizeSort);
    updateFileStats(sortedEntries);

    selectedEntries = {};
    lastSelectedIdx = -1;
    if (len(sortedEntries) > 0) {
      lastSelectedIdx = 0;
      let e = sortedEntries[lastSelectedIdx];
      selectedEntries[e.id] = e;
    }
  }

  function reloadCurrentDir() {
    console.log("reloadCurrentDir()");
    tryReadDir(fsState.currDir, true);
  }

  tryReadDir(fsState.currDir, false);

  export function tryReadDir(dirName, force = false) {
    console.log(`tryChangeDir, dir: ${dirName}, force: ${force}`);

    captureEvent("changedir", {
      dir: dirName,
    });

    fsState.dirInProgress = dirName;

    // TODO: also restore lastSelectedIdx;
    let savedSelectedEntries = [];
    if (dirName == fsState.currDir) {
      savedSelectedEntries.push(...Object.values(selectedEntries));
    }
    function restoreSelection() {
      console.log("restoreSelection");
      function findEntry(e) {
        let i = 0;
        for (let e2 of sortedEntries) {
          if (e2.id == e.id) {
            return i;
          }
          i++;
        }
        return -1;
      }

      for (let e of savedSelectedEntries) {
        let idx = findEntry(e);
        if (idx >= 0) {
          const id = e.id;
          selectedEntries[id] = e;
          setNodeSelected(id, true);
        }
      }
    }

    function buildFullDir() {
      let s = fs.type;
      if (fs.type == fsTypeS3) {
        s += ":" + fs.s3Config.bucket;
      }
      if (fs.type == fsTypeLocal) {
        s = fs.name;
      }
      currDirFull = s + ":" + dirName;
    }

    let isCurrDirOk = errorMsg == "";

    fs.readDir(dirName, force, progressMsg).then(onDone).catch(onError);
    function onError(err) {
      fsState.isValidDir = false;
      err = unpackError(err);
      fsOnError(err);
      if (isCurrDirOk) {
        return;
      }
      // if we were in a bad directory, allow changing
      // (e.g. go up)
      console.log("onError but changing dir to:", dirName);
      fsState.currDir = dirName;
      buildFullDir();
    }
    function onDone(newEntries) {
      progressMsg.set(null);
      errorMsg = "";
      fsState.isValidDir = true;
      onGotEntries(newEntries);
      fsState.currDir = dirName;
      currBookmark.dir = fsState.currDir;

      buildFullDir();
      restoreSelection();
    }
  }

  function unpackError(err) {
    if (err.error) {
      // this might be DropboxResponseError
      let sum = err.error.error_summary;
      if (sum.startsWith("path/not_found/")) {
        return `Directory '${fsState.dirInProgress}' doesn't exist`;
      }
      console.log(`unpackError: summary: ${sum}`);
    }
    return err.toString();
  }

  function setNodeSelected(id, isSelected) {
    // console.log("setNodeSelected:", id, "isSelected:", isSelected);
    const el = elWrapper.querySelector(`[data-id="${id}"]`);
    if (!el) {
      console.log("didn't find node where data-id is", id);
      return;
    }
    if (isSelected) {
      el.classList.add("bg-sky-200");
    } else {
      el.classList.remove("bg-sky-200");
    }
  }

  function unselectAll() {
    for (let name in selectedEntries) {
      setNodeSelected(name, false);
    }
    selectedEntries = {};
  }

  function makeSingleSelected(idx) {
    // console.log("makeSingleSelected:", idx);
    const e = sortedEntries[idx];
    const id = e.id;
    // select a single element
    unselectAll();
    selectedEntries[id] = e;
    setNodeSelected(id, true);
    lastSelectedIdx = idx;
  }

  function markSelected(ev, idx) {
    // console.log(
    //   "markSelected:",
    //   idx,
    //   "id:",
    //   sortedEntries[idx].id,
    //   "detail:",
    //   ev.detail,
    //   "ctrl:",
    //   ev.ctrlKey
    // );

    const isDblClick = ev.detail == 2;
    if (isDblClick) {
      openSelected(idx);
      return;
    }

    // TODO: on ev.shiftKey should extend the selection
    if (ev.ctrlKey || ev.metaKey) {
      // adds to / removes from selection
      const e = sortedEntries[idx];
      const id = e.id;
      const isSelected = id in selectedEntries;
      if (isSelected) {
        delete selectedEntries[id];
        lastSelectedIdx = -1;
      } else {
        selectedEntries[id] = e;
        lastSelectedIdx = idx;
      }
      setNodeSelected(id, !isSelected);
    } else {
      makeSingleSelected(idx);
    }
    ev.stopPropagation();
  }

  function handleKeyDown(ev) {
    // console.log("FileList: handleKeyDown:", ev);
    if (ev.key == "Backspace") {
      dirUp();
      return;
    }
    if (len(sortedEntries) == 0) {
      return;
    }

    if (ev.key == "Enter") {
      if (lastSelectedIdx != -1) {
        openSelected(lastSelectedIdx);
      }
      return;
    }
    let currIdx = lastSelectedIdx;
    let newIdx = currIdx;
    if (ev.key == "ArrowUp" || ev.key == "k") {
      if (currIdx == -1) {
        newIdx = 0;
      } else {
        newIdx--;
      }
      ev.stopPropagation();
      ev.preventDefault();
    } else if (ev.key == "ArrowDown" || ev.key == "j") {
      newIdx++;
      ev.stopPropagation();
      ev.preventDefault();
    } else {
      // not a key we handle
      return;
    }
    const maxIdx = len(sortedEntries) - 1;
    if (newIdx < 0) {
      newIdx = 0;
    } else if (newIdx >= maxIdx) {
      newIdx = maxIdx;
    }
    makeSingleSelected(newIdx);

    const e = sortedEntries[newIdx];
    const id = e.id;
    const sel = `[data-id="${id}"]`;
    // TODO: narrow search to only the table for better perf
    const el = elTable.querySelector(sel);
    // TODO: better scrollIntoView when going up
    el.scrollIntoView(false);
  }

  /** @type {HTMLElement} */
  let elTable;
  /** @type {HTMLElement} */
  let elTableParent;

  function isSelected(idx) {
    const el = sortedEntries[idx];
    return el.id in selectedEntries;
  }

  function dirUp() {
    console.log("dirUp");
    if (fsState.currDir == "" || fsState.currDir == "/") {
      console.log("dirUp: dispatching showtoplevel");
      dispatch("showtoplevel"); // TODO: better name
      return;
    }
    console.log("dirUp 2");
    let { dir } = filepath.split(fsState.currDir);
    if (dir == "") {
      dir = "/";
    }
    tryReadDir(dir);
  }

  function openSelected(idx) {
    console.log("openSelected:", idx);
    let e = sortedEntries[idx];
    if (!e.isDir) {
      openFile(e);
      return;
    }
    if (e.name == "..") {
      dirUp();
      return;
    }
    let newDir = fsState.currDir + "/" + e.name;
    newDir = newDir.replace("//", "/");
    tryReadDir(newDir);
  }

  function entriesWithExts(entries, exts) {
    let files = [];
    for (let e of entries) {
      if (e.isDir) {
        continue;
      }
      if (!fileHasExt(e.name, exts)) {
        continue;
      }
      files.push(e);
    }
    return files;
  }

  const imgExts = [
    ".avif",
    ".bmp",
    ".gif",
    ".jpg",
    ".jpeg",
    ".png",
    ".webp",
    ".svg",
  ];

  const txtExts = [
    ".bat",
    ".clang",
    ".clang-format",
    ".clang-tidy",
    ".c",
    ".cpp",
    ".cppcheck",
    ".css",
    ".csv",
    ".cxx",
    ".editorconfig",
    ".filters",
    ".gitattributes",
    ".gitignore",
    ".gitmodules",
    ".h",
    ".hxx",
    ".hpp",
    ".html",
    ".ini",
    ".js",
    ".log",
    ".py",
    ".pylintrc",
    ".md",
    ".sh",
    ".sln",
    ".tex",
    ".txt",
    ".vcxproj",
    ".yml",
    ".yaml",
    ".xml",
  ];

  let contextMenuEntries = [];
  let contextMenuDirs = [];
  let contextMenuFiles = [];
  let contextMenuViewableTextFiles = [];
  let contextMenuViewableImageFiles = [];

  // ev is Event that triggered context menu
  // can use it to determine which element was clicked
  function calcContextMenuSelectedEntries(ev) {
    contextMenuEntries = [];
    contextMenuDirs = [];
    contextMenuFiles = [];

    for (let e of Object.values(selectedEntries)) {
      if (e.name == "..") {
        continue;
      }
      contextMenuEntries.push(e);
      if (e.isDir) {
        contextMenuDirs.push(e);
      } else {
        contextMenuFiles.push(e);
      }
    }
    let e = getEntryFromContextMenuEvent(ev);
    if (e) {
      if (e.isDir) {
        array.pushUnique(contextMenuDirs, e);
      } else {
        array.pushUnique(contextMenuFiles, e);
      }
    }

    contextMenuViewableTextFiles = entriesWithExts(contextMenuFiles, txtExts);
    contextMenuViewableImageFiles = entriesWithExts(contextMenuFiles, imgExts);
  }

  let cmPageX = 0;
  let cmPageY = 0;

  /** @type {FSEntry|null}*/
  let firstFileEntry = null;

  /**
   * @param {string} type
   * @returns {boolean}
   */
  function fsAllowsDirRename(type) {
    switch (type) {
      case fsTypeGoogleDrive:
      case fsTypeOneDrive:
        return true;
    }
    return false;
  }

  /**
   * trigger showing context menu
   * @param {MouseEvent} ev
   */
  function onContextMenu(ev) {
    cmPageX = ev.pageX;
    cmPageY = ev.pageY;

    let e = getEntryFromContextMenuEvent(ev);
    if (e) {
      let idx = findEntryIdxById(e.id);
      // like in windows:
      // - if right-click on existing selection => preserve multi selection
      // - if right click outside existing selection => select this item
      if (idx >= 0 && !isSelected(idx)) {
        makeSingleSelected(idx);
      }
    }
    calcContextMenuSelectedEntries(ev);

    let cm = [new MenuItem("Refresh", cmdRefresh)];

    let nFiles = len(contextMenuFiles);
    let nDirs = len(contextMenuDirs);
    let nEntries = nDirs + nFiles;
    firstFileEntry = nFiles > 0 ? contextMenuFiles[0] : null;
    /** @type {FSEntry|null}*/
    let firstEntry = nEntries > 0 ? contextMenuEntries[0] : null;
    let nViewable =
      len(contextMenuViewableTextFiles) + len(contextMenuViewableImageFiles);
    let firstViewableEntry;
    if (len(contextMenuViewableImageFiles) > 0) {
      firstViewableEntry = contextMenuViewableImageFiles[0];
    } else if (len(contextMenuViewableTextFiles) > 0) {
      firstViewableEntry = contextMenuViewableTextFiles[0];
    }

    let filesStr = `<span class="text-blue-700">`;
    if (nDirs > 0) {
      filesStr += `${nDirs} ${inflect("dir", nDirs)} `;
    }
    if (nFiles > 0) {
      filesStr += `${nFiles} ${inflect("file", nFiles)} `;
    }
    filesStr += `</span>`;

    let label;
    let mi;

    if (nViewable > 0) {
      label = `View  <span class="text-blue-700">${firstViewableEntry.name}</span>`;
      mi = new MenuItem(label, cmdViewFile);
      cm.push(mi);
    }

    if (nEntries > 0) {
      cm.push(menuItemSeparator);
      label = "Copy File Name";
      if (nEntries > 1) {
        label = `Copy ${nEntries} File Names`;
      }
      mi = new MenuItem(label, cmdCopyFileNames);
      cm.push(mi);
      label = "Copy File Path";
      if (nEntries > 1) {
        label = `Copy ${nEntries} File Paths`;
      }
      mi = new MenuItem(label, cmdCopyFilePaths);
      cm.push(mi);
    }

    if (nFiles > 0) {
      label = `Properties of <span class="text-blue-700">${firstFileEntry.name}</span>`;
      mi = new MenuItem(label, cmdFileProperties);
      cm.push(mi);
    }
    cm.push(menuItemSeparator);

    mi = new MenuItem("Upload Files From Computer", cmdUploadFiles);
    cm.push(mi);

    if (nFiles > 0) {
      label = `Download <span class="text-blue-700">${firstFileEntry.name}</span>`;
      if (nFiles > 1) {
        label = `Download  <span class="text-blue-700">${nFiles} Files</span>`;
      }
      mi = new MenuItem(label, cmdDownloadFiles);
      cm.push(mi);
    }

    if ($canCopyMove && nEntries > 0) {
      label = `Copy <span class="text-blue-700">${firstEntry.name}</span>`;
      if (nEntries > 1) {
        label = `Copy ` + filesStr;
      }
      mi = new MenuItem(label, cmdCopyFiles);
      cm.push(mi);

      label = `Move <span class="text-blue-700">${firstEntry.name}</span>`;
      if (nEntries > 1) {
        label = `Move ` + filesStr;
      }
      mi = new MenuItem(label, cmdMoveFiles);
      cm.push(mi);
    }

    if (nEntries > 0) {
      entryToRename = null;
      let allowsDirRename = fsAllowsDirRename(fsState.fs.type);
      if (allowsDirRename) {
        entryToRename = firstEntry;
      } else if (nFiles > 0) {
        entryToRename = firstFileEntry;
      }
      if (entryToRename) {
        label = `Rename <span class="text-blue-700">${entryToRename.name}</span>`;
        mi = new MenuItem(label, cmdRenameFile);
        cm.push(mi);
      }
    }

    mi = new MenuItem("New Directory", cmdNewDirectory);
    cm.push(mi);

    if (nEntries > 0) {
      label = `Delete <span class="text-blue-700">${firstEntry.name}</span>`;
      if (nEntries > 1) {
        label = `Delete ` + filesStr;
      }
      mi = new MenuItem(label, cmdDeleteFiles);
      cm.push(mi);
    }

    currentContextMenu = cm;
  }

  let isDragOver = false;
  function showIsDragOver() {
    isDragOver = true;
  }
  function hideIsDragOver() {
    isDragOver = false;
  }
  let showDialogUploadFiles = false;
  /** @type {import("./fs").FileInfo[]} */
  let filesToUpload = [];

  setContext("fnUploadFiles", doUpload);

  function doUpload(ev) {
    console.log("doUpload()", filesToUpload);
    showDialogUploadFiles = false;
    /** @type {import("./fs").FileInfo[]} */
    let files = [];
    for (let fi of filesToUpload) {
      console.log("fi:", fi);
      let dstPath = filepath.join(fsState.currDir, fi.name);
      fi.dstPath = dstPath;
      files.push(fi);
    }
    fs.uploadFiles(files, progressMsg).then(onDone).catch(fsOnError);
    function onDone() {
      progressMsg.set(null);
      reloadCurrentDir();
      console.log("upload finished");
    }
  }

  async function onFileDrop(ev) {
    console.log("onFileDrop()");
    const dt = ev.dataTransfer;
    let entries = await getAllFileEntries(dt.items);
    filesToUpload = [];
    for (let fe of entries) {
      console.log("fe:", fe);
      let f = await new Promise((resolve, reject) => {
        fe.file(resolve, reject);
      });
      let path = fe.fullPath;
      if (path.startsWith("/")) {
        path = path.substring(1);
      }
      /** @type {FileInfo} */
      let fi = new FileInfo();
      fi.file = f;
      fi.name = f.name;
      fi.size = f.size;
      fi.path = path;
      console.log(`file: ${f.name}, type: ${f.type}`);
      filesToUpload.push(fi);
    }
    showDialogUploadFiles = true;
  }
  function hideErrorMessage() {
    errorMsg = "";
  }

  /**
   * @param {FSEntry} e
   * @return {string}
   */
  function fsentrySize(e) {
    if (e.isDir) {
      return "";
    }
    return fmtSize(e.size);
  }

  function addToBookmarks() {
    console.log("Add to bookmarks: currDir", fsState.currDir);
    //addBookmarkFSState(fsState);
  }

  let showwingCopyMoveFiles = false;
  /** @type {import("./fs").FSEntry[]|null}*/
  let entriesToCopy = [];
  /** @type {import("./fs").FSDirState} */
  let copyFSStateFrom = null;
  /** @type {import("./fs").FS} */
  let copyFsFrom = null;
  /** @type {import("./fs").FSDirState} */
  let copyFSStateTo = null;
  /** @type {import("./fs").FS} */
  let copyFsTo = null;
  let copyFromDir = "";
  let copyToDir = "";
  let isMove = false;

  function cmdCopyMoveFiles() {
    console.log(`cmdCopyMoveFiles: isMove:${isMove}`);
    let entries = contextMenuEntries;
    copyFSStateFrom = fsState;
    copyFsFrom = fsState.fs;
    copyFromDir = fsState.currDir;
    copyFSStateTo = pickOtherFSState(fsState);
    copyFsTo = copyFSStateTo.fs;
    copyToDir = copyFSStateTo.currDir;
    entriesToCopy = entries;
    showwingCopyMoveFiles = true;
  }

  function cmdCopyFiles() {
    isMove = false;
    cmdCopyMoveFiles();
  }

  function cmdMoveFiles() {
    isMove = true;
    cmdCopyMoveFiles();
  }

  async function doCopyMoveFiles() {
    console.log("doCopyMoveFiles:", entriesToCopy, "isMove:", isMove);
    let ci = new CopyInfo();
    ci.fsSrc = copyFsFrom;
    ci.fsDst = copyFsTo;
    ci.srcDir = copyFromDir;
    ci.dstDir = copyToDir;
    ci.entriesToCopy = entriesToCopy;
    showwingCopyMoveFiles = false;
    // entriesToCopy = [];
    try {
      await fsCopyMove(ci, progressMsg, isMove);
    } catch (err) {
      console.error("fsCopy failed with", err);
      errorMsg = err;
    }
    $progressMsg = "";

    copyFSStateFrom.triggerReloadCurrentDir();
    copyFSStateTo.triggerReloadCurrentDir();
  }
</script>

<!-- ensure this class is generated by tailwind -->
<div class="bg-sky-200 hidden" />

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<div
  class="wrapper overflow-auto flex flex-col"
  class:showdragover={isDragOver}
  bind:this={elWrapper}
  use:focus
  tabindex="0"
  on:keydown={handleKeyDown}
  on:dragover|preventDefault={showIsDragOver}
  on:dragenter|preventDefault={showIsDragOver}
  on:dragleave|preventDefault={hideIsDragOver}
  on:drop|preventDefault={onFileDrop}
>
  <FileListTopNav
    path={currDirFull}
    {currBookmark}
    on:addtobookmarks={addToBookmarks}
    on:fmdirup={dirUp}
    on:gotobookmark
  />

  <div
    id="fm-file-list-wrap"
    class="grow-1 min-h-0 h-full overflow-auto"
    bind:this={elTableParent}
    on:contextmenu={onContextMenu}
  >
    {#if len(sortedEntries) > 0}
      <div
        class="tbl table select-none overflow-y-auto border-collapse w-full"
        bind:this={elTable}
      >
        <div class="table-header-group sticky top-0">
          <div class="table-row text-base cursor-pointer">
            <!-- name sort header -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              on:click={changeNameSort}
              use:remember={nameNodes}
              class="table-cell text-left pl-2 fe-name bg-gray-100 hover:bg-gray-200 border-r-2 border-gray-300"
            >
              name
              {#if nameSort == sortDown}
                <SortDescending class="inline" size={16} />
              {:else if nameSort == sortUp}
                <SortAscending class="inline" size={16} />
              {:else}
                <div style="width: 16; height: 16" />
              {/if}
            </div>

            <!-- size sort header -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              use:remember={sizeNodes}
              on:click={changeSizeSort}
              class="table-cell pr-2 text-right bg-gray-100 hover:bg-gray-200 border-r-2 border-gray-300"
            >
              {#if sizeSort == sortDown}
                <SortDescending class="inline" size={16} />
              {:else if sizeSort == sortUp}
                <SortAscending class="inline" size={16} />
              {:else}
                <div style="width: 16; height: 16" />
              {/if}
              size
            </div>

            <!-- last mod sort header -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              use:remember={lastModNodes}
              on:click={changeLastModSort}
              class="table-cell whitespace-nowrap text-right pr-2 bg-gray-100 hover:bg-gray-200"
            >
              {#if lastModSort == sortDown}
                <SortDescending class="inline" size={16} />
              {:else if lastModSort == sortUp}
                <SortAscending class="inline" size={16} />
              {:else}
                <div style="width: 16; height: 16" />
              {/if}
              Mod
            </div>
          </div>
        </div>

        <div class="table-row-group">
          {#each sortedEntries as e, index (e.id)}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              data-id={e.id}
              class="table-row whitespace-nowrap cursor-pointer hover:bg-sky-100"
              class:bg-sky-200={isSelected(index)}
              on:click={(ev) => markSelected(ev, index)}
            >
              <div
                class="tc table-cell fe-name text-left"
                class:font-bold={e.isDir}
                use:remember={nameNodes}
              >
                {e.name}{nameSuffix(e)}
              </div>

              <div
                class="tc table-cell fe-size pr-2 text-right"
                use:remember={sizeNodes}
              >
                {fsentrySize(e)}
              </div>

              <div
                class="tc table-cell fe-lastmod whitespace-pre pr-2 text-right"
                use:remember={lastModNodes}
              >
                {fmtTimeMs(e.lastMod)}
              </div>
            </div>
          {/each}
        </div>
      </div>
      {#if currentContextMenu}
        <ContextMenu
          pageX={cmPageX}
          pageY={cmPageY}
          menu={currentContextMenu}
        />
      {/if}
    {:else}
      <div class="flex flex-col justify-center items-center h-full">
        <div>No files!</div>
      </div>
    {/if}
  </div>

  <div class="relative grow-0 bg-gray-50 pl-2">
    <div class="flex items-center">
      <Folder size={16} />
      <div class="ml-2">
        {nSelectedDirs} / {nDirs}
      </div>
      <div class="ml-2 mr-2 text-gray-400">•</div>
      <File size={16} />
      <div class="ml-2">{nSelectedFiles} / {nFiles}</div>
      <div class="ml-2 text-gray-400">•</div>
      <div class="ml-2">
        {fmtSize(selectedSize)} / {fmtSize(totalSize)}
      </div>
    </div>
    <div class="absolute flex flex-col bottom-8 left-2 right-2 bg-gray-50">
      {#if $showUploadInfo}
        <div
          class="flex flex-row justify-between items-center px-3 py-1 mb-2 text-blue-500 font-normal bg-blue-100 border border-blue-300 rounded-lg"
        >
          <div class="break-all">
            Drag & drop files from desktop to upload here. <a
              class="underline"
              target="_blank"
              rel="noreferrer"
              href="https://blog.kowalczyk.info/article/ebcb11b156f7458398ea147a30e6776d/upload-files-from-your-computer.html"
              >Learn more</a
            >
          </div>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            class="self-center mt-1 ml-2 hover:bg-blue-200 cursor-pointer"
            on:click={() => setShowUploadInfo(false)}
          >
            <Close size={16} />
          </div>
        </div>
      {/if}
      {#if errorMsg}
        <div
          class="flex flex-row justify-between items-center px-3 py-1 mb-2 text-red-500 font-semibold bg-red-100 border border-red-300 rounded-lg"
        >
          <div class="break-all">{truncatedText(errorMsg)}</div>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            class="self-center mt-1 ml-2 hover:bg-red-200 cursor-pointer"
            on:click={hideErrorMessage}
          >
            <Close size={16} />
          </div>
        </div>
      {/if}

      {#if $progressMsg}
        <div
          class="flex flex-row items-center px-3 py-1 bg-yellow-100 text-gray-900 border border-yellow-300 rounded-lg"
        >
          <div class="mr-2 break-all">{$progressMsg}</div>
          <Spinner style="margin-top: 4px; color: black" />
        </div>
      {/if}
    </div>
  </div>

  <input
    type="file"
    bind:this={filesEl}
    multiple
    hidden
    on:change={filesSelected}
  />
</div>

{#if showDialogNewDirectory}
  <DialogNewDirectory
    bind:open={showDialogNewDirectory}
    currentDir={fsState.currDir}
    allowNested={newDirectoryAllowNested()}
  />
{/if}

{#if showDialogDownload}
  <DialogDownload bind:open={showDialogDownload} paths={filesToDownload} />
{/if}
{#if showDialogDelete}
  <DialogDelete bind:open={showDialogDelete} files={filesToDelete} />
{/if}
{#if showDialogUploadFiles}
  <DialogUploadFiles bind:open={showDialogUploadFiles} files={filesToUpload} />
{/if}
{#if showingFileProperties}
  <DialogFileProperties
    bind:open={showingFileProperties}
    fsEntry={filePropertiesEntry}
    path={filePropertiesPath}
  />
{/if}

{#if showingFileRename}
  <DialogRename
    bind:open={showingFileRename}
    ignoreCase={fileRenameIgnoreCase}
    destSameDir={fileRenameSameDir}
    path={filePathToRename}
  />
{/if}

{#if showwingCopyMoveFiles}
  <DialogCopyMoveFile
    bind:open={showwingCopyMoveFiles}
    {isMove}
    entries={entriesToCopy}
    fromDir={copyFromDir}
    toDir={copyToDir}
    doit={doCopyMoveFiles}
  />
{/if}

{#if showingImagePreview}
  <Overlay bind:open={showingImagePreview}>
    <ShowImage uri={imagePreviewURL} alt={imagePreviewName} />
  </Overlay>
{/if}

{#if showingTextPreview}
  <Overlay bind:open={showingTextPreview}>
    <ShowText url={textPreviewURL} fileName={textPreviewName} />
  </Overlay>
{/if}

<style>
  .wrapper {
    max-height: 100%;
    border: 1px solid white; /* don't re-layout when :focus-within */
    border: 2px solid #ccc;
  }

  .wrapper:focus-within {
    border: 2px solid #999;
    border: 2px solid lightblue;
    border: 2px solid gray;
    outline: none; /* otherwise default black outline is shown */
  }

  .tbl {
    font-family: var(--font-monospace);
    font-size: 10pt;
  }

  .tc {
    vertical-align: top;
    padding-left: 0.5rem; /* pl-2 */
  }
  .showdragover {
    border: 2px solid blue !important;
  }
  .fe-name {
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
</style>
