import * as filepath from "./filepath";
import { inflect, len, throwIf, nanoid } from "./util";
import { FS, fsTypeLocal, LocalConfig } from "./fs";
import { FSEntry, newDirFSEntry } from "./fsentry";

let disablePreCaching = true;

/**
 *
 * @param {string} name
 * @param {FileSystemDirectoryHandle} dh
 * @returns {FSEntry}
 */
function newDirLocalFSEntry(name, dh) {
  let dir = newDirFSEntry(name);
  dir.dirHandle = dh;
  return dir;
}

// Note: can't type dh as FileSystemDirectoryHandle
// because lib.dom.ts in vscode doesn't have
// entries() value
async function dirHandleReadDir(dh) {
  /** @type {FSEntry[]} */
  let entries = [];
  for await (const entry of dh.values()) {
    /** @type {FSEntry} */
    let e;
    let isFile = entry.kind === "file";
    let isFolder = entry.kind === "directory";
    throwIf(!isFolder && !isFile, "must be file or folder");

    if (isFile) {
      /** @type {File} */
      const f = await entry.getFile();
      let meta = [null, f.name, f.size, f.lastModified, null];
      e = FSEntry.fromMeta(meta);
    } else {
      e = newDirFSEntry(entry.name);
    }

    entries.push(e);
  }
  return entries;
}

export class FileSystemLocal extends FS {
  /**
   * @param {LocalConfig} localConfig
   */
  constructor(localConfig) {
    super(fsTypeLocal);
    // TODO: for backwards compat, maybe not needed
    if (!localConfig.id) {
      localConfig.id = nanoid();
    }
    this.localConfig = localConfig;
  }

  get dirHandle() {
    return this.localConfig.dirHandle;
  }

  /**
   * @param {string} dirPath - directory name
   * @param {boolean} force - if true, don't use cached values
   * @param {import("svelte/store").Writable} progress
   * @return {Promise<FSEntry[]>}
   */
  async readDir(dirPath, force, progress) {
    let nDirs = this.dirs.size;
    console.log(`FileSystemLocal.readDir: ${dirPath} in ${nDirs} dirs`);
    throwIf(dirPath == "", `empty dirPath`);

    progress.set(
      `reading files for dir ${dirPath}, ${nDirs} dirs, ${this.filesCount} files`
    );

    if (!force) {
      const de = this.dirs.get(dirPath);
      if (de) {
        console.log(`FileSystemLocal.get: got from cache for ${dirPath}`);
        return de.fsentries;
      }
    }

    console.log(`reading ${dirPath}`);
    let dir;
    if (dirPath == "/") {
      dir = newDirLocalFSEntry(dirPath, this.dirHandle);
    } else if (!dir) {
      let dirNames = [];
      let currDir = dirPath;
      let dh = null;
      while (!dh) {
        let parts = filepath.split(currDir);
        dirNames.push(parts.name);
        let dirParent = parts.dir;
        if (dirParent == "/") {
          dh = this.dirHandle;
        } else {
          let de = this.dirs.get(dirParent);
          if (de) {
            dh = de.dirHandle;
          } else {
            currDir = dirParent;
          }
        }
      }
      console.log("dirNames:", dirNames);
      let n = len(dirNames);
      for (let i = n - 1; i >= 0; i--) {
        let name = dirNames[i];
        dh = await dh.getDirectoryHandle(name);
        currDir = filepath.join(currDir, name);
        if (!dh) {
          throw new Error(
            `no sub-directory '${name}' in directory '${currDir}`
          );
        }
      }
      console.log(`newDirLocalFSEntry: dirPath: ${dirPath}`);
      dir = newDirLocalFSEntry(dirPath, dh);
    }

    console.log(`dirHandleReadDir: dirPath=${dirPath}, dir.name='${dir.name}'`);
    dir.fsentries = await dirHandleReadDir(dir.dirHandle);
    console.log(`cached dir ${dir.name}`);

    this.cacheDir(dir);

    if (!disablePreCaching && dirPath == "/" && this.dirs.size == 1) {
      this.readDirRecur(["/"]);
    }

    return dir.fsentries;
  }

  /**
   * @param {string} dirPath
   */
  async deleteDir(dirPath) {
    throwIf(dirPath == "/", "can't delete '/' directory");

    let parts = filepath.split(dirPath);
    let de = this.dirs.get(parts.dir);
    throwIf(
      de == null,
      `didn't find parent directory '${parts.dir}' for dir '${dirPath}'`
    );

    let fileName = parts.name;
    await de.dirHandle.removeEntry(fileName, { recursive: true });
    this.removeCachedDirRecur(dirPath);
    de.removeEntryByName(fileName);
  }

  /**
   * @param {string} path
   */
  async deleteFile(path) {
    console.log(`FileSystemLocal.deleteFile: ${path}`);
    if (isDirectory(path)) {
      path = stiripSlashEnd(path);
      if (this.dirs.has(path)) {
        return await this.deleteDir(path);
      }
      throw new Error(`didn't find FSEntry for ${path}`);
    }

    let df = this.findDirAndFileFSEntry(path);
    if (!df || !df.entry) {
      throw new Error(`'${path}' not found`);
    }
    let name = df.entry.name;
    // TODO: need to remove deleted directories from df.
    await df.dir.dirHandle.removeEntry(name);
  }

  async deleteFiles(paths, progress) {
    const n = len(paths);
    const fileStr = inflect("file", n);
    progress.set(`deleting ${n} ${fileStr}`);
    for (let path of paths) {
      progress.set(`deleting file ${path}`);
      await this.deleteFile(path);
    }
  }

  /**
   * @param {string} dirPath
   */
  async createParentDir(dirPath) {
    console.log(`FSLocal.createParentDir: path='${dirPath}'`);
    if (dirPath == "/" || dirPath == "") {
      return null;
    }
    let df = filepath.split(dirPath);
    let parentDirPath = df.dir;
    let dirParent = this.dirs.get(parentDirPath);
    if (!dirParent) {
      dirParent = await this.createParentDir(parentDirPath);
    }
    let dh = dirParent.dirHandle;
    let newDH = await dh.getDirectoryHandle(df.name, { create: true });

    // add entry to a parent
    let e = newDirFSEntry(df.name);
    dirParent.fsentries.push(e);

    // needs an entry with full dir
    let de = newDirLocalFSEntry(dirPath, newDH);
    this.cacheDir(de);
    return de;
  }

  /**
   * @param {string} dirPath - full path for the directory
   */
  async createDirectory(dirPath) {
    let de = await this.createParentDir(dirPath);
    if (!de) {
      throw new Error(`couldn't create directory ${dirPath}`);
    }
  }

  /**
   * @param {string} path
   * @param {Blob} file
   * @param {import("./http").HttpProgress} httpProgress
   */
  async uploadFile(path, file, httpProgress) {
    console.log(`local.uploadFile path: '${path}'`);
    let df = filepath.split(path);
    let d = this.dirs.get(df.dir);
    if (!d) {
      d = await this.createParentDir(df.dir);
    }
    let fileName = df.name;
    let newHandle = await d.dirHandle.getFileHandle(fileName, {
      create: true,
    });
    await writeFile(newHandle, file);

    let entries = null;
    let dirHandle = null;
    let lastMod = 0; // TODO: use new Date().getTime() ?
    let meta = [dirHandle, fileName, file.size, lastMod, entries];
    let fe = FSEntry.fromMeta(meta);
    d.fsentries.push(fe);
  }

  /**
   * get publicly visible URL
   * @param {string} path
   * @returns {Promise<string>}
   */
  async getPublicURL(path) {
    console.log(`FSLocal.getPublicURL: path=${path}`);

    let file = await this.downloadFile(path, null);
    let uri = URL.createObjectURL(file);
    return uri;
  }

  /**
   * @param {string} path
   * @param {import("./http").HttpProgress} httpProgress
   * @returns {Promise<Blob>}
   */
  async downloadFile(path, httpProgress) {
    // TODO: should work even if we don't have info cached
    // maybe call readDir(dir), which takes care of that
    let dn = filepath.split(path);
    let de = this.dirs.get(dn.dir);
    if (!de) {
      throw new Error(`no directory ${dn.dir} for file ${path}`);
    }
    let fh = await de.dirHandle.getFileHandle(dn.name);
    if (!fh) {
      throw new Error(`getFileHandle(${dn.name}) failed`);
    }
    let file = await fh.getFile();
    if (!file) {
      throw new Error(`getFile() failed`);
    }
    console.log("file:", file);
    return file;
  }

  /*
    // Get references to a file and a directory.
  const [file] = await showOpenFilePicker();
  const directory = await showDirectoryPicker();
  
  // Rename the file.
  await file.rename('new_name');
  // Move the file to a new directory.
  await file.move(directory);
  // Move the file to a new directory and rename it.
  await file.move(directory, 'newer_name');
  */

  /*
  rename2(path, newPath, onDone) {
    // TODO: implement rename by uploadFile() / deleteOne
    throwIf(true, "rename not supported ");
    console.log(`FSLocal.rename: path=${path}, newPath=${newPath}`);
    throwIf(path == newPath, "path must be != newPath");
    let dn = filepath.split(path);
    let dnDest = filepath.split(newPath);
    throwIf(dn.dir != dnDest.dir, "must be in same directory");
    let d = this.dirs.get(dn.dir);
    if (!d) {
      onDone(new Error(`no directory ${dn.dir} found`));
      return;
    }

    const ren = async () => {
      try {
        console.log(`renaming ${dn.name} to ${dnDest.name}`);
        let fh = await d.dirHandle.getFileHandle(dn.name);
        await fh.rename(dnDest.name);
        onDone(null);
      } catch (e) {
        onDone(e);
      }
    };

    ren();
  }
*/

  async rename(path, newPath) {
    console.log(`FSLocal.rename: path=${path}, newPath=${newPath}`);
    throwIf(path == newPath, "path must be != newPath");
    let dn = filepath.split(path);
    let dnDest = filepath.split(newPath);
    throwIf(dn.dir != dnDest.dir, "must be in same directory");
    let d = this.dirs.get(dn.dir);
    if (!d) {
      throw new Error(`no directory ${dn.dir} found`);
    }
    console.log(`renaming ${dn.name} to ${dnDest.name}`);
    let dh = d.dirHandle;
    let fhIn = await dh.getFileHandle(dn.name);
    let fhOut = await dh.getFileHandle(dnDest.name, { create: true });
    // @ts-ignore
    let writable = await fhOut.createWritable();
    let file = await fhIn.getFile();
    await writable.write(file);
    await writable.close();
    await dh.removeEntry(dn.name);
  }
}

async function writeFile(fileHandle, contents) {
  // Create a FileSystemWritableFileStream to write to.
  const writable = await fileHandle.createWritable();
  // Write the contents of the file to the stream.
  await writable.write(contents);
  // Close the file and write the contents to disk.
  await writable.close();
}

/**
 * @param {LocalConfig} localConfig
 * @returns {FileSystemLocal}
 */
export function makeFileSystemLocal(localConfig) {
  return new FileSystemLocal(localConfig);
}

function isDirectory(path) {
  return path.endsWith("/");
}

function stiripSlashEnd(path) {
  let n = len(path);
  return path.substring(0, n - 1);
}
