// return {x, y} position that ensures that rect is visible inside window
export function ensureRectVisibleInWindow(rect) {
  // console.log("ensureRectVisibleInWindow:", rect);
  let x = rect.x;
  const winDx = window.innerWidth;
  const rEndX = x + rect.width;
  if (rEndX > winDx) {
    x = winDx - rect.width - 15;
  }
  if (x < 4) {
    x = 4;
  }

  let y = rect.y;
  const winDy = window.innerHeight;
  const rEndY = y + rect.height;
  // console.log("rEndY:", rEndY, "winDy:", winDy);
  if (rEndY > winDy) {
    y = winDy - rect.height - 15;
  }
  if (y < 4) {
    y = 4;
  }
  // console.log("x:", x, "y:", y);
  return { x: x, y: y };
}

// action that ensures that the node is fully visible in the window
export function ensurevisible(node) {
  const r = node.getBoundingClientRect();
  console.log("ensureVisible", node, "r:", r);
  const { x, y } = ensureRectVisibleInWindow(r);
  const st = node.style;
  st.left = `${x}px`;
  st.top = `${y}px`;
  // console.log(`top: ${st.top}, left: ${st.left}, bottom: ${st.bottom}, right: ${st.right}`);
}
