export default function clickoutside(node) {
  /**
   * @param {MouseEvent} event
   */
  const handleClick = (event) => {
    if (node && !node.contains(event.target) && !event.defaultPrevented) {
      node.dispatchEvent(new CustomEvent("clickoutside", node));
      // console.log("clickoutside handleClick()");
      // event.stopPropagation();
      // event.preventDefault();
    }
  };

  document.addEventListener("click", handleClick, true);

  return {
    destroy() {
      document.removeEventListener("click", handleClick, true);
    },
  };
}
