<script>
  import DialogBase from "./DialogBase.svelte";
  import { focus } from "./actions/focus";

  import { fmtSizeFull, fmtTimeMs, len } from "./util";
  import {
    prop_od_dlURL,
    prop_od_file_hashes_sha1Hash,
    prop_od_file_hashes_sha256Hash,
    prop_od_file_mimeType,
    prop_od_parentReference_driveId,
    prop_od_parentReference_id,
    prop_od_webUrl,
  } from "./fs-onedrive";
  import {
    prop_gd_createdTime,
    prop_gd_description,
    prop_gd_folderColorRgb,
    prop_gd_md5Checksum,
    prop_gd_mimeType,
    prop_gd_originalFilename,
    prop_gd_parents,
    prop_gd_resourceKey,
    prop_gd_version,
    prop_gd_webContentLink,
    prop_gd_webViewLink,
  } from "./fs-gdrive";
  import {
    prop_dbx_client_modified,
    prop_dbx_content_hash,
    prop_dbx_rev,
  } from "./fs-dropbox";
  import { onMount } from "svelte";

  /** @type {import("./fs").FSEntry} */
  export let fsEntry;
  export let path;
  export let open = false;

  // TODO: maybe convert to pretty names when adding?
  let propsSkip = [
    prop_od_parentReference_id,
    prop_od_parentReference_driveId,
    prop_gd_parents,
  ];

  let prettyNames = [
    prop_od_dlURL,
    "Download URL",
    prop_od_file_mimeType,
    "Mime Type",
    prop_od_file_hashes_sha1Hash,
    "Sha1 Hash",
    prop_od_file_hashes_sha256Hash,
    "Sha256 Hash",
    prop_od_webUrl,
    "Web URL",
    prop_gd_description,
    "Description",
    prop_gd_originalFilename,
    "Original File Name",
    prop_gd_mimeType,
    "Mime Type",
    prop_gd_resourceKey,
    "Resource Key",
    prop_gd_md5Checksum,
    "Md5 Hash",
    prop_gd_createdTime,
    "Created Time",
    prop_gd_folderColorRgb,
    "Folder Color",
    prop_gd_version,
    "Version",
    prop_gd_webContentLink,
    "Content Link",
    prop_gd_webViewLink,
    "View Link",
    prop_dbx_rev,
    "Rev",
    prop_dbx_content_hash,
    "Content Hash",
    prop_dbx_client_modified,
    "Client Modified",
  ];

  let urlProps = [
    prop_od_dlURL,
    prop_od_webUrl,
    prop_gd_webContentLink,
    prop_gd_webViewLink,
  ];

  let props = [];

  function shouldSkip(name) {
    return propsSkip.includes(name);
  }

  function isURL(name) {
    return urlProps.includes(name);
  }

  function prettyName(name) {
    const idx = prettyNames.indexOf(name);
    if (idx >= 0) {
      return prettyNames[idx + 1];
    }
    return name;
  }

  function pushProp(name, val) {
    props.push([name, val]);
  }

  function updateProps(e) {
    if (!e) {
      return;
    }
    pushProp("Name", e.name);
    pushProp("Path", path);
    if (!e.isDir) {
      pushProp("Size", fmtSizeFull(e.size));
    }
    pushProp("Modification Time", fmtTimeMs(e.lastMod));

    let fsProps = fsEntry.getAllProps();
    let n = len(fsProps) / 2;
    for (let i = 0; i < n; i++) {
      let idx = i * 2;
      let name = fsProps[idx];
      if (shouldSkip(name)) {
        continue;
      }
      pushProp(name, fsProps[idx + 1]);
    }
  }

  $: updateProps(fsEntry);

  // for (let i = 0; i < 16; i++) {
  //   pushProp("prop" + i, "value: " + i);
  // }
  function close() {
    open = false;
  }
</script>

<!-- TODO: better way to enforce scrolling of main area-->
<DialogBase bind:open title="File Properties">
  <div slot="main" class="max-h-[60vh] overflow-y-auto">
    <div class="grid grid-cols-[auto_1fr] mt-2">
      {#each props as nameVal}
        <div class="mt-1 font-semibold">{prettyName(nameVal[0])}</div>
        {#if isURL(nameVal[0])}
          <div class="mt-1 ml-4 mr-4 truncate">
            <a
              class="text-blue-600 underline"
              href={nameVal[1]}
              target="_blank"
              rel="noreferrer">{nameVal[1]}</a
            >
          </div>
        {:else}
          <div class="mt-1 ml-4 mr-4 truncate">{nameVal[1]}</div>
        {/if}
      {/each}
    </div>
  </div>

  <div slot="bottom" class="flex flex-row justify-end">
    <button
      use:focus
      class="px-4 py-2 enabled:hover:bg-gray-200 text-gray-800 font-semibold border border-gray-400 disabled:opacity-50"
      on:click={close}>Ok</button
    >
    <slot name="bottom" />
  </div>
</DialogBase>
