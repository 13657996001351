export function len(o) {
  if (!o) {
    return 0;
  }
  return o.length;
}

// https://github.dev/dropbox/dropbox-sdk-js/blob/main/examples/javascript/utils.js#L6
export function parseQueryString(str) {
  const ret = Object.create(null);

  if (typeof str !== "string") {
    return ret;
  }

  str = str.trim().replace(/^(\?|#|&)/, "");

  if (!str) {
    return ret;
  }

  str.split("&").forEach((param) => {
    const parts = param.replace(/\+/g, " ").split("=");
    // Firefox (pre 40) decodes `%3D` to `=`
    // https://github.com/sindresorhus/query-string/pull/37
    let key = parts.shift();
    let val = parts.length > 0 ? parts.join("=") : undefined;

    key = decodeURIComponent(key);

    // missing `=` should be `null`:
    // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
    val = val === undefined ? null : decodeURIComponent(val);

    if (ret[key] === undefined) {
      ret[key] = val;
    } else if (Array.isArray(ret[key])) {
      ret[key].push(val);
    } else {
      ret[key] = [ret[key], val];
    }
  });

  return ret;
}

export function openBrowserWindow(url, title, w, h) {
  const screenLeft = window.screenLeft;
  const screenTop = window.screenTop;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const left = width / 2 - w / 2 + screenLeft;
  const top = height / 2 - h / 2 + screenTop;
  const newWindow = window.open(
    url,
    title,
    "scrollbars=yes, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
  );

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}

/**
 * @param {number} n
 * @returns {string}
 */
export function fmtSizeFull(n) {
  if (!n && n !== 0) {
    return "-";
  }
  let s = fmtSize(n);
  return s + ` (${n.toLocaleString("en-US")} bytes)`;
}

/**
 * @param {number} n
 * @returns {string}
 */
export function fmtSize(n) {
  if (typeof n == "undefined") {
    // google drive has some entries without size
    return "-";
  }
  if (n < 0) {
    // for directories
    return "";
  }
  const a = [
    [1024 * 1024 * 1024 * 1024, "TB"],
    [1024 * 1024 * 1024, "GB"],
    [1024 * 1024, "MB"],
    [1024, "kB"],
  ];
  for (const el of a) {
    const size = el[0];
    if (n >= size) {
      // @ts-ignore
      let s = (n / size).toFixed(2);
      return s.replace(".00", "") + " " + el[1];
    }
  }
  return `${n} B`;
}

/**
 * @param {Date} d
 * @returns {number}
 */
export function timeSince(d) {
  const now = new Date().getTime();
  return now - d.getTime();
}

// returns a function that, when called, returns number of elapsed milliseconds
export function startTimer() {
  const timeStart = performance.now();
  return function () {
    return Math.round(performance.now() - timeStart);
  };
}

/**
 * @param {string} s
 * @param {string} prefix
 * @returns {string}
 */
export function trimSuffix(s, prefix) {
  if (s.startsWith(prefix)) {
    return s.substring(len(prefix));
  }
  return s;
}

/**
 * @param {number} ms
 * @returns {Promise<void>}
 */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * @param {string} s
 */
export function copyTextToClipboard(s) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(s).then(ok, err);
    function ok() {
      console.log(`copied to clipboard: '${s}`);
    }
    function err(e) {
      console.error("failed to copy to clipboard", e);
    }
  }
}

/**
 * @param {string} s
 * @param {number} n
 * @returns {string}
 */
export function inflect(s, n) {
  if (n == 1) {
    return s;
  }
  return s + "s";
}

// https://gist.github.com/nmsdvid/8807205
// supports only 1 argument to debounced function
export const debounceEvent =
  (a, b = 250, c) =>
  (d) =>
    // @ts-ignore
    clearTimeout(c, (c = setTimeout(a, b, d)));

// Wrap readEntries in a promise to make working with readEntries easier
export async function readEntriesPromise(directoryReader) {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    console.log(err);
  }
}

export async function collectAllDirectoryEntries(directoryReader, queue) {
  let readEntries = await readEntriesPromise(directoryReader);
  while (readEntries.length > 0) {
    queue.push(...readEntries);
    readEntries = await readEntriesPromise(directoryReader);
  }
}

// DataTransfer.items
export async function getAllFileEntries(dataTransferItemList) {
  let fileEntries = [];
  let queue = [];
  let n = dataTransferItemList.length;
  for (let i = 0; i < n; i++) {
    let item = dataTransferItemList[i];
    let entry = item.webkitGetAsEntry();
    queue.push(entry);
  }
  while (len(queue) > 0) {
    let entry = queue.shift();
    if (entry.isFile) {
      fileEntries.push(entry);
    } else if (entry.isDirectory) {
      let reader = entry.createReader();
      await collectAllDirectoryEntries(reader, queue);
    }
  }
  return fileEntries;
}

export function dx(left, right) {
  return right - left;
}

export function half(n) {
  return n / 2;
}

export function mid(left, right) {
  return left - dx(left, right) / 2;
}

/**
 * @param {boolean} cond
 * @param {string} msg
 * @returns
 */
export function throwIf(cond, msg) {
  if (!cond) {
    return;
  }
  throw new Error(msg);
}

/**
 * Converts a plain object in an instance of a class by patching its prototype
 * @param {Object} o
 * @param {Object} proto
 * @returns {Object}
 */
export function patchPrototype(o, proto) {
  // https://stackoverflow.com/questions/5873624/parse-json-string-into-a-particular-object-prototype-in-javascript
  Object.setPrototypeOf(o, proto);
  return o;
}

/**
 * @param {string} s
 * @param {number} max
 * @returns string
 */
export function truncatedText(s, max = 128) {
  if (len(s) > max) {
    return s.substring(0, max);
  }
  return s;
}

/**
 * @param {XMLHttpRequest} xhr
 */
function throwOnXhrError(uri, xhr) {
  if (xhr.status >= 200 && xhr.status < 300) {
    return;
  }
  throw new Error(`${uri} failed with ${xhr.status} ${xhr.statusText}`);
}

/**
 * @param {string} uri
 * @param {File} file
 */
export function putWithXhr(uri, file) {
  let xhr = new XMLHttpRequest();
  xhr.open("PUT", uri, true);
  // TODO: set Content-Type
  // xhr.setRequestHeader("Content-Length", size);
  xhr.onload = () => {
    throwOnXhrError(uri, xhr);
  };
  xhr.onerror = () => {
    throwOnXhrError(uri, xhr);
  };
  xhr.send(file);
}

export function lazyLoadScript(src, opts = {}) {
  return new Promise(function (resolve, reject) {
    if (!src) {
      throw new Error("src parameter must be specified");
    }

    const defaults = {
      force: false,
      async: true,
    };

    if (typeof opts === "string") {
      opts = {
        id: opts,
      };
    }

    opts = Object.assign({}, defaults, opts);
    const script = document.createElement("script");

    const id = opts.id;
    script.src = src;
    if (id) {
      script.setAttribute("id", id);
      if (document.getElementById(id)) {
        // console.log(`already loaded ${src}`);
        resolve(document.getElementById(id));
        return;
      }
    } else {
      const sc = document.querySelector(`script[src="${src}"]`);
      if (!opts.force && sc) {
        // console.log(`already loaded ${src}`);
        resolve(sc);
        return;
      }
    }

    if (opts.async) script.setAttribute("async", "true");
    if (opts.defer) script.setAttribute("defer", "true");
    if (opts.integrity) script.setAttribute("integrity", opts.integrity);
    if (opts.type) script.setAttribute("type", opts.type);
    if (opts.text) script.setAttribute("text", opts.text);
    if (opts.charset) script.setAttribute("charset", opts.charset);
    if (opts.crossorigin) script.setAttribute("crossorigin", opts.crossorigin);

    script.onload = function (event) {
      // console.log(`loaded ${src}`);
      resolve(script);
    };
    script.onerror = function (event) {
      reject(event);
    };
    document.body.appendChild(script);
  });
}

/**
 * @param {Date} dt
 * @returns (number)
 */
export function dateStringToMs(dt) {
  if (!dt) {
    // maybe return now?
    return 0;
  }
  const d = new Date(dt);
  return d.getTime();
}

/**
 * @returns {boolean}
 */
export function isDev() {
  return window.location.hostname == "localhost";
}

/**
 * https://attacomsian.com/blog/javascript-load-script-async
 * @param {string} src
 * @param {boolean} async
 * @param {string} type
 * @returns {Promise<Object>}
 */
export async function loadScript(src, async = true, type = "text/javascript") {
  return new Promise((resolve, reject) => {
    try {
      const el = document.createElement("script");
      const container = document.head || document.body;

      el.type = type;
      el.async = async;
      el.src = src;

      el.addEventListener("load", () => {
        resolve({ status: true });
      });

      el.addEventListener("error", () => {
        reject({
          status: false,
          message: `Failed to load the script ${src}`,
        });
      });

      container.appendChild(el);
    } catch (err) {
      reject(err);
    }
  });
}

export function nanoid(t = 21) {
  let a = crypto.getRandomValues(new Uint8Array(t));
  return a.reduce(
    (t, e) =>
      (t +=
        (e &= 63) < 36
          ? e.toString(36)
          : e < 62
          ? (e - 26).toString(36).toUpperCase()
          : e > 62
          ? "-"
          : "_"),
    ""
  );
}

/**
 *
 * @param {any} v
 * @returns {boolean}
 */
export function isString(v) {
  return typeof v === "string" || v instanceof String;
}

/**
 * @param {string} s
 * @param {number} n
 * @param {string} padding
 * @returns {string}
 */
function pad(s, n, padding = " ") {
  let toAdd = n - len(s);
  while (toAdd > 0) {
    s = padding + s;
    toAdd--;
  }
  return s;
}

/**
 * @param {number} timeMs
 * @returns {string}
 */
export function fmtTimeMs(timeMs) {
  if (timeMs == 0) {
    return "";
  }
  let d = new Date();
  d.setTime(timeMs);
  // let s = d.toDateString();
  let m = d.getMonth();
  let day = d.getDay();
  let y = d.getFullYear();
  let h = d.getHours();
  let tz = "AM";
  if (h > 12) {
    tz = "PM";
    h -= 12;
  }
  let hStr = pad(`${h}`, 2, " ");
  let min = d.getMinutes();
  let minStr = pad(`${min}`, 2, "0");
  let s = `${m}/${day}/${y} ${hStr}:${minStr} ${tz}`;
  return s;
}

/**
 * if key is "|foo.bar", we return o["foo"]["bar"]
 * otherwise it's just o[key]
 * @param {Object} o
 * @param {string} key
 * @returns {any}
 */
export function getObjectValue(o, key) {
  if (!key.startsWith("|")) {
    return o[key];
  }
  key = key.substring(1);
  let keys = key.split(".");
  throwIf(len(keys) < 2, `${key} should be multi-level`);
  for (let k of keys) {
    o = o[k];
    if (!o) {
      return null;
    }
  }
  return o;
}

export function fnNoOp() {
  // just a no-op function
  // more efficient than doing () => {} many times
}

// https://svelte.dev/examples/modal
/**
 *
 * @param {*} parent
 * @param {KeyboardEvent} e
 */
export function trapFocus(parent, e) {
  const nodes = parent.querySelectorAll("*:not([disabled])");
  const tabbable = Array.from(nodes).filter((n) => n.tabIndex >= 0);

  let index = tabbable.indexOf(document.activeElement);
  if (index === -1 && e.shiftKey) index = 0;

  index += tabbable.length + (e.shiftKey ? -1 : 1);
  index %= tabbable.length;

  tabbable[index].focus();
}
