<script>
  export let size = 16;
</script>

<!--
<svg
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
  role="img"
  width={size}
  height={size}
  preserveAspectRatio="xMidYMid meet"
  viewBox="0 0 48 48"
  ><rect x="0" y="0" width="48" height="48" fill="none" stroke="none" /><path
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M39.5 15.5h-9a2 2 0 0 1-2-2v-9h-18a2 2 0 0 0-2 2v35a2 2 0 0 0 2 2h27a2 2 0 0 0 2-2Zm-11-11l11 11"
  /></svg
>
-->

<svg
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
  role="img"
  width={size}
  height={size}
  preserveAspectRatio="xMidYMid meet"
  viewBox="0 0 1024 1024"
  ><rect
    x="0"
    y="0"
    width="1024"
    height="1024"
    fill="none"
    stroke="none"
  /><path
    fill="currentColor"
    d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494z"
  /></svg
>

<!--
<svg
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
  role="img"
  width={size}
  height={size}
  preserveAspectRatio="xMidYMid meet"
  viewBox="0 0 16 16"
  ><path
    fill="currentColor"
    d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"
  /></svg
>
-->

<!--

<svg
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
  role="img"
  width={size}
  height={size}
  preserveAspectRatio="xMidYMid meet"
  viewBox="0 0 16 16"
  ><rect x="0" y="0" width="16" height="16" fill="none" stroke="none" /><path
    fill="currentColor"
    d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"
  /></svg
>
-->
