export const sortNone = 0;
export const sortUp = 1;
export const sortDown = 2;

export function cmpStringsNoCase(s1, s2) {
  return s1.localeCompare(s2, undefined, { sensitivity: "base" });
}

export function cmpStringsNaturalNoCase(s1, s2) {
  return s1.localeCompare(s2, navigator.languages[0] || navigator.language, {
    numeric: true,
    ignorePunctuation: true,
    // sensitivity: "base",
  });
}

// Note: I think it's un-intuitive but in explorer and opus
// "sort down" means "large / z first" and "sort up" means "small / a first"

function nameSortUp(e1, e2) {
  // directories sort before files
  if (e1.isDir && !e2.isDir) {
    return -1;
  }
  if (!e1.isDir && e2.isDir) {
    return 1;
  }
  if (e1.name == "..") {
    return -1;
  }
  if (e2.name == "..") {
    return 1;
  }
  return cmpStringsNaturalNoCase(e1.name, e2.name);
}

function nameSortDown(e1, e2) {
  // directories sort before files
  if (e1.isDir && !e2.isDir) {
    return -1;
  }
  if (!e1.isDir && e2.isDir) {
    return 1;
  }
  if (e1.name == "..") {
    return -1;
  }
  if (e2.name == "..") {
    return 1;
  }
  return cmpStringsNoCase(e2.name, e1.name);
}

function cmdNum(n1, n2) {
  if (n1 > n2) {
    return 1;
  }
  if (n1 == n2) {
    return 0;
  }
  return -1;
}

function lastModSortUp(e1, e2) {
  // directories sort before files
  if (e1.isDir && !e2.isDir) {
    return -1;
  }
  if (!e1.isDir && e2.isDir) {
    return 1;
  }

  // directories sort by name
  if (e1.isDir && e2.isDir) {
    return nameSortUp(e1, e2);
  }

  return cmdNum(e1.lastMod, e2.lastMod);
}

function lastModSortDown(e1, e2) {
  // directories sort before files
  if (e1.isDir && !e2.isDir) {
    return -1;
  }
  if (!e1.isDir && e2.isDir) {
    return 1;
  }

  // directories sort by name
  if (e1.isDir && e2.isDir) {
    return nameSortUp(e1, e2);
  }

  return cmdNum(e2.lastMod, e1.lastMod); // reverse
}

function sizeSortUp(e1, e2) {
  // directories sort before files
  if (e1.isDir && !e2.isDir) {
    return -1;
  }
  if (!e1.isDir && e2.isDir) {
    return 1;
  }

  // directories sort by name
  if (e1.isDir && e2.isDir) {
    return nameSortUp(e1, e2);
  }

  return cmdNum(e1.size, e2.size);
}

function sizeSortDown(e1, e2) {
  // directories sort before files
  if (e1.isDir && !e2.isDir) {
    return -1;
  }
  if (!e1.isDir && e2.isDir) {
    return 1;
  }

  // directories sort by name
  if (e1.isDir && e2.isDir) {
    return nameSortUp(e1, e2);
  }

  return cmdNum(e2.size, e1.size); // reverse
}

export function sortFiles(entries, nameSort, sizeSort, lastModSort) {
  // console.log("sortFiles:, nameSort:", nameSort, "sizeSort:", sizeSort);
  let fnSort = nameSortUp;

  if (nameSort == sortDown) {
    fnSort = nameSortDown;
  }

  if (sizeSort == sortUp) {
    fnSort = sizeSortUp;
  } else if (sizeSort == sortDown) {
    fnSort = sizeSortDown;
  }
  if (lastModSort == sortUp) {
    fnSort = lastModSortUp;
  } else if (lastModSort == sortDown) {
    fnSort = lastModSortDown;
  }

  entries.sort(fnSort);
}
