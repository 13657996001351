<script>
  import { getContext } from "svelte";
  import DialogBase from "./DialogBase.svelte";
  import { focus } from "./actions/focus";
  import { len, inflect, fmtSize } from "./util";

  /** @type {import("./fs").FileInfo[]} */
  export let files = [];
  export let open = false;

  let fnUploadFiles = getContext("fnUploadFiles");

  let limitedFiles = [];
  let nFiles = 0;
  let totalSize = 0;

  let max = 10;

  let n = len(files);
  for (let i = 0; i < n; i++) {
    let fi = files[i];
    if (i < max) {
      limitedFiles.push(fi);
    }
    nFiles++;
    totalSize += fi.size;
  }

  let nNotShown = n - max;

  function msg() {
    let s = "Upload ";
    s +=
      `<b>${nFiles}</b> ` +
      inflect("file", nFiles) +
      " of total size <b>" +
      fmtSize(totalSize) +
      "</b> ?";
    return s;
  }

  function close() {
    open = false;
  }
</script>

<DialogBase bind:open title="Upload Files">
  <div slot="main" class="flex flex-col">
    <div>{@html msg()}</div>
    <div class="mt-4">Files to upload:</div>

    <div class="table">
      <div class="table-row-group">
        {#each limitedFiles as fi}
          <div class="table-row font-mono">
            <!-- TODO: better way to truncate table -->
            <div class="table-cell pl-4 text-sm max-w-[60vw] truncate">
              {fi.name}
            </div>
            <div class="table-cell pl-4 text-sm text-right  ">
              {fmtSize(fi.size)}
            </div>
          </div>
        {/each}
        {#if nNotShown > 0}
          <div class="font-mono ml-4 text-sm truncate maxw">
            ... and {nNotShown} more
          </div>
        {/if}
      </div>
    </div>
  </div>

  <div slot="bottom" class="flex justify-end">
    <button
      use:focus
      class="px-4 py-2 hover:bg-gray-200 border border-gray-400"
      on:click={close}>Cancel</button
    >

    <button
      class="ml-8 px-4 py-2 hover:bg-gray-200 font-semibold border border-gray-400"
      on:click={fnUploadFiles}>Upload</button
    >
  </div>
</DialogBase>
