<script>
  import { createEventDispatcher } from "svelte/internal";
  import clickoutside from "./actions/clickoutside";
  import {
    bookmarkExists,
    bookmarkName,
    getBookmarks,
    removeBookmark,
  } from "./bookmarks";
  import { len } from "./util";

  export let currBookmark;

  let bookmarks = getBookmarks();
  let inBookmarks = bookmarkExists(bookmarks, currBookmark);

  const dispatch = createEventDispatcher();

  function dismiss() {
    dispatch("dismiss");
  }

  function addToBookmarks() {
    dispatch("addtobookmarks");
    dismiss();
  }

  /**
   * @param {import("./bookmarks").Bookmark} b
   */
  function goToBookmark(b) {
    dispatch("gotobookmark", b);
    dismiss();
  }

  function removeCurrentBookmark() {
    removeBookmark(currBookmark);
    dismiss();
  }

  function editBookmars() {
    console.log("editBookmarks");
  }
</script>

<div
  on:clickoutside={dismiss}
  use:clickoutside
  class="absolute flex flex-col bg-white z-20 px-2 py-2 border border-gray-300 shadow-md cursor-pointer top-[100%] left text-sm text-black max-h-[85vh] max-w-[42vw] overflow-auto z-20"
>
  {#if inBookmarks}
    <div
      class="flex hover:bg-gray-200 truncate px-2 py-0.5 min-h-[1.5rem] text-red-500"
      on:click|stopPropagation={removeCurrentBookmark}
    >
      Remove this folder from bookmarks
    </div>
  {:else}
    <div
      class="flex hover:bg-gray-200 truncate px-2 py-0.5 min-h-[1.5rem]"
      on:click|stopPropagation={addToBookmarks}
    >
      Bookmark this folder
    </div>
  {/if}

  <div
    class="flex hover:bg-gray-200 truncate px-2 py-0.5 hidden"
    on:click={editBookmars}
  >
    Edit bookmarks
  </div>
  {#if len(bookmarks) > 0}
    <hr class="mt-2 mb-2 py-0.5" />
  {/if}
  {#each bookmarks as b}
    <div
      class="flex hover:bg-gray-200 truncate px-2 py-0.5 min-h-[1.5rem]"
      on:click|stopPropagation={() => goToBookmark(b)}
    >
      {bookmarkName(b)}
    </div>
  {/each}
</div>
