<script>
  import { getContext } from "svelte";
  import DialogBase from "./DialogBase.svelte";
  import { focus } from "./actions/focus";

  import { len, inflect, fmtSize } from "./util";

  /** @type {import("./fs").FileInfo[]} */
  export let files = [];
  export let open = false;

  let fnDeleteFiles = getContext("fnDeleteFiles");

  let limitedFiles = [];
  let paths = [];
  let nFiles = 0;
  let nDirs = 0;
  let totalSize = 0;

  let max = 10;

  let n = len(files);
  for (let i = 0; i < n; i++) {
    let fi = files[i];
    let path = fi.path;
    totalSize += fi.size;
    paths.push(path);
    if (i < max) {
      limitedFiles.push(fi);
    }
    if (path.endsWith("/")) {
      nDirs++;
    } else {
      nFiles++;
    }
  }

  let nNotShown = n - max;

  function msg() {
    let s = "Delete ";
    if (nDirs > 0) {
      s += ` <b>${nDirs}</b> ` + inflect("dir", nDirs);
      if (nFiles > 0) {
        s += ` and `;
      }
    }
    if (nFiles > 0) {
      s += `<b>${nFiles}</b> ` + inflect("file", nFiles);
      s += " of total size <b>";
      s += fmtSize(totalSize) + "</b>";
    }
    s += "?";
    return s;
  }
  let dirInflected = "directories";
  if (nDirs == 1) {
    dirInflected = "directory";
  }

  function close() {
    open = false;
  }
</script>

<DialogBase bind:open title="Delete">
  <div slot="main">
    <div>{@html msg()}</div>
    <div class="mt-4">Will delete:</div>

    <div class="table w-full">
      <div class="table-row-group">
        {#each limitedFiles as fi}
          <div class="table-row font-mono">
            <div class="table-cell pl-4 text-sm truncate max-w-[80vw]">
              {fi.path}
            </div>
            <div class="table-cell pl-4 text-sm text-right  ">
              {fmtSize(fi.size)}
            </div>
          </div>
        {/each}
        {#if nNotShown > 0}
          <div class="font-mono ml-4 text-sm truncate max-w-[80vw]">
            ... and {nNotShown} more
          </div>
        {/if}
        {#if nDirs > 0}
          <div class="font text-red-500 text-sm mt-2">
            Warning: deleting <span class="font-bold">{nDirs}</span>
            {dirInflected}. Could delete lots of files!
          </div>
        {/if}
      </div>
    </div>
  </div>

  <div slot="bottom" class="flex justify-end">
    <button
      use:focus
      class="px-4 py-2 hover:bg-gray-200 border border-gray-400"
      on:click={close}>Cancel</button
    >
    <button
      class="ml-8 px-4 py-2 hover:bg-gray-200 text-red-400 font-semibold border border-gray-400"
      on:click={() => {
        fnDeleteFiles(paths);
      }}>Delete</button
    >
  </div>
</DialogBase>
