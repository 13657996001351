import { len, throwIf } from "./util";

const menuSeparator = "---";

export class MenuItem {
  label = "";
  shortcut = "";
  handler;
  items = [];

  constructor(l, h, items = []) {
    throwIf(!l, "needs label");
    throwIf(!h && l != menuSeparator && len(items) == 0, "needs handler");
    this.label = l;
    this.handler = h;
    this.items = items;
  }
  get isShowing() {
    return len(this.items) > 0;
  }
  isSeparator() {
    return this.label == menuSeparator;
  }
  isSubMenu() {
    return len(this.items) > 0;
  }
}

export const menuItemSeparator = new MenuItem(menuSeparator);

export function makeSubMenu(l, items) {
  return new MenuItem(l, null, items);
}
