<script>
  import { getContext, onMount } from "svelte";
  import DialogBase from "./DialogBase.svelte";

  import * as filepath from "./filepath";

  export let path = "";
  export let ignoreCase = false;
  // if true, we can only change file name,
  // not directory
  export let destSameDir = false;
  export let open = false;

  console.log("DialogRename: path:", path);
  let inputEl;

  let fnRename = getContext("fnRename");

  // console.log("DialogRename: path=", path);
  let dir = "";
  let fileName = "";
  let renameDisabled = true;
  let newPath = "";
  let suff = "";

  let errorMsg = "";

  function setFocus() {
    let s = destSameDir ? fileName : path;
    let idx = filepath.indexOfExt(s);
    if (inputEl) {
      inputEl.focus();
      if (idx >= 0) {
        inputEl.setSelectionRange(idx, idx);
      }
    }
  }
  onMount(() => {
    setFocus();
    let parts = filepath.split(path);
    dir = parts.dir;
    fileName = parts.name;
    suff = dir == "/" ? "" : "/";
  });

  $: newPath = filepath.join(dir, fileName);
  $: errorMsg = buildErrorMessage(newPath);
  $: warningMsg = buildWarningMessage(newPath);

  function buildErrorMessage(newPath) {
    renameDisabled = false;
    if (newPath == "") {
      renameDisabled = true;
      return "name can't be empty";
    }
    let msg = "destination path must be different";
    if (ignoreCase) {
      msg = "destination name must be different, case insensitive";
    }
    if (newPath == path) {
      renameDisabled = true;
      return msg;
    }
    if (ignoreCase && newPath.toLowerCase() == path.toLowerCase()) {
      renameDisabled = true;
      return msg;
    }
    if (destSameDir) {
      let srcDir = filepath.dir(path);
      let dstDir = filepath.dir(newPath);
      if (srcDir != dstDir) {
        msg = "must be in same directory";
        renameDisabled = true;
        return msg;
      }
    }
    return "";
  }

  function buildWarningMessage(newPath) {
    if (newPath.endsWith(" ")) {
      return "<span>Warning: file name ends with spaces</span>";
    }
    const ext1 = filepath.ext(path);
    const ext2 = filepath.ext(newPath);
    if (ext1 != ext2) {
      return `<span>Warning: changing extension: <b>${ext1}</b> to <b>${ext2}</b></span>`;
    }

    return "";
  }

  /**
   * @param {KeyboardEvent} ev
   */
  function handleKeyDown(ev) {
    console.log("DialogRename: handleKeyDown ev:", ev);
    let isEnter = ev.key === "Enter";
    if (isEnter && !errorMsg) {
      // dispatch("fmnewdir", fullNewPath);
      ev.preventDefault();
      ev.stopPropagation();
      fnRename({ path, newPath });
      return;
    }
  }

  function close() {
    open = false;
  }
</script>

<DialogBase bind:open title="Rename">
  <div slot="main" class="flex flex-col" on:keydown={handleKeyDown}>
    <div class="mt-2">{path}</div>
    <div class="font-semibold mt-2">to:</div>
    {#if destSameDir}
      <div class="flex items-baseline mt-2">
        <div>{dir}{suff}</div>
        <input
          type="text"
          autocomplete="off"
          spellcheck="false"
          class="border-b py-1 focus:outline-none focus:border-gray-400 w-full"
          bind:value={fileName}
          bind:this={inputEl}
        />
      </div>
    {:else}
      <div class="flex items-baseline mt-2">
        <input
          type="text"
          autocomplete="off"
          spellcheck="false"
          class="border-b py-1 focus:outline-none focus:border-gray-400 w-full"
          bind:value={newPath}
          bind:this={inputEl}
        />
      </div>
    {/if}
    {#if errorMsg}
      <div
        class="text-sm text-red-500 mt-2 min-w-[32rem] min-h-[1.5em] max-w-full"
      >
        {errorMsg}
      </div>
    {:else}
      <div
        class="text-sm text-gray-600 mt-2 min-w-[32rem] min-h-[1.5em] max-w-full"
      >
        {@html warningMsg}
      </div>
    {/if}
  </div>

  <div slot="bottom" class="flex justify-end">
    <button
      class="px-4 py-2 hover:bg-gray-200 border border-gray-400"
      on:click={close}>Cancel</button
    >
    <button
      disabled={renameDisabled}
      class="ml-8 px-4 py-2 enabled:hover:bg-gray-200 text-gray-800 font-semibold border  border-gray-400 disabled:opacity-50"
      on:click={fnRename({ path, newPath })}>Rename</button
    >
  </div>
</DialogBase>
