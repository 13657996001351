import { fmtSize } from "./util";
import * as filepath from "./filepath";
import { getDirFilesRelativePath } from "./fs";

export class CopyInfo {
  /** @type {import("./fs").FS} */
  fsSrc = null;
  /** @type {import("./fs").FS} */
  fsDst = null;

  srcDir = "";
  dstDir = "";
  entriesToCopy = [];
}

/**
 *
 * @param {import("./fs").FS} fsSrc
 * @param {string} srcPath
 * @param {import("./fs").FS} fsDst
 * @param {string} dstPath
 * @param {import("svelte/store").Writable} progress
 * @param {boolean} isMove
 */
async function copyMoveFile(fsSrc, srcPath, fsDst, dstPath, progress, isMove) {
  const verb = isMove ? "move" : "copy";
  progress.set(`${verb} ${srcPath}: downloading`);
  function p1(size, totalSize, durMs) {
    if (totalSize > 0) {
      progress.set(
        `${verb} ${srcPath}: downloading ${fmtSize(size)} of ${fmtSize(
          totalSize
        )}`
      );
    } else {
      progress.set(`${verb} ${srcPath}: downloading ${fmtSize(size)}`);
    }
  }

  let blob = await fsSrc.downloadFile(srcPath, p1);
  progress.set(`${verb} ${srcPath}: uploading`);
  function p2(size, totalSize, durMs) {
    if (totalSize > 0) {
      progress.set(
        `${verb} ${srcPath}: downloading ${fmtSize(size)} of ${fmtSize(
          totalSize
        )}`
      );
    } else {
      progress.set(`${verb} ${srcPath}: downloading ${fmtSize(size)}`);
    }
  }
  await fsDst.uploadFile(dstPath, blob, p2);
  if (isMove) {
    progress.set(`deleting ${srcPath}`);
    await fsSrc.deleteFile(srcPath);
  }
}

/**
 * @param {CopyInfo} ci
 * @param {import("svelte/store").Writable} progress
 * @param {boolean} isMove
 */
export async function fsCopyMove(ci, progress, isMove) {
  console.log("fsCopyMove: ", ci, "isMove:", isMove);
  // first copy files
  const fsSrc = ci.fsSrc;
  const fsDst = ci.fsDst;
  const srcDir = ci.srcDir;
  const dstDir = ci.dstDir;

  // copy files first
  for (let e of ci.entriesToCopy) {
    if (e.isDir) {
      continue;
    }
    let name = e.name;
    let srcPath = filepath.join(srcDir, name);
    let dstPath = filepath.join(dstDir, name);
    await copyMoveFile(fsSrc, srcPath, fsDst, dstPath, progress, isMove);
  }

  // recursively copy directories
  for (let e of ci.entriesToCopy) {
    if (!e.isDir) {
      continue;
    }
    let srcDirPath = filepath.join(srcDir, e.name);
    let dstDirPath = filepath.join(dstDir, e.name);
    let files = await getDirFilesRelativePath(fsSrc, srcDirPath, true);
    for (let name of files) {
      let srcPath = filepath.join(srcDirPath, name);
      let dstPath = filepath.join(dstDirPath, name);
      await copyMoveFile(fsSrc, srcPath, fsDst, dstPath, progress, isMove);
    }
  }
}
