import { get, writable } from "svelte/store";
import {
  fsTypeDropbox,
  fsTypeLocal,
  fsTypeS3,
  fsTypeTest,
  S3Config,
  DropboxConfig,
  LocalConfig,
  fsTypeGoogleDrive,
  GoogleDriveConfig,
  OneDriveConfig,
  fsTypeOneDrive,
  BackBlazeConfig,
  fsTypeBackBlaze,
  FSConfig,
  FSDirState,
  FS,
  fsID,
} from "./fs";
import { makeFileSystemDropbox } from "./fs-dropbox";
import { makeFileSystemGoogleDrive } from "./fs-gdrive";
import { makeFileSystemOneDrive } from "./fs-onedrive";
import { makeFileSystemLocal } from "./fs-local";
import { makeFileSystemS3 } from "./fs-s3";
import { makeFileSystemTest } from "./fs-testfiles";
import { cmpStringsNoCase } from "./sort";
import { len, patchPrototype, throwIf } from "./util";
import { makeFileSystemBackBlaze } from "./fs-backblaze";

// persists confing information about connected storage accounts
const lsKeyStorageAccounts = "fm-connected-storage";

/**
 * @returns {FSConfig[]}
 */
export function getFSConfigsFromStorage() {
  const s = localStorage.getItem(lsKeyStorageAccounts);
  /** @type {FSConfig[]} */
  let res = JSON.parse(s || "[]");
  for (let o of res) {
    // turn plain js objects into js classes
    if (o.localConfig) {
      patchPrototype(o.localConfig, LocalConfig.prototype);
    }

    if (o.dropboxConfig) {
      patchPrototype(o.dropboxConfig, DropboxConfig.prototype);
    }

    if (o.googleDriveConfig) {
      patchPrototype(o.googleDriveConfig, GoogleDriveConfig.prototype);
    }

    if (o.oneDriveConfig) {
      patchPrototype(o.oneDriveConfig, OneDriveConfig.prototype);
    }

    if (o.bbConfig) {
      patchPrototype(o.bbConfig, BackBlazeConfig.prototype);
    }

    if (o.s3Config) {
      patchPrototype(o.s3Config, S3Config.prototype);
    }

    patchPrototype(o, FSConfig.prototype);
  }
  return res;
}

const lsKeyBookmarks = "fm-bookmarks";

// bookmark represents an explicit bookmark, an entry
// in navigation history and
export class Bookmark {
  // same as fsID()
  fsID = "";
  dir = "/";
  // only when used as a history navigation item
  selectedName;
}

/**
 * @returns {Bookmark[]}
 */
export function getBookmarksFromStorage() {
  const s = localStorage.getItem(lsKeyBookmarks);
  /** @type {Bookmark[]} */
  let res = JSON.parse(s || "[]");
  for (let o of res) {
    // turn plain js objects into js classes
    patchPrototype(o, Bookmark.prototype);
  }
  return res;
}

let b = getBookmarksFromStorage();
// console.log("getBookmarksFromStorage:", b);
/** @type{import("svelte/store").Writable<Bookmark[]>} */
export let bookmarks = writable(b);

/**
 * @param {Bookmark} b
 * @returns {boolean}
 */
function shouldStoreBookmark(b) {
  if (b.fsID == fsTypeTest) {
    return false;
  }

  // TODO: could store local if dirHandle is stored in indexeddb
  // https://web.dev/file-system-access/#storing-file-handles-or-directory-handles-in-indexeddb
  if (b.fsID.startsWith("local:")) {
    return false;
  }
  return true;
}

/**
 * @param {Bookmark[]} a
 */
function setBookmarks(a) {
  bookmarks.set(a);
  let stored = [];
  for (let b of a) {
    if (shouldStoreBookmark(b)) {
      stored.push(b);
    }
  }
  const s = JSON.stringify(stored);
  localStorage.setItem(lsKeyBookmarks, s);
}

/**
 * @param {FSDirState} fsState
 * @returns {Bookmark}
 */
export function bookmarkFromFSState(fsState) {
  let fs = fsState.fs;
  let b = new Bookmark();
  b.fsID = fsID(fs);
  b.dir = fsState.currDir;
  return b;
}

/**
 * @param {Bookmark} b
 * @returns {string}
 */
export function bookmarkName(b) {
  return b.fsID + ":" + b.dir;
}

/**
 *
 * @param {Bookmark} b1
 * @param {Bookmark} b2
 * @returns boolean
 */
function bookmarkEq(b1, b2) {
  const n1 = bookmarkName(b1);
  const n2 = bookmarkName(b2);
  return n1 == n2;
}

export function bookmarkExists(a, b) {
  for (let b2 of a) {
    if (bookmarkEq(b, b2)) {
      return true;
    }
  }
  return false;
}

/**
 * return true if bookmark represents the same location as filesystem
 * @param {Bookmark} [b]
 * @param {FS} [fs]
 */
export function bookmarkEqFS(b, fs) {
  return b.fsID == fsID(fs);
}

/**
 * @param {Bookmark} b
 * @param {string} dir
 */
function addBookmark(b, dir) {
  b.dir = dir;
  console.log("addBookmark:", b);
  let a = get(bookmarks);
  if (bookmarkExists(a, b)) {
    return;
  }
  a.push(b);
  setBookmarks(a);
}

/**
 * @param {FSDirState} fsState
 */
export function addBookmarkFSState(fsState) {
  console.log("addBookmarkFSState:", fsState);
  const b = bookmarkFromFSState(fsState);
  addBookmark(b, fsState.currDir);
}

export function getBookmarks() {
  return get(bookmarks);
}

/**
 * @param {Bookmark} b1
 * @param {Bookmark} b2
 * @returns {boolean}
 */
function cmpByBookmarkName(b1, b2) {
  const s1 = bookmarkName(b1);
  const s2 = bookmarkName(b2);
  return cmpStringsNoCase(s1, s2);
}

export function sortBookmarks(a) {
  a.sort(cmpByBookmarkName);
}

function removeBookmarkFromArray(a, b) {
  let n = len(a);
  for (let i = 0; i < n; i++) {
    let b2 = a[i];
    if (bookmarkEq(b, b2)) {
      a.splice(i, 1);
      return;
    }
  }
  console.log("didn't remove bookmark:", b);
}

/**
 * @param {Bookmark} b
 */
export function removeBookmark(b) {
  console.log("removeBookmark:", b);
  let a = get(bookmarks);
  removeBookmarkFromArray(a, b);
  setBookmarks(a);
}

// keeps a list of mounted file system so that we don't
// re-read directories on every mount
/** @type {FS[]} */
let fileSystems = [];

/**
s * @param {Bookmark} b 
 * @returns {FS | null}
 */
export function findFSByBookmark(b) {
  for (let fs of fileSystems) {
    if (bookmarkEqFS(b, fs)) {
      return fs;
    }
  }
  return null;
}

/**
 * @param {FS} fs
 */
export function rememberFS(fs) {
  fileSystems.push(fs);
}

/**
 * @param {Bookmark} b
 */
export function makeFSFromBookmark(b) {
  let fs = findFSByBookmark(b);
  if (fs) {
    console.log("makeFSFromBookmark: found fs");
    return fs;
  }
  switch (b.type) {
    case fsTypeTest:
      fs = makeFileSystemTest();
      break;

    case fsTypeLocal:
      fs = makeFileSystemLocal(b.localConfig);
      break;

    case fsTypeDropbox:
      fs = makeFileSystemDropbox(b.dropboxConfig);
      break;

    case fsTypeOneDrive:
      fs = makeFileSystemOneDrive(b.oneDriveConfig);
      break;

    case fsTypeGoogleDrive:
      fs = makeFileSystemGoogleDrive(b.googleDriveConfig);
      break;

    case fsTypeBackBlaze:
      fs = makeFileSystemBackBlaze(b.bbConfig);
      break;

    case fsTypeS3:
      fs = makeFileSystemS3(b.s3Config);
      break;

    default:
      throwIf(true, "unsupported type " + b.type);
      break;
  }
  rememberFS(fs);
  console.log("makeFSFromBookmark: made new one");
  return fs;
}

/**
 * return true if bookmark represents the same location as filesystem
 * @param {FS} [fs1]
 * @param {FS} [fs2]
 */
export function fsEq(fs1, fs2) {
  return fsID(fs1) == fsID(fs2);
}
