<script>
  import { positionModal } from "./actions/positionnode";

  export let uri;
  export let alt;
</script>

<div
  role="dialog"
  use:positionModal
  class="absolute w-fit min-w-[120px] min-h-[120px] bg-white px-4 py-4"
>
  <img src={uri} {alt} class="block" />
</div>

<style>
  img {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90vw;
    max-height: 90vh;
  }
</style>
