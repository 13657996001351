import { writable } from "svelte/store";
import { debounceEvent } from "./util";

export const viewportSize = writable(); // {dx, dy}
export const viewportWidth = writable();

function updateAfterResize() {
  const dx = window.innerWidth;
  const dy = window.innerHeight;
  viewportSize.set({ dx: dx, dy: dy });
  viewportWidth.set(dx);
}

window.addEventListener("resize", debounceEvent(updateAfterResize, 250));
updateAfterResize();

const lsKeyNoUploadInfo = "fm-no-upload-info";
let v = localStorage.getItem(lsKeyNoUploadInfo);
export let showUploadInfo = writable(!v);

export function setShowUploadInfo(v) {
  showUploadInfo.set(v);
  if (v) {
    localStorage.removeItem(lsKeyNoUploadInfo);
  } else {
    localStorage.setItem(lsKeyNoUploadInfo, "true");
  }
}

export let canCopyMove = writable(false);
