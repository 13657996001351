<script>
  import { getContext } from "svelte";
  import DialogBase from "./DialogBase.svelte";
  import { focus } from "./actions/focus";
  import { join } from "./filepath";

  export let currentDir = "";
  export let allowNested = false;
  export let open = false;

  let dirName = "";
  let fullNewPath = "";
  let createDisabled = true;
  let showNoNestedError = false;

  // TODO: pass as prop
  let fnDeleteFiles = getContext("fnNewDirectory");

  $: fullNewPath = join(currentDir, dirName);
  $: showNoNestedError = calcShowNoNested(dirName);
  $: createDisabled = dirName == "" || showNoNestedError;

  function calcShowNoNested(s) {
    if (!allowNested && s.includes("/")) {
      return true;
    }
    return false;
  }

  /**
   * @param {KeyboardEvent} ev
   */
  function handleKeyDown(ev) {
    console.log("DialogNewDirectory: handleKeyDown ev:", ev);
    let isEnter = ev.key == "Enter";
    if (isEnter && !createDisabled) {
      // dispatch("fmnewdir", fullNewPath);
      ev.preventDefault();
      ev.stopPropagation();
      fnDeleteFiles(fullNewPath);
      return;
    }
  }

  function close() {
    open = false;
  }
</script>

<DialogBase bind:open title="Create New Directory">
  <div slot="main" class="flex flex-col" on:keydown={handleKeyDown}>
    <input
      type="text"
      autocomplete="off"
      use:focus
      size="64"
      class="border-b mt-4 px-2 py-1 focus:outline-none focus:border-gray-400"
      bind:value={dirName}
    />
    {#if showNoNestedError}<div class="text-red-600 mt-4">
        sub-directories not allowed
      </div>
    {/if}
    <div class="text-sm text-gray-600 mt-4">
      Will create directory: <span class="bg-gray-50 font-mono"
        >{fullNewPath}</span
      >
    </div>
  </div>

  <div slot="bottom" class="flex justify-end">
    <button
      class="px-4 py-2 hover:bg-gray-200 border border-gray-400"
      on:click={close}>Cancel</button
    >
    <button
      disabled={createDisabled}
      class="ml-8 px-4 py-2 border border-gray-400 enabled:hover:bg-gray-200 text-gray-800 font-semibold  disabled:opacity-50"
      on:click={() => {
        fnDeleteFiles(fullNewPath);
      }}>Create</button
    >
  </div>
</DialogBase>
