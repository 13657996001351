<script>
  import { createEventDispatcher, onMount } from "svelte";

  import DialogConfigS3 from "./DialogConfigS3.svelte";
  import DialogConfigBackBlaze from "./DialogConfigBackBlaze.svelte";

  import {
    fsFullName,
    fsTypeDropbox,
    fsTypeGoogleDrive,
    fsTypeLocal,
    fsTypeOneDrive,
    LocalConfig,
  } from "./fs";
  import { makeFileSystemLocal } from "./fs-local";
  import { makeFileSystemTest } from "./fs-testfiles";
  import { connectDropbox } from "./fs-dropbox-login";
  import { makeFileSystemGoogleDrive } from "./fs-gdrive";
  import { connectOneDrive } from "./fs-onedrive-login";

  import { len, isDev } from "./util";
  import {
    Bookmark,
    bookmarkName,
    bookmarks,
    removeBookmark,
  } from "./bookmarks";
  import { makeFileSystemOneDrive } from "./fs-onedrive";
  import { connectGoogleDrive } from "./fs-gdrive-login";

  const dispatch = createEventDispatcher();

  let showingDialogConfigBackBlaze = false;
  let showingDialogConfigS3 = false;
  let showTestFiles = false;

  let h = window.location.host;
  showTestFiles = h.includes("localhost") || h.includes("onrender.com");

  // we only support one of those
  let connectedBrowserStorage = false;
  let connectedDropbox = false;
  let connectedGoogleDrive = false;
  let connectedOneDrive = false;

  let bookmarksStorage = [];
  let bookmarksBookmarks = [];

  $: updateConnectedFileSystems($bookmarks);

  /**
   * @param {Bookmark[]}a
   */
  function updateConnectedFileSystems(a) {
    connectedBrowserStorage = false;
    connectedDropbox = false;
    connectedGoogleDrive = false;
    connectedOneDrive = false;
    bookmarksStorage = [];
    bookmarksBookmarks = [];

    for (let b of a) {
      if (b.dir == "/") {
        bookmarksStorage.push(b);
      } else {
        bookmarksBookmarks.push(b);
      }
      if (b.type == fsTypeDropbox) {
        connectedDropbox = true;
      } else if (b.type == fsTypeGoogleDrive) {
        connectedGoogleDrive = true;
      } else if (b.type == fsTypeOneDrive) {
        connectedOneDrive = true;
      } else if (b.type == fsTypeLocal && b.name == "Browser Storage") {
        connectedBrowserStorage = true;
      }
    }

    // TODO: for s3, sort by endpoint first then by bucket
    function cmpBookmarkByName(b1, b2) {
      let n1 = fsFullName(b1);
      let n2 = fsFullName(b2);
      if (n1 > n2) {
        return 1;
      } else if (n1 < n2) {
        return -1;
      }
      return 0;
    }
    bookmarksStorage.sort(cmpBookmarkByName);
  }

  async function pickNavigatorStorage() {
    let dirHandle = await navigator.storage.getDirectory();
    let c = new LocalConfig(dirHandle, "Browser Storage");
    const fs = makeFileSystemLocal(c);
    dispatch("mountfs", fs);
  }

  async function pickLocalFolder() {
    let dirHandle;
    try {
      // @ts-ignore
      dirHandle = await window.showDirectoryPicker();
    } catch {
      console.log("pickLocalHolder: dismissed the folder pickup");
      return;
    }
    let c = new LocalConfig(dirHandle, dirHandle.name);
    const fs = makeFileSystemLocal(c);
    dispatch("mountfs", fs);
  }

  function connectTestFiles() {
    let fs = makeFileSystemTest();
    dispatch("mountfs", fs);
  }

  async function onConnectGoogleDrive() {
    const config = await connectGoogleDrive();
    const fs = makeFileSystemGoogleDrive(config);
    console.log("onConnectGoogleDrive: config:", config);
    dispatch("mountfs", fs);
  }

  async function onConnectOneDrive() {
    let config = await connectOneDrive();
    console.log("onConnectedOneDrive: config:", config);
    const fs = makeFileSystemOneDrive(config);
    console.log("onConnected one drive: fs:", fs);
    dispatch("mountfs", fs);
  }

  function onConnectDropbox() {
    connectDropbox(onConnected);
    function onConnected(fs) {
      console.log("onConnected dropbox: fs:", fs);
      dispatch("mountfs", fs);
    }
  }

  // @ts-ignore
  let hasFileSystemSupport = window.showDirectoryPicker;
  // per Sentry, Chrome Mobile 102 doesn't have storage.getDiretory function
  let hasStorage = !!(navigator.storage && navigator.storage.getDirectory);

  onMount(() => {
    console.log("hasStorage: ", hasStorage);
    console.log("connectedBrowserStorage: ", connectedBrowserStorage);
  });
  /**
   * @param {Bookmark} b
   */
  function selectBookmark(b) {
    console.log("selectBookmark", b);
    dispatch("gotobookmark", b);
    return;
  }
</script>

<div
  class="wrapper flex flex-col items-center bg-white border pt-8 max-h-full overflow-y-auto"
>
  {#if showingDialogConfigBackBlaze}
    <DialogConfigBackBlaze
      bind:open={showingDialogConfigBackBlaze}
      on:mountfs
    />
  {/if}
  {#if showingDialogConfigS3}
    <DialogConfigS3 bind:open={showingDialogConfigS3} on:mountfs />
  {/if}

  {#if len(bookmarksStorage)}
    <div class="self-center font-bold">Connected Storage Services</div>
    <div
      class="flex flex-col mt-2 max-w-[85%] max-h-[50vh] overflow-y-auto px-3"
    >
      {#each bookmarksStorage as b}
        <div class="flex flex-row justify-between items-baseline text-sm">
          <button
            on:click={() => selectBookmark(b)}
            class="self-left hover:bg-gray-200 cursor-pointer px-2 py-1 truncate"
            tabindex="0"
          >
            {bookmarkName(b)}
          </button>
          <button
            class="ml-12 hover:underline hover:underline-offset-2 cursor-pointer text-gray-400 text-sm"
            on:click={() => removeBookmark(b)}
          >
            remove
          </button>
        </div>
      {/each}
    </div>
  {/if}

  {#if len(bookmarksBookmarks)}
    <div class="self-center font-bold">Bookmarks</div>
    <div class="flex flex-col max-w-[85%] max-h-[50vh] overflow-y-auto px-3">
      {#each bookmarksBookmarks as b}
        <div class="flex flex-row justify-between items-baseline text-sm">
          <button
            on:click={() => selectBookmark(b)}
            class="self-left hover:bg-gray-200 cursor-pointer px-2 py-1 truncate"
            tabindex="0"
          >
            {bookmarkName(b)}
          </button>
          <button
            class="ml-12 hover:underline hover:underline-offset-2 cursor-pointer text-gray-400 text-sm"
            on:click={() => removeBookmark(b)}
          >
            remove
          </button>
        </div>
      {/each}
    </div>
  {/if}

  <div class="flex flex-col mt-8">
    {#if hasStorage && !connectedBrowserStorage}
      <div class="flex items-baseline justify-end">
        <button
          on:click={pickNavigatorStorage}
          class="border border-gray-400 mb-4 py-1 px-4 hover:bg-gray-200 grow"
          >Open Browser Storage</button
        >
        <a class="text-blue-600 underline ml-4 hidden" href="">help</a>
      </div>
    {/if}

    {#if hasFileSystemSupport}
      <div class="flex items-baseline justify-end">
        <button
          on:click={pickLocalFolder}
          class="border border-gray-400 mb-4 py-1 px-4 hover:bg-gray-200 grow"
          >Open Local Folder</button
        >
        <a class="text-blue-600 underline ml-4 hidden" href="">help</a>
      </div>
    {/if}
    {#if !connectedDropbox}
      <div class="flex items-baseline justify-end">
        <button
          on:click={onConnectDropbox}
          class="border border-gray-400 mb-4 py-1 px-4 hover:bg-gray-200 grow"
          >Connect Dropbox</button
        >
        <a class="text-blue-600 underline ml-4 hidden" href="">help</a>
      </div>
    {/if}

    {#if isDev() && !connectedGoogleDrive}
      <div class="flex items-baseline justify-end">
        <button
          on:click={onConnectGoogleDrive}
          class="border border-gray-400 mb-4 py-1 px-4 hover:bg-gray-200 grow"
          >Connect Google Drive</button
        >
        <a class="text-blue-600 underline ml-4 hidden" href="">help</a>
      </div>
    {/if}

    {#if !connectedOneDrive}
      <div class="flex items-baseline justify-end">
        <button
          on:click={onConnectOneDrive}
          class="border border-gray-400 mb-4 py-1 px-4 hover:bg-gray-200 grow"
          >Connect One Drive</button
        >
        <a class="text-blue-600 underline ml-4 hidden" href="">help</a>
      </div>
    {/if}

    <div class="flex items-baseline justify-end">
      <button
        on:click={() => (showingDialogConfigS3 = true)}
        class="border border-gray-400 mb-4 py-1 px-4 hover:bg-gray-200 grow"
        >Connect S3</button
      >
      <a class="text-blue-600 underline ml-4 hidden" href="">help</a>
    </div>

    {#if isDev()}
      <div class="flex items-baseline justify-end">
        <button
          on:click={() => (showingDialogConfigBackBlaze = true)}
          class="border border-gray-400 mb-4 py-1 px-4 hover:bg-gray-200 grow"
          >Connect BackBlaze</button
        >
        <a class="text-blue-600 underline ml-4 hidden" href="">help</a>
      </div>
    {/if}

    <!--
    <button
      on:click={() => (showingConfigBackBlaze = true)}
      use:equalwidth={eqsize}
      class="border-2 mb-4 py-1 px-4 hover:bg-gray-200"
      >Connect BackBlaze</button
    >
    -->

    {#if showTestFiles}
      <button
        on:click={connectTestFiles}
        class="border border-gray-400 mb-4 py-1 px-4 hover:bg-gray-200"
        >Connect Test Files</button
      >
    {/if}
  </div>
  <!--
    <button
      on:click={connectOneDrive}
      use:equalwidth={eqsize}
      class="border-2 mb-4 py-1 px-4 hover:bg-gray-200">Connect OneDrive</button
    >
    <button
      on:click={connectOneDrive}
      use:equalwidth={eqsize}
      class="border-2 mb-4 py-1 px-4 hover:bg-gray-200">Connect Google Drive</button
    >
    -->
</div>

<style>
  .wrapper {
    max-height: 100%;
    border: 1px solid white; /* don't re-layout when :focus-within */
    border: 2px solid #ccc;
  }

  .wrapper:focus-within {
    border: 1px solid #ccc;
    border: 2px solid #999;
    outline: none; /* otherwise default black outline is shown */
  }
</style>
