import { writable } from "svelte/store";
import * as filepath from "./filepath";
import {
  fmtSize,
  len,
  throwIf,
  trimSuffix,
  nanoid,
  getObjectValue,
} from "./util";
import { showMessage } from "./message";
import { xhrGetAsBlob } from "./http";

/**
 * @typedef {import('./fsentry').FSEntry} FSEntry
 */

export const fsTypeLocal = "local";
export const fsTypeS3 = "s3";
export const fsTypeDropbox = "dropbox";
export const fsTypeGoogleDrive = "gdrive";
export const fsTypeOneDrive = "onedrive";
export const fsTypeBackBlaze = "backblaze";
export const fsTypeTest = "testfiles";

/**
 * @typedef {"local" | "s3" | "dropbox" | "gdrive" | "onedrive" | "backblaze" | "testfiles" } FSType
 */

export class S3Config {
  name = "";
  access = "";
  secret = "";
  bucket = "";
  endpoint = "";
}

/**
 * @param {S3Config} c1
 * @param {S3Config} c2
 * @returns {boolean}
 */
export function s3ConfigEq(c1, c2) {
  return (
    c1.access == c2.access &&
    c1.secret == c2.secret &&
    c1.bucket == c2.bucket &&
    c1.endpoint == c2.endpoint
  );
}

export class DropboxConfig {
  accessToken = "";
  refreshToken = "";
  email = "";
  name = "";
}

/**
 * there's only one dropbox so they are always equal
 * @param {DropboxConfig} c1
 * @param {DropboxConfig} c2
 * @returns {boolean}
 */
export function dropboxConfigEq(c1, c2) {
  return true;
}
export class GoogleDriveConfig {
  accessToken = "";
  refreshToken = "";
  tokenExpires = "";
  email = "";
  name = "";
}

/**
 * @param {GoogleDriveConfig} c1
 * @param {GoogleDriveConfig} c2
 * @returns {boolean}
 */
export function googleDriveConfigEq(c1, c2) {
  return c1.email == c2.email;
}

export class OneDriveConfig {
  accessToken = "";
  refreshToken = "";
  tokenExpires = ""; // Date as string
  email = "";
  name = "";
}

/**
 * @param {OneDriveConfig} c1
 * @param {OneDriveConfig} c2
 * @returns {boolean}
 */
export function oneDriveConfigEq(c1, c2) {
  return c1.email == c2.email;
}

export class LocalConfig {
  name = "";
  // unique id because folder names are not unique
  id = "";
  dirHandle; // not persisted
  constructor(dirHandle, name) {
    this.dirHandle = dirHandle;
    this.name = name;
    this.id = nanoid();
  }
}

/**
 * @param {LocalConfig} c1
 * @param {LocalConfig} c2
 * @returns {boolean}
 */
export function localConfigEq(c1, c2) {
  if (c1.dirHandle && c2.dirHandle) {
    return c1.dirHandle.isSameEntry(c2.dirHandle);
  }
  return c1.id == c2.id;
}

export class BackBlazeConfig {
  name = "";
  appKeyId = "";
  appKey = "";
  bucketId = "";
  bucketName = "";
}

/**
 * @param {BackBlazeConfig} c1
 * @param {BackBlazeConfig} c2
 * @returns {boolean}
 */
export function backBlazeConfigEq(c1, c2) {
  if (c1.appKeyId != c2.appKeyId) {
    return false;
  }
  if (c1.appKey != c2.appKey) {
    return false;
  }
  if (c1.bucketId != c2.bucketId) {
    return false;
  }
  return true;
}

// re-using this in mulitple contexts for simplicity
export class FileInfo {
  name = "";
  size = 0;
  /** @type {File|null} */
  file = null;
  path = "";
  // when uploading, this is destination path
  dstPath = "";
}

/**
 * Copy properties from objet to FSEntry
 * @param {string[]} props
 * @param {Object} o
 * @param {Array} e
 */
export function fsCopyProps(props, o, e) {
  for (let propName of props) {
    let v = getObjectValue(o, propName);
    if (v) {
      e.push(propName, v);
    }
  }
}

class DirAndFileFSEntry {
  /** @type {FSEntry} */
  dir = null;
  /** @type {FSEntry} */
  entry = null;
  constructor(dir) {
    this.dir = dir;
  }
}

export class FSConfig {
  /** @type {FSType} */
  type;

  /** @type {DropboxConfig} */
  dropboxConfig;

  /** @type {LocalConfig} */
  localConfig;

  /** @type {GoogleDriveConfig} */
  googleDriveConfig;

  /** @type {OneDriveConfig} */
  oneDriveConfig;

  /** @type {BackBlazeConfig} */
  bbConfig;

  /** @type {S3Config} */
  s3Config;

  /**
   * @param {FSType} [fsType]
   */
  constructor(fsType) {
    this.type = fsType;
  }
}

/**
 * @param {FSConfig} fs
 * @returns {string}
 */
export function fsID(fs) {
  switch (fs.type) {
    case fsTypeTest:
      return fsTypeTest;
    case fsTypeLocal:
      return "local:" + fs.localConfig.name;
    case fsTypeDropbox:
      return "dropbox";
    case fsTypeGoogleDrive:
      return "gdrive";
    case fsTypeOneDrive:
      return "onedrive";
    case fsTypeBackBlaze:
      return;
  }
}

/**
 * @param {FSConfig} fs
 * @returns {string}
 */
export function fsName(fs) {
  switch (fs.type) {
    case fsTypeTest:
      return "test files";

    case fsTypeLocal:
      return fs.localConfig.name;

    case fsTypeDropbox:
      return "dropbox";

    case fsTypeGoogleDrive:
      return "gdrive";

    case fsTypeOneDrive:
      return "onedrive";

    case fsTypeBackBlaze:
      return fs.bbConfig.bucketName;

    case fsTypeS3:
      return fs.s3Config.name;
  }
  throwIf(true, `unsupported fs.type: ${fs.type}`);
}

/**
 * @param {FSConfig} fs
 * @returns {string}
 */
export function fsFullName(fs, dir) {
  let name = fsName(fs);
  switch (fs.type) {
    case fsTypeS3:
      let s3Name = fs.s3Config.bucket + "." + fs.s3Config.endpoint;
      if (name == "") {
        name = s3Name;
      } else {
        name = name + " (" + s3Name + ")";
      }
      break;

    case fsTypeBackBlaze:
      name = "backblaze:" + fs.bbConfig.bucketName;
      break;

    case fsTypeLocal:
      name = "folder:" + name;
  }
  return name + ":" + dir;
}

export class FS extends FSConfig {
  /** @type {Map<string, FSEntry>} */
  dirs = new Map();
  filesCount = 0;
  dummyProgress = writable("");

  /**
   * @param {FSType} fsType
   */
  constructor(fsType) {
    super(fsType);
  }

  get name() {
    return fsName(this);
  }

  /**
   * @param {string} path
   * @returns {DirAndFileFSEntry | null}
   */
  findDirAndFileFSEntry(path) {
    const isDir = path.endsWith("/");
    if (isDir && path != "/") {
      path = path.substring(0, len(path) - 1);
    }

    const parts = filepath.split(path);
    let dirName = parts.dir;
    let entryName = parts.name;

    const dir = this.dirs.get(dirName);
    if (!dir) {
      return null;
    }
    let res = new DirAndFileFSEntry(dir);
    if (entryName !== "") {
      for (let e of dir.fsentries) {
        if (e.name == entryName) {
          res.entry = e;
          return res;
        }
      }
    }
    return res;
  }

  /**
   * @param {FSEntry} fsentry
   */
  calcDirLastMod(fsentry) {
    // the api doesn't provide lastMod time for directories so we
    // calculate it as the lastMod of newest file in a directory
    let lastMod = 0;
    let entries = fsentry.fsentries;
    for (let e of entries) {
      if (e.isDir) {
        continue;
      }
      if (e.lastMod > lastMod) {
        lastMod = e.lastMod;
      }
    }
    if (lastMod == 0) {
      lastMod = Date.now();
    }
    fsentry.lastMod = lastMod;
  }

  /**
   * @param {FSEntry} fsentry
   */
  cacheDir(fsentry) {
    let dirPath = fsentry.name;
    throwIf(!fsentry.isDir, `${dirPath} is not a dir`);
    throwIf(!dirPath.startsWith("/"), `${dirPath} doesn't start with '/'`);
    console.log(`FS.cacheDir for dir: '${dirPath}'`);

    // this.calcDirLastMod(fsentry);

    this.filesCount += fsentry.filesCount();
    let old = this.dirs.get(dirPath);
    if (old) {
      this.filesCount -= old.filesCount();
    }
    this.dirs.set(dirPath, fsentry);
  }

  /**
   * @param {string} dirPath
   */
  removeCachedDir(dirPath) {
    console.log(`FS.removeCachedDir: ${dirPath}`);
    let e = this.dirs.get(dirPath);
    this.filesCount -= e.filesCount();
    this.dirs.delete(dirPath);
  }

  /**
   * @param {string} dirPath
   */
  removeCachedDirRecur(dirPath) {
    this.removeCachedDir(dirPath);
    // remove all sub-directories of dirPath
    let prefix = dirPath + "/";
    let dirsToRemove = [];
    for (let dir of this.dirs.keys()) {
      if (dir.startsWith(prefix)) {
        dirsToRemove.push(dir);
      }
    }
    for (let dir of dirsToRemove) {
      this.removeCachedDir(dir);
    }
  }

  /**
   * @param {string} path
   */
  findEntries(path) {
    let dn = filepath.split(path);
    let dir = dn.dir;
    if (dir == "") {
      dir = "/";
    }
    const de = this.dirs.get(dir);
    if (!de) {
      throw new Error(`Didn't find ${dir} parent dir for path ${path} `);
    }
    if (path == "/") {
      return [de];
    }

    let entries = [];
    for (let e of de.fsentries) {
      if (e.name == dn.name) {
        entries.push(e);
      }
    }
    if (len(entries) == 0) {
      throw new Error(`Didn't find entry ${dn.name} in dir ${dn.dir} `);
    }
    return entries;
  }

  /**
   * @param {string} path
   * @param {boolean} isDir
   */
  findFirstEntryOfType(path, isDir) {
    let entries = this.findEntries(path);
    for (let e of entries) {
      if (e.isDir == isDir) {
        return e;
      }
    }
    throw new Error(`Didn't find enry for dir ${path}`);
  }

  skipReadParentDirs = false;
  async readParentDirs(dirPath, force, progress) {
    if (this.skipReadParentDirs) {
      return;
    }
    this.skipReadParentDirs = true;
    let dirs = [];
    dirPath = filepath.dir(dirPath);
    while (dirPath != "/") {
      dirs.push(dirPath);
      dirPath = filepath.dir(dirPath);
    }
    dirs.push("/");

    let n = len(dirs);
    for (let i = 0; i < n; i++) {
      let dir = dirs[n - i - 1];
      await this.readDir(dir, false, progress);
    }
    this.skipReadParentDirs = false;
  }

  /**
   * read fs entries in a given directory
   * @param {string} dirPath - directory name
   * @param {boolean} force - if true, don't use cached values
   * @param {import("svelte/store").Writable} progress
   * @return {Promise<FSEntry[]>}
   */
  async readDir(dirPath, force, progress) {
    throw new Error(`readDir() not implemented for ${this.type}`);
  }

  /**
   * get publicly visible URL
   * @param {string} path
   * @returns {Promise<string>}
   */
  async getPublicURL(path) {
    throw new Error(`getPublicURL() not implemented for${this.type}`);
  }

  /**
   * Generic download function that works using getPublicURL
   * Some filesystems might implement optimized version
   * @param {string} path
   * @param {import("./http").HttpProgress} httpProgress
   * @returns {Promise<Blob>}
   */
  async downloadFile(path, httpProgress) {
    let uri = await this.getPublicURL(path);
    let blob = await xhrGetAsBlob(uri, httpProgress);
    return blob;
  }

  /**
   * @param {string} path
   * @param {Blob} file
   * @param {import("./http").HttpProgress} httpProgress
   */
  async uploadFile(path, file, httpProgress) {
    throw new Error(`uploadFile() not implemented for ${this.type}`);
  }

  /**
   * upload files to this filesystem
   * @param {FileInfo[]} files - files to upload
   * @param {import("svelte/store").Writable} progress
   */
  async uploadFiles(files, progress) {
    console.log("local.uploadFiles() files:", files);
    for (let fi of files) {
      let msg = `uploading ${fi.path}`;
      progress.set(msg);
      console.log("uploadFiles:", msg);

      function onProgress(size, totalSize, durMs) {
        console.log(
          "onProgress: size:",
          size,
          "totalSize:",
          totalSize,
          "durMs:",
          durMs
        );
        msg = `uploading ${fi.path} ${fmtSize(size)}`;
        if (totalSize > size) {
          msg += ` of ${fmtSize(totalSize)}`;
        }
        msg += ` in ${durMs} ms`;
        progress.set(msg);
      }
      progress.set(msg);

      await this.uploadFile(fi.dstPath, fi.file, onProgress);
    }
  }

  /**
   * rename a file
   * @param {string} path
   * @param {string} newPath
   */
  async rename(path, newPath) {
    throw new Error(`rename() not implemented for ${this.type}`);
  }

  /**
   * create a new directory
   * @param {string} dirPath - full path for the directory
   */
  async createDirectory(dirPath) {
    throw new Error(`createDirectory() not implemented for ${this.type}`);
  }

  /**
   * @param {string} path
   */
  async deleteFile(path) {
    throw new Error(`deleteFile() not implemented for ${this.type}`);
  }

  /**
   * delete files and directories in this filesystem
   * @param {string[]} paths
   * @param {import("svelte/store").Writable} progress
   */
  async deleteFiles(paths, progress) {
    for (let path of paths) {
      progress.set(`deleting ${path}`);
      await this.deleteFile(path);
    }
  }

  /**
   * pre-cache file listing by scanning directories
   * recursively
   * @param {string[]} toRead
   */
  async readDirRecur(toRead) {
    let n = 0;
    while (len(toRead) > 0) {
      let dirPath = toRead.shift();
      let msg = `caching dir ${dirPath}, ${this.dirs.size} dirs, ${this.filesCount} files`;
      let removeMsg = showMessage(msg);
      n++;
      console.log(
        `readDirRecur: ${dirPath}, dirs read: ${n}, dirs left: ${len(toRead)}`
      );
      try {
        let dir = this.dirs.get(dirPath);
        let entries = dir ? dir.fsentries : null;
        if (!entries) {
          entries = await this.readDir(dirPath, false, this.dummyProgress);
        }
        removeMsg();
        for (let e of entries) {
          if (!e.isDir) {
            continue;
          }
          let path = filepath.join(dirPath, e.name);
          toRead.push(path);
        }
      } catch (err) {
        console.error(`readDirRecur: `, err);
        removeMsg();
        return;
      }
    }
  }
}

/**
 * get paths for files in a directory, relative to directory name
 * recursively if needed
 * @param {FS} fs
 * @param {string} dir
 * @returns {Promise<string[]>}
 */
export async function getDirFilesRelativePath(fs, dir, recursive) {
  let toRead = [dir];
  let res = [];
  let dummyProgress = writable("");
  let relativeStarts = len(dir);

  while (len(toRead) > 0) {
    let dirPath = toRead.shift();
    let entries = await fs.readDir(dirPath, true, dummyProgress);
    for (let e of entries) {
      let path = filepath.join(dirPath, e.name);
      if (!e.isDir) {
        path = path.substring(relativeStarts);
        path = trimSuffix(path, "/");
        res.push(path);
      } else if (recursive) {
        toRead.push(path);
      }
    }
  }

  return res;
}

export class FSDirState {
  /** @type {FS|null} */
  fs = null;
  currDir = "";
  // if we're reading directory in the background,
  // this is the directory we're reading
  dirInProgress = "";
  /** @type {FSEntry[]} */
  entries = [];
  isValidDir = true;

  onReloadCurrentDir;

  triggerReloadCurrentDir() {
    if (this.onReloadCurrentDir) {
      this.onReloadCurrentDir();
    }
  }

  /**
   * @param {FS} fs
   * @param {string} dir
   */
  constructor(fs, dir) {
    this.fs = fs;
    this.currDir = dir;
  }
}
