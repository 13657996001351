import { nanoid, isDev } from "./util";

// unique user id
export const lsKeyULID = "fm-ulid";
// dropbox e-mail address
export const lsKeyDropboxEmail = "fm-dbx-email";
// google account info
export const lsKeyGoogleAccount = "fm-google-account";
// Microsoft One Drive account info
export const lsKeyOneDriveAccount = "fm-onedrive-account";

/**
 * get the best uniquely identifying user id
 * @returns {string}
 */
export function getUserID() {
  let uid = localStorage.getItem(lsKeyDropboxEmail);
  if (!uid) {
    uid = localStorage.getItem(lsKeyULID);
  }
  if (!uid) {
    let acc = localStorage.getItem(lsKeyGoogleAccount);
    if (acc) {
      let o = JSON.parse(acc);
      if (o && o.email) {
        uid = o.email;
      }
    }
  }
  if (!uid) {
    let acc = localStorage.getItem(lsKeyOneDriveAccount);
    if (acc) {
      let o = JSON.parse(acc);
      if (o && o.mail) {
        uid = o.mail;
      }
    }
  }

  // throwIf(!uid, "should have uid");
  return uid;
}

/**
 * @param {string} uid
 */
function initSentryAndPostHog(uid) {
  if (isDev()) {
    console.log("skipping sentry/posthog for localhost");
    return;
  }

  // window.Sentry might not exist if using ad blocker
  // @ts-ignore
  if (window.Sentry) {
    // @ts-ignore
    Sentry.init({
      dsn: "https://83f778ea4da74bf188e290c6cd9785f1@o1295897.ingest.sentry.io/6522010",
      // @ts-ignore
      integrations: [new Sentry.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }

  // @ts-ignore
  if (window.posthog) {
    // @ts-ignore
    posthog.identify(uid);
  }
}

// create user id on demand and initialize
// functionality that needs user id
export function createUserIDAndIdentify() {
  let uid = getUserID();
  if (!uid) {
    uid = nanoid();
    localStorage.setItem(lsKeyULID, uid);
    // console.log("generated unique user id:", uid);
  } else {
    // console.log("got user id:", uid);
  }

  initSentryAndPostHog(uid);
}
