import { throwIf, timeSince } from "./util";
import { lsKeyGoogleAccount } from "./user";
import {
  captureEvent,
  mkURL,
  telegramNotify,
  throwIfFetchFailed,
} from "./http";
import { corruptGoogleDrive, setCorruptGoogleDrive } from "./dev";
import { GoogleDriveConfig } from "./fs";

// https://zditect.com/code/javascript/migrate-to-google-identity-services-nbspnbsp-google-identity-services-javascript-sdk-nbspnbsp-google-developers.html

export let gdriveDiscoveryDocs =
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";

const l = window.location;
export let gdriveRedirectURL = l.protocol + "//" + l.host + "/auth/drivelogin";

export let gdriveClientID =
  "342130367825-linpqjemsutjbpmiebpfffhq0bchm5h4.apps.googleusercontent.com";

let scopes = [
  "https://www.googleapis.com/auth/drive",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
];

export let gdriveScopes = scopes.join(" ");
export let gdriveApiKey = "AIzaSyA1giq83197oaNDPiGTv_i563ZU2N406SU";

/*
async function connectGoogleDriveToken(onConnected) {
  console.log("connectGoogleDriveToken");

  const opts = {
    client_id: gdriveClientID,
    scope: gdriveScopes,
    callback: onGotToken,
  };

  // https://developers.google.com/identity/oauth2/web/reference/js-reference
  // @ts-ignore
  const client = google.accounts.oauth2.initTokenClient(opts);
  console.log(client);
  client.requestAccessToken();
}
*/

async function configFromToken(token) {
  console.log("onGotToken:", token);

  let accessToken = token.access_token;
  console.log("connectGoogleDriveToken: access_token:", accessToken);

  let url = mkURL(
    `https://www.googleapis.com/oauth2/v1/userinfo`,
    "alt",
    "json",
    "access_token",
    accessToken
  );
  let rsp = await fetch(url);
  throwIfFetchFailed(rsp);
  let ui = await rsp.json();
  console.log("user info:", ui);
  let email = ui.email;
  const msg = `New Google Drive connection for ${email}`;
  telegramNotify(msg);
  captureEvent("gdrive-login", { email: email });

  localStorage.setItem(lsKeyGoogleAccount, JSON.stringify(ui));

  let c = new GoogleDriveConfig();
  c.accessToken = accessToken;
  c.refreshToken = token.refresh_token;
  c.tokenExpires = token.expires;
  c.email = email;
  c.name = ui.name;
  return c;
}

/*
async function connectGoogleDriveRedirect(onConnected) {
  console.log("connectGoogleDrive");
  console.log("window.innerWidth", window.innerWidth);
  let opts = {
    client_id: gdriveClientID,
    scope: gdriveScopes,
    ux_mode: "redirect",
    redirect_uri: gdriveRedirectURL,
    state: "32432143143aazz-user-one",
  };

  // @ts-ignore
  const client = google.accounts.oauth2.initCodeClient(opts);
  console.log("calling client.requestCode");
  client.requestCode();
}
*/

/**
 * @returns {Promise<GoogleDriveConfig}
 */
export async function connectGoogleDrive() {
  // connectGoogleDriveToken(onConnected);
  return await connectGoogleDrivePopup();
  // connectGoogleDriveRedirect(onConnected);
}

/**
 * @returns {Promise<GoogleDriveConfig}
 */
async function connectGoogleDrivePopup() {
  let fn = async (resolve, reject) => {
    console.log("connectGoogleDrive");
    console.log("window.innerWidth", window.innerWidth);
    console.log("gdriveRedirectURL:", gdriveRedirectURL);
    let opts = {
      client_id: gdriveClientID,
      scope: gdriveScopes,
      ux_mode: "popup",
      callback: gdrivecb,
    };
    // https://developers.google.com/identity/oauth2/web/reference/js-reference
    // @ts-ignore
    const client = google.accounts.oauth2.initCodeClient(opts);
    console.log("client:", client);
    console.log("calling client.requestCode");
    client.requestCode();

    async function gdrivecb(response) {
      console.log("gdrivecb:", response);
      console.log("window.innerWidth", window.innerWidth);
      const code = response.code;
      console.log("connectGoogleDrivePopup: code:", code);
      let url = mkURL(`/api/gdrive/tokenfromcode`, "code", code);
      let rsp = await fetch(url);
      throwIfFetchFailed(rsp);
      let token = await rsp.json();
      let config = configFromToken(token);
      resolve(config);
    }
  };
  return new Promise(fn);
}

/**
 * @param {GoogleDriveConfig} c
 * @returns {Promise<string>}
 */
export async function getAccessTokenMaybeRefresh(c) {
  console.log("getAccessTokenMaybeRefresh:");
  let expiresTime = new Date(c.tokenExpires);
  let n = timeSince(expiresTime);
  if (!corruptGoogleDrive && n < 0) {
    console.log("getAccessTokenMaybeRefresh: token still valid, n:", n);
    return c.accessToken;
  }
  let tok = c.refreshToken;
  if (corruptGoogleDrive) {
    tok = tok.substring(1);
    setCorruptGoogleDrive(false);
    console.log("getAccessTokenMaybeRefresh: corrupting google drive token");
  }
  let url = mkURL(`/api/gdrive/refreshtoken`, "refresh_token", tok);
  let rsp = await fetch(url);
  if (rsp.status == 200) {
    let js = await rsp.json();
    console.log("getAccessTokenMaybeRefresh: js:", js);
    // TODO: should I remember this in local storage?
    // the tokens expire after 1 hr so it doesn't matter much
    // refresh token is not supposed to change
    throwIf(
      c.refreshToken != js.refresh_token,
      "Google Drive refresh token changed"
    );
    c.accessToken = js.access_token;
    c.tokenExpires = js.expires;
    return c.accessToken;
  }
  let config = await connectGoogleDrive();
  c.accessToken = config.accessToken;
  c.tokenExpires = config.tokenExpires;
  c.refreshToken = config.refreshToken;
  return c.accessToken;
}
