<script context="module">
  import { ensureRectVisibleInWindow } from "./actions/ensurevisible.js";
  import { half } from "./util";

  const tooltipDelay = 500;

  function validatePosition(s) {
    if (s === "bottom" || s === "top") {
      return s;
    }
    throw `Unknown position: ${s}`;
  }

  export function tooltip(node, args) {
    let timerId;
    let tooltipEl = document.getElementById("__internal_tooltip_id__");
    if (!tooltipEl) {
      throw "Tooltip not mounted";
    }

    let text = "";
    let position = "bottom";
    if (typeof args === "string") {
      text = args;
    } else {
      text = args.text;
      position = args.position || "bottom";
    }
    // console.log("tooltip: text=", text, "tooltipEl:", tooltipEl);

    // console.log(`text: '${text}' pos: '${position}'`);
    position = validatePosition(position);

    function updatePosition() {
      tooltipEl.textContent = text;
      let tr = tooltipEl.getBoundingClientRect();
      // console.log("setePosition: trect:", tr);
      const nr = node.getBoundingClientRect();
      let tx = nr.left + half(nr.width) - half(tr.width);
      tr.x = tx;
      if (position === "bottom") {
        tr.y = nr.bottom + 8;
        const { x, y } = ensureRectVisibleInWindow(tr);
        tooltipEl.style.left = `${x}px`;
        tooltipEl.style.top = `${y}px`;
        // console.log(`pos bottom: x=${x}, y=${y}`);
      } else {
        tr.y = top + nr.height - 8;
        const { x, y } = ensureRectVisibleInWindow(tr);
        tooltipEl.style.left = `${x}px`;
        tooltipEl.style.top = `${y}px`;
        // console.log(`pos top: x=${x}, y=${y}`);
      }
    }

    function showDelayed() {
      tooltipEl.style.opacity = "1";
    }

    function show() {
      // need a combination of block / opacity because with display: none,
      // we don't get the bounding rect
      tooltipEl.style.display = "block";
      tooltipEl.style.opacity = "0";
      timerId = setTimeout(showDelayed, tooltipDelay);
      updatePosition();
    }

    function hide() {
      // cancel a (potential) scheduled showing of tooltip
      clearTimeout(timerId);
      tooltipEl.style.display = "none";
    }

    // this is useful when the element with tooltip is also a click trigger
    // for drop-down menu
    // TODO: maybe make this an option
    node.addEventListener("click", hide);

    node.addEventListener("mouseenter", show);
    node.addEventListener("mouseleave", hide);

    return {
      update(text) {
        // console.log(`tooltip update: ${text}`);
        tooltipEl = document.getElementById("__internal_tooltip_id__");
        updatePosition();
      },

      destroy() {
        console.log("");
        hide();
        node.removeEventListener("mouseenter", show);
        node.removeEventListener("mouseleave", hide);
      },
    };
  }
</script>

<script>
  let tooltipEl = document.getElementById("__internal_tooltip_id__");
  if (tooltipEl) {
    throw "tooltip mounted multiple times";
  }
</script>

<div
  id="__internal_tooltip_id__"
  class="absolute hidden bg-white text-gray-700 py-1 px-3 z-50 text-sm border-2 border-gray-300 pointer-events-none w-fit"
/>
