import { dateStringToMs, len, startTimer, throwIf } from "./util";
import * as filepath from "./filepath";
import {
  xhrAsync,
  xhrDeleteAuth,
  xhrGetJSONAuth,
  XhrOpts,
  xhrPut,
} from "./http";
import { FSEntry, newDirFSEntry } from "./fsentry";
import { OneDriveConfig, FS, fsTypeOneDrive, fsCopyProps } from "./fs";
import {
  onedriveMaybeUpdateAccessToken,
  msGraphEndpointV1,
} from "./fs-onedrive-login";

let disablePreCaching = true;

// One Drive props, files and dirs:
const prop_od_createDateTime = "createDateTime";
const prop_od_cTag = "cTag";
const prop_od_eTag = "eTag";
export const prop_od_webUrl = "webUrl";
export const prop_od_parentReference_id = "|parentReference.id";
export const prop_od_parentReference_driveId = "|parentReference.driveId";

// files only
export const prop_od_dlURL = "@microsoft.graph.downloadUrl";
export const prop_od_file_mimeType = "|file.mimeType";
export const prop_od_file_hashes_sha1Hash = "|file.hashes.sha1Hash";
export const prop_od_file_hashes_sha256Hash = "|file.hashes.sha256Hash";

const fileDirsProps = [
  prop_od_createDateTime,
  prop_od_cTag,
  prop_od_eTag,
  prop_od_webUrl,
  prop_od_parentReference_id,
  prop_od_parentReference_driveId,
];

const fileOnlyProps = [
  prop_od_dlURL,
  prop_od_file_mimeType,
  prop_od_file_hashes_sha1Hash,
  prop_od_file_hashes_sha256Hash,
];

/* TODO:
folder.view.viewType: "thumbnail"
folder.view.sortBy: "name"
folder.view.sortOrder: "ascending", "descending"

files:
file.hashes.quickXorHash
*/

class FileSystemOneDrive extends FS {
  /** @type {OneDriveConfig} */
  constructor(config) {
    super(fsTypeOneDrive);
    this.oneDriveConfig = config;
  }

  async updateAccessToken() {
    let c = this.oneDriveConfig;
    let didUpdate = await onedriveMaybeUpdateAccessToken(c);
    if (!didUpdate) {
      return c.accessToken;
    }
    console.log("updateAccessToken: found new token:", c.accessToken);
    // TODO: update bookmarks
    return c.accessToken;
  }

  /**
   * read fs entries in a given directory
   * @param {string} dirPath - directory name
   * @param {boolean} force - if true, don't use cached values
   * @param {import("svelte/store").Writable} progress
   * @return {Promise<FSEntry[]>}
   */
  async readDir(dirPath, force, progress) {
    console.log("FileSystemOneDrive.readDir:", dirPath);
    progress.set(
      `reading dir ${dirPath}, ${this.dirs.size} dirs, ${this.filesCount} files`
    );
    if (!force) {
      const de = this.dirs.get(dirPath);
      if (de) {
        console.log(`FileSystemOneDrive.get: got from cache for ${dirPath}`);
        return de.fsentries;
      }
    }
    if (dirPath != "/") {
      await this.readParentDirs(dirPath, force, progress);
    }

    let parentId = "root";
    if (dirPath != "/") {
      let de = this.findFirstEntryOfType(dirPath, true);
      parentId = de.id;
    }
    const dur = startTimer();
    await this.updateAccessToken();
    let t = this.oneDriveConfig.accessToken;
    let entries = await oneDriveGetEntriesInDir(t, dirPath, parentId);
    console.log(
      `FileSystemOneDrive.readDir, gdriveGetEntriesInDir: got ${len(
        entries
      )} entries for ${dirPath} in ${dur()} ms`
    );

    let dir = newDirFSEntry(dirPath, entries);
    this.cacheDir(dir);

    if (!disablePreCaching && dirPath == "/" && this.dirs.size == 1) {
      this.readDirRecur(["/"]);
    }
    return dir.fsentries;
  }

  getParentDirectoryId(path) {
    let dn = filepath.split(path);
    if (dn.dir == "/") {
      return "root";
    }
    let e = this.findFirstEntryOfType(dn.dir, true);
    return e.id;
  }

  /**
   * @param {string} path
   * @param {Blob} file
   * @param {import("./http").HttpProgress} httpProgress
   */
  async uploadFile(path, file, httpProgress) {
    console.log(`FileSystemOneDrive.uploadFile: ${path}`);
    let parentId = this.getParentDirectoryId(path);
    let dn = filepath.split(path);
    await this.updateAccessToken();
    let accessToken = this.oneDriveConfig.accessToken;
    await onedriveMaybeUpdateAccessToken(accessToken);

    // https://docs.microsoft.com/en-us/graph/api/driveitem-put-content?view=graph-rest-1.0&tabs=http
    let uploadURL = `${msGraphEndpointV1}/me/drive/items/${parentId}:/${dn.name}:/content`;
    await xhrPut(uploadURL, file, httpProgress, accessToken);
  }

  /**
   * get publicly visible URL
   * @param {string} path
   * @returns {Promise<string>}
   */
  async getPublicURL(path) {
    console.log(`FileSystemOneDrive.getPublicURL: path=${path}`);
    path = filepath.stripDirSlash(path);
    let all = this.findEntries(path);
    let e = all[0];
    let url = e.findProp(prop_od_dlURL);
    console.log("getPublicURL:", url);
    return url;
  }

  /**
   * @param {string} dirPath - full path for the directory
   */
  async createDirectory(dirPath) {
    console.log(`FileSystemOneDrive.createDirectory: ${dirPath}`);
    let parentId = this.getParentDirectoryId(dirPath);
    let dn = filepath.split(dirPath);
    let dirName = dn.name;
    await this.updateAccessToken();
    let accessToken = this.oneDriveConfig.accessToken;

    let createURL = `${msGraphEndpointV1}/me/drive/items/${parentId}/children`;
    let file = {
      name: dirName,
      folder: {},
      "@microsoft.graph.conflictBehavior": "rename",
    };
    let opts = new XhrOpts();
    opts.method = "POST";
    opts.headers["Authorization"] = `Bearer ${accessToken}`;
    opts.headers["Content-Type"] = `application/json`;
    opts.responseType = "json";
    opts.data = JSON.stringify(file);

    let res = await xhrAsync(createURL, opts);
    console.log("res:", res);
  }

  /**
   * rename a file or directory
   * @param {string} path
   * @param {string} newPath
   */
  async rename(path, newPath) {
    console.log(`FileSystemOneDrive.rename: ${path} ${newPath}`);

    path = filepath.stripDirSlash(path);
    let all = this.findEntries(path);
    let e = all[0];
    console.log(`rename: ${path}, isDir: ${e.isDir}`);

    await this.updateAccessToken();

    // https://docs.microsoft.com/en-us/graph/api/driveitem-update?view=graph-rest-1.0&tabs=http
    let updateURL = `${msGraphEndpointV1}/me/drive/items/${e.id}`;
    let accessToken = this.oneDriveConfig.accessToken;
    let opts = new XhrOpts();
    opts.method = "PATCH";
    opts.responseType = "json";
    opts.headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": `application/json`,
    };
    let newName = filepath.base(newPath);
    let file = {
      name: newName,
    };
    opts.data = JSON.stringify(file);
    await xhrAsync(updateURL, opts);
  }

  /**
   * @param {string} path
   */
  async deleteFile(path) {
    console.log(`FileSystemOneDrive.deleteFile: ${path}`);
    path = filepath.stripDirSlash(path);
    let all = this.findEntries(path);
    let e = all[0];
    console.log(`deleteFile: ${path}, isDir: ${e.isDir}`);
    await this.updateAccessToken();
    // https://docs.microsoft.com/en-us/graph/api/driveitem-delete?view=graph-rest-1.0&tabs=http
    let accessToken = this.oneDriveConfig.accessToken;
    let deleteURL = `${msGraphEndpointV1}/me/drive/items/${e.id}`;
    await xhrDeleteAuth(deleteURL, accessToken);
  }
}

/**
 * @param {string} accessToken
 * @param {string} dir
 * @param {string} dirId
 * @returns {Promise<FSEntry[]>}
 */
async function oneDriveGetEntriesInDir(accessToken, dir, dirId) {
  console.log(`oneDriveGetEntriesInDir: dirId: ${dirId}`);
  let js;
  let url = `${msGraphEndpointV1}/me/drive/items/${dirId}/children`;
  /** @type {Object} */
  let entries = [];
  while (url) {
    console.log(`getting ${url}, nEntries so far: ${len(entries)}`);
    js = await xhrGetJSONAuth(url, accessToken, null);
    console.log("js:", js);
    entries.push(...js.value);
    url = js["@odata.nextLink"];
  }
  /** @type {FSEntry[]} */
  let fsentries = [];
  let fe;
  for (let e of entries) {
    let isFile = e.hasOwnProperty("file");
    let isFolder = e.hasOwnProperty("folder");
    throwIf(
      !(isFile || isFolder),
      `${JSON.stringify(e)} should be either a file or a folder`
    );

    let size = isFile ? e.size : -1;
    let lastMod = dateStringToMs(e.lastModifiedDateTime);
    let entries = isFile ? null : [];
    let meta = [e.id, e.name, size, lastMod, entries];
    fsCopyProps(fileDirsProps, e, meta);
    if (isFile) {
      fsCopyProps(fileOnlyProps, e, meta);
    }
    let fe = FSEntry.fromMeta(meta);

    fsentries.push(fe);
  }
  return fsentries;
}

/**
 * @param {OneDriveConfig} c
 */
export function makeFileSystemOneDrive(c) {
  console.log("makeFileSystemOneDrive, config:", c);
  let fs = new FileSystemOneDrive(c);
  return fs;
}
