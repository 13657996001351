/**
 *
 * @param {any[]} a
 * @param {any} el
 */
export function remove(a, el) {
  const idx = a.indexOf(el);
  if (idx >= 0) {
    // console.log("array.remove: removed at idx", idx, el);
    a.splice(idx, 1);
    return;
  }
  // console.log("array.remove: didn't remove", el, a);
}

/**
 *
 * @param {any[]} a
 * @param {any} el
 */
export function pushUnique(a, el) {
  if (a.includes(el)) {
    return;
  }
  a.push(el);
}
