<script>
  import { createEventDispatcher } from "svelte";

  import { tooltip } from "./Tooltip.svelte";
  import Bookmarks from "./Bookmarks.svelte";

  import ArrowLeft from "./icons/ArrowLeft.svelte";
  import ArrowRight from "./icons/ArrowRight.svelte";
  import ArrowUp from "./icons/ArrowUp.svelte";
  import Star from "./icons/Star.svelte";

  export let path = "";
  /** @type {import("./bookmarks").Bookmark} */
  export let currBookmark;

  let showBookmarks = false;
  const dispatch = createEventDispatcher();
</script>

<div class="grow-0 flex items-center px-1 pl-2 py-1 bg-gray-300">
  <!-- left (back) -->
  <div use:tooltip={"history back (not yet implemented)"}>
    <ArrowLeft
      class="text-gray-700 hover:bg-gray-200 cursor-pointer"
      size={16}
    />
  </div>

  <!-- right (forward) -->
  <div class="ml-2" use:tooltip={"history nav (not yet implemented)"}>
    <ArrowRight
      class="text-gray-700 hover:bg-gray-200 cursor-pointer"
      size={16}
    />
  </div>

  <!-- bookmarks -->
  <div
    class="ml-2 relative"
    on:click={() => {
      console.log("showBookmarks");
      showBookmarks = true;
    }}
  >
    <div use:tooltip={"Bookmarks"}>
      <Star size={16} class="text-gray-700 hover:bg-gray-200 cursor-pointer" />
    </div>
    {#if showBookmarks}
      <Bookmarks
        {currBookmark}
        on:dismiss={() => (showBookmarks = false)}
        on:gotobookmark
        on:addtobookmarks
      />
    {/if}
  </div>

  <!-- up  -->
  <div on:click={() => dispatch("fmdirup")} use:tooltip={"go up"} class="ml-2">
    <ArrowUp class="text-gray-700 hover:bg-gray-200 cursor-pointer" size={16} />
  </div>

  <div class="ml-2 flex-grow px-2 py-1 bg-gray-50 text-xs truncate">{path}</div>
</div>
