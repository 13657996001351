// re-use the slot for id and dirHandle. dirHandle is

import { isString, len, patchPrototype, throwIf } from "./util";

// reusing slot 0 for idxDirHandle (only in local fs) or id (everyone else)
const idxId = 0;
const idxDirHandle = 0;
const idxName = 1;
const idxSize = 2;
const idxLastMod = 3;
const idxEntries = 4;
const idxFirstProp = 5;

/*
for efficiency we store data in array which has at least
5 values:
id - a unique id. Used by fsTypeGoogleDrive, fsTypeOneDrive
     name if not set
name - for directory it's full directory path. for files, it's name
size - size of the file, -1 for directories
entries - array of entries, only for directories
lastMod - last modification time, 0 if not available
after that we have key / value props specific for each fs
*/
export class FSEntry extends Array {
  /**
   * @param {any[]} meta
   * @returns {FSEntry}
   */
  static fromMeta(meta) {
    throwIf(!Array.isArray(meta), "meta not an array");
    const n = len(meta);
    throwIf(n < idxFirstProp, "invalid meta");
    if (meta[idxSize] == -1) {
      let a = meta[idxEntries];
      if (!Array.isArray(a)) {
        throwIf(a != null, "meta[idxEntries] must be null or []");
        meta[idxEntries] = [];
      }
    }

    // convert lastMod from string to number
    let lastMod = meta[idxLastMod];
    // TODO: fix s3 backend to send the equivalent directly
    if (isString(lastMod)) {
      let timeMs = 0;
      if (lastMod != "") {
        timeMs = new Date(lastMod).getTime();
      }
      meta[idxLastMod] = timeMs;
    }
    let res = patchPrototype(meta, FSEntry.prototype);
    // let res = new FSEntry(n);
    // for (let i = 0; i < n; i++) {
    //   res[i] = meta[i];
    // }
    throwIf(res.size >= 0 && res.isDir, "dir must have size < 0");
    throwIf(res.isDir && res.size != -1, "dir must have size < 0");
    return res;
  }

  /**
   * @returns {string}
   */
  get id() {
    let v = this[idxId];
    // could be dirHandle
    if (!isString(v)) {
      v = this[idxName];
    }
    return v;
  }

  /**
   * @param {string} val
   */
  set id(val) {
    this[idxId] = val;
  }

  /**
   * @returns {string}
   */
  get name() {
    return this[idxName];
  }

  /**
   * @returns {number}
   */
  get size() {
    return this[idxSize];
  }

  get lastMod() {
    return this[idxLastMod];
  }

  set lastMod(v) {
    this[idxLastMod] = v;
  }

  /**
   * @returns {FSEntry[]}
   */
  get fsentries() {
    return this[idxEntries];
  }

  /**
   * @param {FSEntry[]} v
   */
  set fsentries(v) {
    this[idxEntries] = v;
  }

  /**
   * @returns {FileSystemDirectoryHandle}
   */
  get dirHandle() {
    // only for fsLocal
    // @ts-ignore
    return this[idxDirHandle];
  }

  /**
   * @param {FileSystemDirectoryHandle} v
   */
  set dirHandle(v) {
    // @ts-ignore
    this[idxDirHandle] = v;
  }

  /**
   * @returns {boolean}
   */
  get isDir() {
    let a = this[idxEntries];
    return Array.isArray(a);
  }

  /* re-using idxSize because it only applies for directories */
  /**
   * @returns {number}
   */
  get cachedTotalSize() {
    return this[idxSize];
  }

  /**
   * @param {number} v
   */
  set cachedTotalSize(v) {
    this[idxSize] = v;
  }

  /**
   * adds arbitrary property stored as 2 array elements: key and value
   * @param {string} name
   * @param {any} val
   */
  addProp(name, val) {
    this.push(name, val);
  }

  /**
   * Find a prop by name
   * @param {string} name
   * @returns {any}
   */
  findProp(name) {
    let n = len(this);
    // we look for name followed by a value
    for (let i = idxFirstProp; i < n; i++) {
      let entryName = this[i];
      if (entryName === name) {
        throwIf(i + 1 >= n, `key ${name} without value`);
        return this[i + 1];
      }
    }
    return null;
  }

  /**
   * Get all properties as an array with 2 elements for each prop:
   * name and value
   * @returns {any[]}
   */
  getAllProps() {
    return this.slice(idxFirstProp);
  }

  /**
   * @returns {number}
   */
  filesCount() {
    throwIf(!this.isDir, "only valid for dirs");
    let n = 0;
    for (let e of this.fsentries) {
      if (!e.isDir) {
        n++;
      }
    }
    return n;
  }

  /**
   * @returns {number}
   */
  totalSize() {
    throwIf(!this.isDir, "only valid for dirs");
    if (this.cachedTotalSize >= 0) {
      return this.cachedTotalSize;
    }
    let n = 0;
    for (let e of this.fsentries) {
      if (!e.isDir) {
        n += e.size;
      }
    }
    this.cachedTotalSize = n;
    return this.cachedTotalSize;
  }

  /**
   * @param {string} name
   */
  removeEntryByName(name) {
    throwIf(!this.isDir, "only applies to directory entries");
    let n = len(this.fsentries);
    for (let i = 0; i < n; i++) {
      let e = this.fsentries[i];
      if (e.name == name) {
        this.fsentries.splice(i, 1);
        return;
      }
    }
    throw `Didn't find entry ${name} in ${this.name}`;
  }
}

/**
 * @param {string} dirName
 * @param {FSEntry[]} entries
 * @returns {FSEntry}
 */
export function newDirFSEntry(dirName, entries = []) {
  let meta = [null, dirName, -1, 0, entries];
  return FSEntry.fromMeta(meta);
}

/**
 * @param {string} dirName
 * @param {any[][]} metaEntries
 * @returns {FSEntry}
 */
export function newDirFSEntryWithMeta(dirName, metaEntries) {
  let res = newDirFSEntry(dirName);
  for (let meta of metaEntries) {
    let e = FSEntry.fromMeta(meta);
    res.fsentries.push(e);
  }
  return res;
}
