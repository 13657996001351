<script>
  import { getContext } from "svelte";
  import DialogBase from "./DialogBase.svelte";
  import { focus } from "./actions/focus";
  import { len, inflect } from "./util";

  export let open = false;
  export let paths = [];

  let fnDeleteFiles = getContext("fnDownloadFiles");

  let limitedPaths = [];
  let nFiles = 0;
  let nDirs = 0;

  let max = 8;

  let n = len(paths);
  for (let i = 0; i < n; i++) {
    let path = paths[i];
    if (i < max) {
      limitedPaths.push(path);
    }
    if (path.endsWith("/")) {
      nDirs++;
    } else {
      nFiles++;
    }
  }

  let nNotShown = n - max;
  if (nNotShown > 0) {
    let s = `... and ${nNotShown} more`;
    limitedPaths.push(s);
  }

  function msg() {
    let s = "Download ";
    if (nFiles > 0) {
      s += `<b>${nFiles}</b> ` + inflect("file", nFiles);
      if (nDirs > 0) {
        s += " and";
      }
    }
    if (nDirs > 0) {
      s += ` <b>${nDirs}</b> ` + inflect("dir", nDirs);
    }
    s += "?";
    return s;
  }

  let dirInflected = "directories";
  if (nDirs == 1) {
    dirInflected = "directory";
  }

  function close() {
    open = false;
  }
</script>

<DialogBase bind:open title="Download">
  <div slot="main" class="flex flex-col">
    <div>{@html msg()}</div>
    <div class="mt-4">Will download:</div>
    {#each limitedPaths as path}
      <div class="font-mono ml-4 text-sm truncate maxw">{path}</div>
    {/each}
    {#if nDirs > 0}
      <div class="font text-red-500 text-sm mt-2">
        Warning: downloading <span class="font-bold">{nDirs}</span>
        {dirInflected}. Could download lots of files!
      </div>
    {/if}
  </div>

  <div slot="bottom" class="flex justify-end">
    <button
      use:focus
      class="px-4 py-2 hover:bg-gray-200 border border-gray-400 focus:border-black outline-none"
      on:click={close}>Cancel</button
    >
    <button
      class="ml-8 px-4 py-2 hover:bg-gray-200 text-gray-800 font-semibold border border-gray-400 outline-none focus:border-black"
      on:click={fnDeleteFiles(paths)}>Download</button
    >
  </div>
</DialogBase>
