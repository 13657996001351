<script>
  import { fetchStore } from "./http";
  import { fmtSize } from "./util";

  export let url;
  export let fileName = "";

  let msg = `Downloading: ${fileName}`;

  function onProgress(size, totalSize, durMs) {
    const sizeStr = fmtSize(size);
    if (totalSize == 0) {
      msg = `Download: ${fileName} ${sizeStr} int ${durMs} ms`;
    } else {
      const totalStr = fmtSize(totalSize);
      msg = `Download: ${fileName} ${sizeStr} of ${totalStr}, in ${durMs} ms`;
    }
  }

  const [data, loading, error] = fetchStore(url, onProgress);
</script>

<div
  class="wrapper bg-white overflow-auto mx-auto self-center flex flex-col px-2 py-2 flex flex-col focus:outline-none"
>
  {#if $loading}
    <div class="self-center my-auto">
      {msg}
    </div>
  {:else if $error}
    <div class="self-center my-auto text-red-500">
      Error: {$error}
    </div>
  {:else}
    <pre>{$data}</pre>
  {/if}
</div>

<style>
  .wrapper {
    min-width: 50%;
    max-width: 80%;
    min-height: 50%;
    max-height: 80%;
  }
</style>
