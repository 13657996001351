<script>
  import { createEventDispatcher, getContext } from "svelte";
  import DialogBase from "./DialogBase.svelte";

  import { focus } from "./actions/focus";
  import { showMessage } from "./message";
  import { telegramNotify, throwIfFetchFailed } from "./http";
  import { S3Config } from "./fs";
  import { testS3Connection, makeFileSystemS3 } from "./fs-s3";

  export let open = false;

  const dispatch = createEventDispatcher();

  let endpointPlaceholder = "s3.amazonaws.com";

  let config = new S3Config();
  let canConnect = false;
  let testingConnection = false;
  let errorMessage = "";

  $: canConnect = calcCanConnect(config.secret, config.access, config.bucket);

  function calcCanConnect(secret, access, bucket) {
    return secret && access && bucket; // name is optional
  }

  async function onConnect() {
    console.log("onConnect()");
    if (config.endpoint == "") {
      config.endpoint = endpointPlaceholder;
    }
    testingConnection = true;
    errorMessage = "";
    canConnect = false;
    let removeMsg = showMessage("testing s3 connection");
    try {
      let rsp = await testS3Connection(config);
      console.log("rsp", rsp);
      await throwIfFetchFailed(rsp);
      removeMsg();
      let fs = makeFileSystemS3(config);
      const msg = `New S3 connection ${config.bucket}.${config.endpoint}`;
      telegramNotify(msg);

      dispatch("mountfs", fs);
    } catch (e) {
      console.error(e);
      errorMessage = e.toString();
      removeMsg();
    }
    testingConnection = false;
    canConnect = true;
  }

  function close() {
    open = false;
  }
</script>

<DialogBase bind:open title="Connect S3">
  <div slot="main" class="flex flex-col">
    <label for="input-name"
      >Name: <span class="text-gray-400">(optional)</span></label
    >
    <input
      use:focus
      type="text"
      id="input-name"
      autocomplete="off"
      class="outline-none border px-2 py-1 text-sm"
      bind:value={config.name}
    />

    <label class="mt-3" for="input-key">S3 Access Key:</label>
    <input
      type="text"
      id="input-key"
      autocomplete="off"
      class="outline-none border px-2 py-1 text-sm"
      bind:value={config.access}
    />

    <label class="mt-3" for="input-secret">S3 Secret:</label>
    <input
      type="text"
      id="input-secret"
      autocomplete="off"
      class="outline-none border px-2 py-1 text-sm"
      bind:value={config.secret}
    />

    <label class="mt-3" for="input-bucket">S3 Bucket:</label>
    <input
      type="text"
      id="input-bucket"
      autocomplete="off"
      class="outline-none border px-2 py-1 text-sm"
      bind:value={config.bucket}
    />

    <label class="mt-3" for="input-endpoint">Endpoint:</label>
    <input
      type="text"
      id="input-endpoint"
      autocomplete="off"
      placeholder={endpointPlaceholder}
      class="outline-none border px-2 py-1 text-sm"
      bind:value={config.endpoint}
    />

    <div class="mt-4 text-sm">
      <a
        href="https://doc.clickup.com/36014502/d/12b2d6-107/filerionmanual/12b2d6-387/s-3-support"
        class="text-blue-600 underline"
        target="_blank"
        rel="noreferrer">configuration help</a
      >
    </div>

    {#if testingConnection}
      <div class="text-sm text-gray-500 mt-2 mb-2">testing s3 settings...</div>
    {/if}

    {#if errorMessage}
      <div class="mt-2 mb-2 text-red-500 font-medium break-all w-72">
        {errorMessage}
      </div>
    {/if}
  </div>

  <div slot="bottom" class="flex flex-row justify-between">
    <button
      on:click={close}
      class="py-2 px-4 border border-gray-400 hover:bg-gray-200">Cancel</button
    >
    <button
      disabled={!canConnect}
      on:click={onConnect}
      class="py-2 px-4 border border-gray-400 enabled:hover:bg-gray-200 disabled:opacity-50"
      >Connect S3</button
    >
  </div>
</DialogBase>
