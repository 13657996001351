<script>
  import FileList from "./FileList.svelte";
  import PickFileSystem from "./PickFileSystem.svelte";
  import TopNavBar from "./TopNavBar.svelte";
  import Tooltip from "./Tooltip.svelte";
  import Close from "./icons/Close.svelte";

  import { viewportWidth, canCopyMove } from "./store";
  import { currentMessage } from "./message";
  import { captureEvent } from "./http";
  import {
    addBookmarkFSState,
    bookmarkEqFS,
    fsEq,
    makeFSFromBookmark,
  } from "./bookmarks";
  import { FileInfo, FSDirState } from "./fs";
  import { throwIf } from "./util";

  /** @type {import("./fs").FSDirState | null} */
  let fs1State = null;
  let fs1Component;
  /** @type {import("./fs").FSDirState | null} */
  let fs2State = null;
  let fs2Component;

  {
    let files = [];
    {
      let fi = new FileInfo();
      fi.name = "test.txt";
      fi.size = 2341234;
      files.push(fi);
    }
    {
      let fi = new FileInfo();
      fi.name = "long file name.pdf";
      fi.size = 23234;
      files.push(fi);
    }
    {
      let fi = new FileInfo();
      fi.name = "another.file.zip";
      fi.size = 88234;
      files.push(fi);
    }
    // entriesToCopy = files;
  }

  function showTopLevel(num) {
    console.log("showTopLevel", num);
    if (num == 1) {
      fs1State = null;
    } else if (num == 2) {
      fs2State = null;
    }
  }

  $: $canCopyMove = calcCanCopyMove(fs1State, fs2State);

  function calcCanCopyMove(fs1State, fs2State) {
    if (!fs1State || !fs2State) {
      return false;
    }
    if (!fs1State.isValidDir || !fs2State.isValidDir) {
      return false;
    }
    // disallow same directories
    if (fsEq(fs1State.fs, fs2State.fs)) {
      if (fs1State.currDir == fs2State.currDir) {
        return false;
      }
    }
    return true;
  }

  async function goToBookmark(ev, num) {
    /** @type {import("./bookmarks").Bookmark} */
    const b = ev.detail;
    console.log("goToBookmark:", ev, num, b);

    let newFS = makeFSFromBookmark(b);
    let newFSState = new FSDirState(newFS, b.dir);

    // if not mounted, set the directory with fs1Dir/fs2Dir
    // if mounted, set the directory with tryReadDir()
    if (num == 1) {
      let same = fs1State && bookmarkEqFS(b, fs1State.fs);
      if (!same) {
        fs1State = newFSState;
      } else {
        fs1Component.tryReadDir(b.dir);
      }
    } else if (num == 2) {
      let same = fs2State && bookmarkEqFS(b, fs2State.fs);
      if (!same) {
        fs2State = newFSState;
      } else {
        fs2Component.tryReadDir(b.dir);
      }
    } else {
      throwIf(true, `num is $[num}, should be 1 or 2`);
    }
    // let sameDir = sameFS && dir == currFs.dir;
    captureEvent("gotobookmark", { type: b.type });
  }

  async function mountfs(ev, num) {
    console.log("mountfs:", ev, num);
    /** @type {import("./fs").FS} */
    const fs = ev.detail;

    let fsState = new FSDirState(fs, "/");
    //addBookmarkFSState(fsState);

    if (num == 1) {
      fs1State = fsState;
    } else if (num == 2) {
      fs2State = fsState;
    } else {
      throw "invalid num";
    }

    captureEvent("mountfs", { type: fs.type });
  }

  const lsKeyInfoVisible = "fm-info-visible";
  let infoVisible = !localStorage.getItem(lsKeyInfoVisible);
  function hideInfo() {
    infoVisible = false;
    localStorage.setItem(lsKeyInfoVisible, "no");
  }
  // @ts-ignore
  window.showInfo = function () {
    localStorage.removeItem(lsKeyInfoVisible);
    infoVisible = true;
  };

  let errorMsg = "";
  function showError(ev) {
    let err = ev.detail;
    errorMsg = err.toString();
  }
  function hideErrorMessage() {
    errorMsg = "";
  }

  /**
   * @param {import("./fs").FSDirState} fsState
   * @return {import("./fs").FSDirState}
   */
  function pickOtherFSState(fsState) {
    if (fsState == fs1State) {
      return fs2State;
    }
    if (fsState == fs2State) {
      return fs1State;
    }
    throw new Error("pickOtherFSState: unrecognized fsState");
  }
</script>

<main
  class="relative bg-yellow"
  on:contextmenu|preventDefault={() => {
    /* do nothing */
  }}
>
  <TopNavBar />

  <!-- big screen: show 2 panels; small screen: 1 panel -->
  {#if $viewportWidth > 768}
    <div class="grid min-h-0 grid-cols-2">
      {#key fs1State}
        {#if fs1State}
          <FileList
            {pickOtherFSState}
            fsState={fs1State}
            bind:this={fs1Component}
            on:gotobookmark={(ev) => goToBookmark(ev, 1)}
            on:showtoplevel={(ev) => showTopLevel(1)}
          />
        {:else}
          <PickFileSystem
            on:fmerror={showError}
            on:gotobookmark={(ev) => goToBookmark(ev, 1)}
            on:mountfs={(ev) => mountfs(ev, 1)}
          />
        {/if}
      {/key}
      {#key fs2State}
        {#if fs2State}
          <FileList
            {pickOtherFSState}
            fsState={fs2State}
            bind:this={fs2Component}
            on:gotobookmark={(ev) => goToBookmark(ev, 2)}
            on:showtoplevel={(ev) => showTopLevel(2)}
          />
        {:else}
          <PickFileSystem
            on:fmerror={showError}
            on:gotobookmark={(ev) => goToBookmark(ev, 2)}
            on:mountfs={(ev) => mountfs(ev, 2)}
          />
        {/if}
      {/key}
    </div>
  {:else}
    <div class="grid min-h-0 grid-cols-1">
      {#if fs1State}
        {#key fs1State}
          <FileList
            {pickOtherFSState}
            fsState={fs1State}
            bind:this={fs1Component}
            on:gotobookmark={(ev) => goToBookmark(ev, 1)}
            on:showtoplevel={(ev) => showTopLevel(1)}
          />
        {/key}
      {:else if fs2State}
        {#key fs2State}
          <FileList
            {pickOtherFSState}
            fsState={fs2State}
            bind:this={fs2Component}
            on:gotobookmark={(ev) => goToBookmark(ev, 2)}
            on:showtoplevel={(ev) => showTopLevel(2)}
          />
        {/key}
      {:else}
        <PickFileSystem
          on:fmerror={showError}
          on:gotobookmark={(ev) => goToBookmark(ev, 1)}
          on:mountfs={(ev) => mountfs(ev, 1)}
        />
      {/if}
    </div>
  {/if}

  {#if errorMsg}
    <div
      class="absolute bottom-0 left-0 ml-2 mt-1 flex flex-row justify-between items-center px-3 py-1 mb-2 text-red-500 font-semibold bg-red-100 border border-red-300 rounded-lg"
    >
      <div class="break-all">{errorMsg}</div>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="self-center mt-1 ml-2 hover:bg-red-200 cursor-pointer"
        on:click={hideErrorMessage}
      >
        <Close size={16} />
      </div>
    </div>
  {/if}

  {#if $currentMessage}
    <div class="absolute bottom-0 right-0 px-2 py-0 bg-yellow-200 z-10">
      {$currentMessage}
    </div>
  {/if}

  {#if !fs1State && !fs2State && infoVisible}
    <div
      class="flex flex-row info absolute border-yellow-400 bottom-2 border px-4 py-2 bg-yellow-100"
    >
      <div>
        Filerion is a file manager for online storage (s3, Dropbox and more). <a
          href="https://blog.kowalczyk.info/article/690e6dc957aa421082cbe7b42936e09c/filerion-documentation.html"
          target="_blank"
          rel="noreferrer"
          class="text-blue-700  hover:underline-offset-2 hover:underline"
          >Learn more</a
        >.
      </div>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class=" self-center mt-1 ml-2 hover:bg-yellow-300 cursor-pointer"
        on:click={hideInfo}
      >
        <Close size={20} />
      </div>
    </div>
  {/if}
</main>

<Tooltip />

<style>
  main {
    padding: 0;
    margin: 0;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }

  .info {
    left: 50%;
    transform: translateX(-50%) translateY(1px);
  }
</style>
