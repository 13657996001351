<script>
  import clickoutside from "./actions/clickoutside";
  import MenuItems from "./MenuItems.svelte";

  import { ensurevisible } from "./actions/ensurevisible";
  import { getContext } from "svelte";
  import { throwIf } from "./util";

  let dismissContextMenu = getContext("fnDismissContextMenu");
  throwIf(!dismissContextMenu, "needs dismissContextMenu");

  export let menu = [];
  export let pageX = 0;
  export let pageY = 0;
</script>

<nav
  class="absolute block text-black cursor-pointer z-50"
  style="top: {pageY}px; left: {pageX}px"
  on:click={dismissContextMenu}
  use:clickoutside
  use:ensurevisible
  on:clickoutside={dismissContextMenu}
>
  <MenuItems {menu} />
</nav>
