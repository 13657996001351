import { len } from "../util";

export class NodesCollection {
  /** @type {HTMLElement[]} */
  nodes = [];
  cachedMax = -1;

  clear() {
    this.nodes = [];
    this.cachedMax = -1;
  }
  rememberNode(node) {
    this.nodes.push(node);
    this.cachedMax = -1;
  }

  maxWidth() {
    if (this.cachedMax > 0) {
      return this.cachedMax;
    }
    const n = len(this.nodes);
    if (n == 0) {
      return 0;
    }
    // TODO: could use IntersectionObserver to maybe save 300 ms on 10k items
    // https://toruskit.com/blog/how-to-get-element-bounds-without-reflow/
    let max = -1;
    for (let n of this.nodes) {
      let dx = n.clientWidth;
      if (dx > max) {
        max = dx;
      }
    }
    this.cachedMax = max;
    return this.cachedMax;
  }
}

/**
 * @param {HTMLElement} node
 * @param {NodesCollection} data
 */
export function remember(node, data) {
  data.rememberNode(node);
}
