import { OneDriveConfig } from "./fs";
import {
  apiFetch,
  captureEvent,
  telegramNotify,
  throwIfFetchFailed,
  xhrGetJSONAuth,
} from "./http";
import { lsKeyOneDriveAccount } from "./user";
import { nanoid, openBrowserWindow, parseQueryString, timeSince } from "./util";
import { corruptOneDrive, setCorruptOneDrive } from "./dev";

// https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/quickStartType~/null/sourceType/Microsoft_AAD_IAM/appId/70852eac-6d04-4e85-af4e-8d9cbb312eea/objectId/4522e52d-458f-432c-9e5e-60b3dbe550cd/isMSAApp~/false/defaultBlade/Overview/appSignInAudience/PersonalMicrosoftAccount
const onedriveClientID = "70852eac-6d04-4e85-af4e-8d9cbb312eea";
// which one is it?
//const onedriveClientSecret = "affc26bc-9ca7-4f83-b11a-e63266c91460"
// const onedriveClientSecret = "fPJ8Q~Cyp0jeq1spyiSnTZk~OounAkn70-YAZcjM";

let scopes = [
  "openid",
  "profile",
  "offline_access",
  "User.Read",
  "email",
  "Files.ReadWrite.All",
];

const l = window.location;
export const onedriveRedirectURL =
  l.protocol + "//" + l.host + "/auth/onedrivelogin";

const lsKeyOneDriveCode = "fm-onedrive-code";
const lsKeyOneDriveEmail = "fm-onedrive-email";
const lsKeyOneDriveCodeVerifier = "fm-onedrive-code-verifier";

const endpointAuth =
  "https://login.microsoftonline.com/consumers/oauth2/v2.0/authorize";
export const msGraphEndpointV1 = "https://graph.microsoft.com/v1.0";

// https://github.com/curityio/pkce-javascript-example/blob/master/index.html
// https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow#request-an-authorization-code

/**
 *
 * @returns {Promise<OneDriveConfig>}
 */
export async function connectOneDrive() {
  let fn = async (resolve, reject) => {
    const challengeMethod = crypto.subtle ? "S256" : "plain";
    let codeVerifier = nanoid(64);
    localStorage.setItem(lsKeyOneDriveCodeVerifier, codeVerifier);
    let codeChallenge = await generateCodeChallenge(codeVerifier);
    var params = new URLSearchParams({
      response_type: "code",
      client_id: onedriveClientID,
      redirect_uri: onedriveRedirectURL,
      scope: scopes.join(" "),
      response_mode: "query",
      code_challenge: codeChallenge,
      code_challenge_method: challengeMethod,
    });

    let authURL = endpointAuth + `?` + params;
    console.log("authURL:", authURL);
    window.addEventListener("storage", (e) => {
      waitForOneDriveLogin(e, onCompleted);
      function onCompleted(config) {
        console.log("onCompleted:", config);
        resolve(config);
      }
    });
    openBrowserWindow(authURL, "OneDrive Login", 840, 760);
  };
  return new Promise(fn);
}

// called from index.html
export async function completeOneDriveAuth() {
  let search = window.location.search;
  console.log("completeOneDriveAuth: location.search:", search);
  let q = parseQueryString(window.location.search);
  // for easier debugging don't close the window if error
  if (!q.code) {
    if (q.error_description) {
      console.error("completeOneDriveAuth:", q.error_description);
    }
    return;
  }
  // this will trigger waitForOneDriveLogin in original window
  localStorage.setItem(lsKeyOneDriveCode, q.code);
  window.close();
}

async function waitForOneDriveLogin(e, onCompleted) {
  if (e.key !== lsKeyOneDriveCode) {
    return;
  }
  console.log(`waitForOneDriveLogin: key='%s', val='%s'`, e.key, e.newValue);
  window.removeEventListener("storage", waitForOneDriveLogin);
  const code = e.newValue;
  console.log(`waitForOneDriveLogin: code=${code}`);
  let cv = localStorage.getItem(lsKeyOneDriveCodeVerifier);
  console.log("codeVerifier:", cv);

  let params = new URLSearchParams({
    redirect_uri: onedriveRedirectURL,
    code: code,
    code_verifier: cv,
  });
  let url = "/api/onedrive/tokenfromcode?" + params;
  let response = await apiFetch(url);
  throwIfFetchFailed(response);
  let o = await response.json();
  console.log("waitForOneDriveLogin: tokenfromcode:", o);

  const accessToken = o.access_token;
  const refreshToken = o.refresh_token;

  let c = new OneDriveConfig();
  c.accessToken = accessToken;
  c.refreshToken = refreshToken;
  c.tokenExpires = calcAbsoluteDateString(o.expires_in);

  const ui = await oneDriveGetUser(c);
  console.log("waitForOneDriveLogin:", ui);
  localStorage.setItem(lsKeyOneDriveAccount, JSON.stringify(ui));

  c.email = ui.mail;
  if (!c.email) {
    c.email = ui.userPrincipalName;
  }
  c.name = ui.displayName;

  const msg = `New OneDrive connection for ${c.email} ${c.name}`;
  telegramNotify(msg);
  captureEvent("onedrive-login", {
    email: c.email,
  });
  localStorage.setItem(lsKeyOneDriveEmail, ui.email);

  localStorage.removeItem(lsKeyOneDriveCode);
  localStorage.removeItem(lsKeyOneDriveCodeVerifier);

  onCompleted(c);
}

/**
 * @param {OneDriveConfig} c
 * @returns {Promise<boolean>}
 */
export async function onedriveMaybeUpdateAccessToken(c) {
  console.log("onedriveGetAccessTokenMaybeRefresh:");
  let expiresTime = new Date(c.tokenExpires);
  let n = timeSince(expiresTime);
  if (!corruptOneDrive && n < 0) {
    console.log("onedriveGetAccessTokenMaybeRefresh: token still valid, n:", n);
    return false;
  }
  let rtok = c.refreshToken;
  if (corruptOneDrive) {
    rtok = rtok.substring(1);
    setCorruptOneDrive(false);
  }
  let params = new URLSearchParams({
    redirect_uri: onedriveRedirectURL,
    refresh_token: rtok,
  });

  let url = "/api/onedrive/refreshtoken?" + params;
  let response = await apiFetch(url);
  if (response.status == 200) {
    let o = await response.json();
    console.log("waitForOneDriveLogin: refreshed token:", o);

    // throwIf(c.refreshToken != o.refresh_token, "OneDrive refresh token changed");
    c.accessToken = o.access_token;
    c.tokenExpires = calcAbsoluteDateString(o.expires_in);
    return true;
  }
  let o = await response.json();
  console.log("onedriveMaybeUpdateAccessToken: failed response:", o);
  // throwIfFetchFailed(response);
  let config = await connectOneDrive();
  console.log("onedriveMaybeUpdateAccessToken: config:", config);
  c.accessToken = config.accessToken;
  c.tokenExpires = config.tokenExpires;
  c.refreshToken = config.refreshToken;
  return true;
}

/**
 * @param {string} durSecsStr
 * @returns {string}
 */
function calcAbsoluteDateString(durSecsStr) {
  const durMs = +durSecsStr * 60;
  let timeMs = Date.now() + durMs;
  let dateStr = new Date(timeMs).toISOString();
  console.log(`calcAbsoluteDateString: '${durSecsStr}' => '${dateStr}'`);
  return dateStr;
}

/**
 * @param {string} codeVerifier
 * @returns {Promise<string>}
 */
async function generateCodeChallenge(codeVerifier) {
  if (!crypto.subtle) {
    return codeVerifier;
  }
  var digest = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(codeVerifier)
  );

  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}

/**
 * https://docs.microsoft.com/en-us/graph/api/user-get?view=graph-rest-1.0&tabs=http
 * @param {OneDriveConfig} c
 * @returns {Promise<Object>}
 */
async function oneDriveGetUser(c) {
  await onedriveMaybeUpdateAccessToken(c);
  let url = `${msGraphEndpointV1}/me?access_token=${c.accessToken}`;
  let o = await xhrGetJSONAuth(url, c.accessToken, null);
  console.log("oneDriveGetUser:", o);
  return o;
}

// TODO: logout
// remove tokens and call the API
// https://docs.microsoft.com/en-us/onedrive/developer/rest-api/getting-started/graph-oauth?view=odsp-graph-online
