import { writable } from "svelte/store";
import { len } from "./util";

// a temporary message to show activity
export let currentMessage = writable("");

let messagesStack = [];

// adds operation, currentMessage is the last one pushed
// returns function to call to remove this message
export function showMessage(s) {
  // console.log("showMessage:", s);
  messagesStack.push(s);
  currentMessage.set(s);
  function removeMessage() {
    // console.log("removeMessage:", s);
    let pos = messagesStack.indexOf(s);
    if (pos == -1) {
      return;
    }
    messagesStack.splice(pos, 1);
    let newCurrent = "";
    if (len(messagesStack) > 0) {
      newCurrent = messagesStack.at(-1);
    }
    currentMessage.set(newCurrent);
  }
  return removeMessage;
}

export function showTemporaryMessage(s, timeout = 5000) {
  let remove = showMessage(s);
  setTimeout(remove, timeout);
}
