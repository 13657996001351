<div class="bg-gray-50 flex flex-row">
  <div class="ml-2 font-semibold text-blue-900/75">Filerion</div>
  <div class="ml-2">
    is a <a
      href="https://blog.kowalczyk.info/article/690e6dc957aa421082cbe7b42936e09c/filerion-documentation.html"
      target="_blank"
      class="text-blue-700 hover:underline hover:underline-offset-2"
      >file manager for online storage (s3, Dropbox and more)</a
    >
  </div>
  <div class="flex-grow" />
  <a
    class="hover:bg-gray-200 px-2"
    href="https://github.com/kjk/filerion-public/discussions"
    target="_blank">Discuss</a
  >
  <a
    class="hover:bg-gray-200 px-2"
    href="https://github.com/kjk/filerion-public/issues"
    target="_blank">Issues</a
  >
  <a
    class="hover:bg-gray-200 px-2"
    href="https://filerion.canny.io/feature-requests"
    target="_blank">Vote for features</a
  >
  <a
    class="hover:bg-gray-200 px-2"
    href="https://twitter.com/kjk"
    target="_blank">Twitter</a
  >
</div>
