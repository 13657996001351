// like filepath package in Go

import { len } from "./util";

class DirName {
  dir = "";
  name = "";
  constructor(dir, name) {
    this.dir = dir;
    this.name = name;
  }
}

/**
 * splits a path into directory and file name
 * foo/bar/z.txt => "foo/bar", "z.txt"
 * @param {string} path
 * @returns {DirName}
 */
export function split(path) {
  let parts = path.split("/");
  const n = len(parts);
  if (n == 1) {
    return new DirName("/", path);
  }
  const name = parts[n - 1];
  const dirParts = parts.slice(0, n - 1);
  let dir = dirParts.join("/");
  if (dir == "") {
    dir = "/";
  }
  return new DirName(dir, name);
}

/**
 * "/foo/bar/el.txt" => "/foo/bar"
 * @param {string} path
 * @returns {string}
 */
export function dir(path) {
  const dn = split(path);
  return dn.dir;
}

/**
 * "/foo/bar/el.txt" => "el.txt"
 * @param {string} path
 * @returns {string}
 */
export function base(path) {
  const dn = split(path);
  return dn.name;
}

/**
 * @param {string} dir
 * @param {string} name
 * @returns {string}
 */
export function join(dir, name) {
  let n = 0;
  if (dir.endsWith("/")) {
    n++;
  }
  if (name.startsWith("/")) {
    n++;
  }
  if (n == 2) {
    return dir + name.substring(1);
  }
  if (n == 1) {
    return dir + name;
  }
  return dir + "/" + name;
}

/**
 * given "foo/" returns "foo"
 * @param {string} s
 * @returns {string}
 */
export function stripDirSlash(s) {
  if (s == "/" || !s.endsWith("/")) {
    return s;
  }
  return s.substring(0, len(s) - 1);
}

/**
 * return index of extension or -1 if no extension
 * @param {string} name
 * @returns {number}
 */
export function indexOfExt(name) {
  let lastIdx = -1;
  while (true) {
    let idx = name.indexOf(".", lastIdx + 1);
    if (idx < 0) {
      return lastIdx;
    }
    lastIdx = idx;
  }
}

/**
 * return file extension from name
 * @param {string} name
 * @returns {string}
 */
export function ext(name) {
  let idx = indexOfExt(name);
  if (idx < 0) {
    return "";
  }
  return name.substring(idx);
}
