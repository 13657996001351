<script>
  import Close from "./icons/Close.svelte";
  import { draggable } from "@neodrag/svelte";
  import { onDestroy } from "svelte";
  import { trapFocus } from "./util";
  import { positionModal } from "./actions/positionnode";

  // based on:
  // https://svelte.dev/examples/modal
  export let title = "";
  export let open = false;
  // TODO: always true?
  export let closeOnEsc = true;

  let overlay = null;
  let modal = null;
  let dragHandle = null;

  function close() {
    open = false;
  }

  /**
   * @param {MouseEvent} e
   */
  function handleClick(e) {
    if (e.target == overlay) {
      // clicked on overlay => dismiss
      e.stopPropagation();
      close();
    }
  }

  /**
   * @param {KeyboardEvent} e
   */
  function handleKeyDown(e) {
    // console.log("DialogBase: handleKeyDown:", e);
    if (closeOnEsc && e.key === "Escape") {
      close();
      // e.preventDefault();
      return;
    }

    if (modal && e.key === "Tab") {
      trapFocus(modal, e);
      e.preventDefault();
      return;
    }

    // needed to prevent arrow up / down etc. in FileList
    e.stopPropagation();
  }

  const previouslyFocused = /** @type {HTMLElement} */ (
    typeof document !== "undefined" && document.activeElement
  );
  if (previouslyFocused) {
    onDestroy(() => {
      previouslyFocused.focus();
    });
  }
</script>

{#if open}
  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <div
    tabindex="0"
    class="fixed inset-0 z-40 bg-gray-600 bg-opacity-40"
    bind:this={overlay}
    on:click={handleClick}
    on:keydown={handleKeyDown}
  >
    <div
      role="dialog"
      class="absolute w-fit min-w-[360px] max-w-[80vw] shadow-md bg-white flex flex-col"
      bind:this={modal}
      use:draggable={{ handle: dragHandle }}
      use:positionModal
    >
      <!-- title area -->
      <div
        class="flex items-center px-8 py-4 border-t-[8px] border-blue-300  cursor-grab"
        bind:this={dragHandle}
      >
        <div class="grow font-semibold text-xl">
          {title}
        </div>
        <button class="hover:bg-gray-200" on:click={close}>
          <Close size={24} />
        </button>
      </div>

      <div class="bg-white px-8">
        <slot name="main" />
      </div>

      <div class="bg-gray-50 px-8 py-4 mt-8">
        <slot name="bottom" />
      </div>
    </div>
  </div>
{/if}
