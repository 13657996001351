import { len, startTimer, throwIf } from "./util";
import { apiFetch, mkURL, throwIfFetchFailed, xhrPut } from "./http";
import { FSEntry, newDirFSEntry } from "./fsentry";
import { FS, fsTypeBackBlaze } from "./fs";
import { s3SanitizeDir, s3SanitizePath } from "./fs-s3";

let disablePreCaching = true;

class FileSystemBackBlaze extends FS {
  /**
   * @param { import("./fs").BackBlazeConfig } config
   */
  constructor(config) {
    super(fsTypeBackBlaze);
    this.bbConfig = config;
  }

  /**
   * @param {string} dirPath - directory name
   * @param {boolean} force - if true, don't use cached values
   * @param {import("svelte/store").Writable} progress
   * @return {Promise<FSEntry[]>}
   */
  async readDir(dirPath, force, progress) {
    console.log("FileSystemS3.readDir:", dirPath);
    throwIf(dirPath == "", `empty dirName`);

    progress.set(
      `reading dir ${dirPath}, ${this.dirs.size} dirs, ${this.filesCount} files`
    );
    if (!force) {
      const de = this.dirs.get(dirPath);
      if (de) {
        console.log(`FileSystemS3.get: got from cache for ${dirPath}`);
        return de.fsentries;
      }
    }

    const dur = startTimer();
    let entries = await bbGetFiles(this.bbConfig, dirPath);
    console.log(
      `"FileSystemS3.readDir: got ${len(
        entries
      )} entries for ${dirPath} in ${dur()} ms`
    );
    let dir = newDirFSEntry(dirPath, entries);
    this.cacheDir(dir);

    if (!disablePreCaching && dirPath == "/" && this.dirs.size == 1) {
      this.readDirRecur(["/"]);
    }
    return dir.fsentries;
  }

  /**
   * @param {string} path
   * @returns {Promise<string>}
   */
  async getPublicURL(path) {
    console.log(`bb.getPublicURL: path=${path}`);

    // TODO: maybe cache the links?
    let c = this.bbConfig;
    let url = mkURL(
      `/api/bb/getpublicurl`,
      "appKeyId",
      c.appKeyId,
      "appKey",
      c.appKey,
      "bucketName",
      c.bucketName,
      "path",
      path
    );

    let response = await apiFetch(url);
    throwIfFetchFailed(response);
    let js = await response.json();
    url = js.url;
    console.log("bb.getPublicURL: url:", url);
    return url;
  }

  /**
   * @param {string} path
   * @param {Blob} file
   * @param {import("./http").HttpProgress} httpProgress
   */
  async uploadFile(path, file, httpProgress) {
    console.log("bb.uploadFile: ${path}");
    path = s3SanitizePath(path);
    let c = this.bbConfig;
    let url = mkURL(
      `/api/bb/upload`,
      "appKeyId",
      c.appKeyId,
      "appKey",
      c.appKey,
      "bucketId",
      c.bucketId,
      "path",
      path
    );
    let rsp = await xhrPut(url, file, httpProgress);
    console.log(rsp.response);
  }

  /**
   * @param {string} path
   * @param {string} newPath
   */
  async rename(path, newPath) {
    let e = this.findFirstEntryOfType(path, false);
    let sourceFileId = e.id;
    console.log(
      `bb.rename: path=${path} sourceFileId: ${e.id}, newPath=${newPath}`
    );
    let c = this.bbConfig;
    let url = mkURL(
      `/api/bb/rename`,
      "appKeyId",
      c.appKeyId,
      "appKey",
      c.appKey,
      "sourceFileId",
      sourceFileId,
      "newPath",
      newPath
    );
    let response = await apiFetch(url);
    throwIfFetchFailed(response);
  }

  /**
   * @param {string} path - full path for the directory
   */
  async createDirectory(path) {
    console.log(`bb.createDirectory: path=${path}`);
    let c = this.bbConfig;
    let url = mkURL(
      `/api/bb/createdirectory`,
      "appKeyId",
      c.appKeyId,
      "appKey",
      c.appKey,
      "bucketId",
      c.bucketId,
      "path",
      path
    );
    let response = await apiFetch(url);
    throwIfFetchFailed(response);
  }

  /**
   * @param {string} path
   */
  async deleteFile(path) {
    path = s3SanitizePath(path);
    let c = this.bbConfig;
    let url = mkURL(
      `/api/bb/deletefile`,
      "appKeyId",
      c.appKeyId,
      "appKey",
      c.appKey,
      "bucketId",
      c.bucketId,
      "path",
      path
    );
    console.log(`bb.deleteFile: path: ${path}, uri: ${url}`);
    let rsp = await apiFetch(url);
    await throwIfFetchFailed(rsp);
  }
}

/*
 * @param {import("./fs").BackBlazeConfig} config
 * @param {string} dir
 * @returns {Promise<FSEntry[]>}
 */
async function bbGetFiles(c, dir) {
  dir = s3SanitizeDir(dir);
  let url = mkURL(
    `/api/bb/list`,
    "appKeyId",
    c.appKeyId,
    "appKey",
    c.appKey,
    "bucketId",
    c.bucketId,
    "dir",
    dir
  );
  console.log(`bb.getFiles: dir: '${dir}'`);
  let rsp = await apiFetch(url);
  await throwIfFetchFailed(rsp);
  let entriesMeta = await rsp.json();
  /** @type {FSEntry[]} */
  let res = [];
  for (let meta of entriesMeta) {
    let e = FSEntry.fromMeta(meta);
    res.push(e);
  }
  return res;
}

/**
 * @param {import("./fs").BackBlazeConfig} c
 * @returns FileSystemBackBlaze
 */
export function makeFileSystemBackBlaze(c) {
  let res = new FileSystemBackBlaze(c);
  res.bbConfig = c;
  return res;
}
