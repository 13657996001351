import { fsTypeGoogleDrive, FS, GoogleDriveConfig, fsCopyProps } from "./fs";
import { len, startTimer, dateStringToMs } from "./util";
import * as filepath from "./filepath";
import {
  mkURL,
  throwIfXhrFailed,
  xhrAsync,
  xhrGetJSONAuth,
  XhrOpts,
  xhrPut,
} from "./http";
import { FSEntry, newDirFSEntry } from "./fsentry";
import { getAccessTokenMaybeRefresh } from "./fs-gdrive-login";

let disablePreCaching = true;

class FileSystemGoogleDrive extends FS {
  /** @type {GoogleDriveConfig} */
  constructor(config) {
    super(fsTypeGoogleDrive);
    this.googleDriveConfig = config;
  }

  async getAccessToken() {
    let accessToken = getAccessTokenMaybeRefresh(this.googleDriveConfig);
    return accessToken;
  }

  /**
   * @param {string} dirPath - directory name
   * @param {boolean} force - if true, don't use cached values
   * @param {import("svelte/store").Writable} progress
   * @return {Promise<FSEntry[]>}
   */
  async readDir(dirPath, force, progress) {
    console.log("FileSystemGoogleDrive.readDir:", dirPath);
    progress.set(
      `reading dir ${dirPath}, ${this.dirs.size} dirs, ${this.filesCount} files`
    );
    if (!force) {
      const de = this.dirs.get(dirPath);
      if (de) {
        console.log(`FileSystemGoogleDrive.get: got from cache for ${dirPath}`);
        return de.fsentries;
      }
    }
    if (dirPath != "/") {
      await this.readParentDirs(dirPath, force, progress);
    }

    const dur = startTimer();
    let accessToken = await this.getAccessToken();
    let parentId = "root";
    if (dirPath != "/") {
      let de = this.findFirstEntryOfType(dirPath, true);
      parentId = de.id;
    }
    let entries = await gdriveGetEntriesInDir(accessToken, dirPath, parentId);
    console.log(
      `FileSystemGoogleDrive.readDir, gdriveGetEntriesInDir: got ${len(
        entries
      )} entries for ${dirPath} in ${dur()} ms`
    );

    let dir = newDirFSEntry(dirPath, entries);
    this.cacheDir(dir);

    if (!disablePreCaching && dirPath == "/" && this.dirs.size == 1) {
      this.readDirRecur(["/"]);
    }
    return dir.fsentries;
  }

  /**
   * get publicly visible URL
   * @param {string} path
   * @returns {Promise<string>}
   */
  async getPublicURL(path) {
    console.log(`FileSystemGoogleDrive.getPublicURL: path=${path}`);

    let file = await this.downloadFile(path, null);
    let uri = URL.createObjectURL(file);
    return uri;
  }

  /**
   * @param {string} path
   * @param {import("./http").HttpProgress} httpProgress
   * @returns {Promise<Blob>}
   */
  async downloadFile(path, httpProgress) {
    let e = this.findFirstEntryOfType(path, false);
    let accessToken = await this.getAccessToken();
    let blob = await gdriveDownloadFile(accessToken, e.id, httpProgress);
    return blob;
  }

  /**
   * @param {string} path
   * @param {Blob} file
   * @param {import("./http").HttpProgress} httpProgress
   */
  async uploadFile(path, file, httpProgress) {
    let parentId = this.getParentDirectoryId(path);
    let dn = filepath.split(path);
    let accessToken = await this.getAccessToken();
    await gdriveUploadFile(accessToken, file, parentId, dn.name, httpProgress);
  }

  /**
   * rename a file
   * @param {string} path
   * @param {string} newPath
   */
  async rename(path, newPath) {
    path = filepath.stripDirSlash(path);
    let all = this.findEntries(path);
    let e = all[0];
    console.log(`rename: name: ${path}, newName: ${newPath} isDir: ${e.isDir}`);
    let accessToken = await this.getAccessToken();
    let newName = filepath.base(newPath);
    await gdriveRename(accessToken, e.id, newName);
  }

  /**
   * @param {string} dirPath - full path for the directory
   */
  async createDirectory(dirPath) {
    let accessToken = await this.getAccessToken();
    let parentId = this.getParentDirectoryId(dirPath);
    let dn = filepath.split(dirPath);
    let dirName = dn.name;
    await gdriveCreateDirectory(accessToken, dirName, parentId);
  }

  getParentDirectoryId(path) {
    let dn = filepath.split(path);
    if (dn.dir == "/") {
      return "root";
    }
    let e = this.findFirstEntryOfType(dn.dir, true);
    return e.id;
  }

  /**
   * @param {string} path
   */
  async deleteFile(path) {
    path = filepath.stripDirSlash(path);
    let all = this.findEntries(path);
    let e = all[0];
    console.log(`deleteFile: ${path}, isDir: ${e.isDir}`);
    let accessToken = await this.getAccessToken();
    await gdriveTrashFile(accessToken, e.id);
  }
}

// https://developers.google.com/drive/api/guides/mime-types
const mimeFolder = "application/vnd.google-apps.folder";
const mimeGoogleDoc = "application/vnd.google-apps.document";
const mimeGoogleSheet = "application/vnd.google-apps.spreadsheet";
const mimeGoogleDrawing = "application/vnd.google-apps.drawing";
const mimeGooglePresentation = "application/vnd.google-apps.presentation";

// those files can be downloaded by converting them to those types
function extFromMimeType(mt) {
  if (mt == mimeGoogleDoc) {
    return ".docx";
  }
  if (mt == mimeGoogleSheet) {
    return ".xlsx";
  }
  if (mt == mimeGooglePresentation) {
    return ".pptx";
  }
  if (mt == mimeGoogleDrawing) {
    return ".jpg";
  }
  return "";
}

/*
fields
files(kind,name,size,id,md5Checksum)

q
"root" in parents

q
(mimeType="application/vnd.google-apps.folder") and ("root" in parents) and (trashed = false)

fields
files(*)

fields
files(id,name,mimeType,resourceKey,size,md5Checksum,createdTime,modifiedTime)

https://www.codeeval.dev/edit?gistid=9407acb13f23e528eb04bb0012e3a9a4
*/

// https://developers.google.com/drive/api/v3/reference/files#resource
export const prop_gd_description = "description";
export const prop_gd_originalFilename = "originalFilename";
export const prop_gd_mimeType = "mimeType";
export const prop_gd_resourceKey = "resourceKey";
export const prop_gd_md5Checksum = "md5Checksum";
export const prop_gd_createdTime = "createdTime";
export const prop_gd_folderColorRgb = "folderColorRgb";
export const prop_gd_version = "version";
export const prop_gd_parents = "parents";
export const prop_gd_webContentLink = "webContentLink";
export const prop_gd_webViewLink = "webViewLink";
// iconLink
// shared
// fullFileExtension ???
// fileExtension ???
// quotaBytesUsed ???
// imageMediaMetadata
// videoMediaMetadata

const fileProps = [
  prop_gd_description,
  prop_gd_originalFilename,
  prop_gd_mimeType,
  prop_gd_resourceKey,
  prop_gd_md5Checksum,
  prop_gd_createdTime,
  prop_gd_folderColorRgb,
  prop_gd_version,
  prop_gd_parents,
  prop_gd_webContentLink,
  prop_gd_webViewLink,
];

// https://developers.google.com/drive/api/v3/reference/files/list
// https://stackoverflow.com/questions/24720075/how-to-get-list-of-files-by-folder-on-google-drive-api
async function gdriveGetEntriesInDir(accessToken, dir, dirId) {
  console.log("gdriveGetEntriesInDir");

  let fields =
    "files(kind,id,name,size,description,originalFilename,mimeType,resourceKey,md5Checksum,createdTime,modifiedTime,parents,folderColorRgb,version)";
  let url = `https://www.googleapis.com/drive/v3/files`;
  let params = {
    pageSize: 1000, // default 100
    q: `("${dirId}" in parents) and (trashed = false)`,
    fields,
  };

  let entries = [];
  let js;
  let pageToken = "";
  while (true) {
    js = await xhrGetJSONAuth(url, accessToken, params);
    entries.push(...js.files);
    pageToken = js.nextPageToken;
    if (!pageToken) {
      console.log("finished because no pageToken");
      break;
    }
    console.log("pageToken:", pageToken);
    console.log("files so far:", len(entries));
    params.pageToken = pageToken;
  }
  console.log(`got ${len(entries)} entries`);

  let fsentries = [];
  for (let e of entries) {
    let isFolder = e.mimeType === mimeFolder;
    let size = isFolder ? -1 : e.size;
    let lastMod = dateStringToMs(e.modifiedTime);
    let entries = isFolder ? [] : null;
    let meta = [e.id, e.name, size, lastMod, entries];
    fsCopyProps(fileProps, e, meta);
    let fe = FSEntry.fromMeta(meta);
    fsentries.push(fe);
  }

  return fsentries;
}

/**
 * @param {string} accessToken
 * @param {string} fileId
 * @param {Object} props
 */
async function gdriveUpdateFileProps(accessToken, fileId, props) {
  // TODO: validate properties
  let url = `https://www.googleapis.com/drive/v3/files/${fileId}`;
  await gdrivePatchJSON(url, accessToken, props);
}

/**
 * @param {string} accessToken
 * @param {string} fileId
 * @param {import("./http").HttpProgress} httpProgress
 * @returns
 */
async function gdriveDownloadFile(accessToken, fileId, httpProgress) {
  let url = `https://www.googleapis.com/drive/v3/files/${fileId}`;
  let params = {
    alt: "media",
  };
  let blob = await gdriveGetBlob(url, accessToken, httpProgress, params);
  return blob;
}

async function gdriveCreateDirectory(accessToken, name, parentFileId) {
  // let ids = await gdriveGenerateIds(accessToken, 1);
  // console.log("gdriveCreateDirectory:", ids);
  let url = "https://www.googleapis.com/drive/v3/files";
  let file = {
    name: name,
    mimeType: mimeFolder,
    parents: [parentFileId],
  };
  let data = JSON.stringify(file);
  await gdrivePostJSON(url, accessToken, data);
}

async function gdriveGenerateIds(accessToken, n) {
  let url = "https://www.googleapis.com/drive/v3/files/generateIds";
  let params = {
    count: n,
  };
  let o = await xhrGetJSONAuth(url, accessToken, params);
  return o.ids;
}

/**
 * https://developers.google.com/drive/api/guides/manage-uploads#resumable
 * @param {string} accessToken
 * @param {Blob} file
 * @param {string} parentDirId
 * @param {string} fileName
 * @param {import("./http").HttpProgress} httpProgress
 */
async function gdriveUploadFile(
  accessToken,
  file,
  parentDirId,
  fileName,
  httpProgress
) {
  let url = mkURL(
    `https://www.googleapis.com/upload/drive/v3/files`,
    "uploadType",
    "resumable"
  );
  let meta = {
    name: fileName,
    // TODO: mimeType from extension. But it seems google does it for us, so
    // maybe not needed
    parents: [parentDirId],
  };
  let data = JSON.stringify(meta);
  let xhr = await gdrivePostJSON(url, accessToken, data);
  throwIfXhrFailed(xhr);
  let uploadURL = xhr.getResponseHeader("Location");
  console.log("gdriveUploadFile: Location:", uploadURL);
  await xhrPut(uploadURL, file, httpProgress);
}

async function gdriveDeleteFile(accessToken, fileId, httpProgress) {
  let url = `https://www.googleapis.com/drive/v3/files/${fileId}`;
  let params = {
    alt: "media",
  };
  let blob = await gdriveGetBlob(url, accessToken, httpProgress, params);
  return blob;
}

/**
 * Set trash = true metadata field on a file which is soft-delete
 * @param {string} accessToken
 * @param {string} fileId
 */
async function gdriveTrashFile(accessToken, fileId) {
  let url = `https://www.googleapis.com/drive/v3/files/${fileId}`;
  let params = {
    trashed: true,
  };
  let data = JSON.stringify(params);
  await gdrivePatchJSON(url, accessToken, data);
}

/**
 * @param {string} accessToken
 * @param {string} fileId
 * @param {string} newName
 */
async function gdriveRename(accessToken, fileId, newName) {
  let url = `https://www.googleapis.com/drive/v3/files/${fileId}`;
  let params = {
    name: newName,
  };
  let data = JSON.stringify(params);
  await gdrivePatchJSON(url, accessToken, data);
}

// TODO: why this doesn't work with fetch?
// I think origin header was missing.
// is it about Authorization header?
// maybe needed credentials: "include" in opts

/**
 * @param {string} url
 * @param {string} accessToken
 * @param {Blob|string} data
 * @returns {Promise<XMLHttpRequest>}
 */
async function gdrivePostJSON(url, accessToken, data) {
  /** @type {import("./http").XhrOpts} */
  let opts = new XhrOpts();
  opts.method = "POST";
  opts.data = data;
  opts.responseType = "json";
  opts.headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  let xhr = await xhrAsync(url, opts);
  throwIfXhrFailed(xhr);
  return xhr;
}

/**
 * @param {string} url
 * @param {string} accessToken
 * @param {Blob|string} data
 * @returns {Promise<Object>}
 */
async function gdrivePatchJSON(url, accessToken, data) {
  /** @type {import("./http").XhrOpts} */
  let opts = new XhrOpts();
  opts.method = "PATCH";
  opts.data = data;
  opts.responseType = "json";
  opts.headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  let xhr = await xhrAsync(url, opts);
  throwIfXhrFailed(xhr);
  return xhr.response;
}

/**
 * @param {string} url
 * @param {string} accessToken
 * @param {import("./http").HttpProgress} httpProgress
 * @param {Object} params
 * @returns {Promise<Blob>}
 */
async function gdriveGetBlob(url, accessToken, httpProgress, params = {}) {
  // params.key = gdriveApiKey;
  /** @type {import("./http").XhrOpts} */
  let opts = new XhrOpts();
  opts.params = params;
  opts.httpProgress = httpProgress;
  opts.responseType = "blob";
  opts.headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  let xhr = await xhrAsync(url, opts);
  throwIfXhrFailed(xhr);
  return xhr.response;
}

/**
 * @param {string} url
 * @param {string} accessToken
 * @param {import("./http").HttpProgress} httpProgress
 * @param {Object} params
 * @returns {Promise<void>}
 */
async function gdriveDelete(url, accessToken, httpProgress, params = {}) {
  // params.key = gdriveApiKey;
  /** @type {import("./http").XhrOpts} */
  let opts = new XhrOpts();
  opts.method = "DELETE";
  opts.params = params;
  opts.httpProgress = httpProgress;
  opts.headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  let xhr = await xhrAsync(url, opts);
  throwIfXhrFailed(xhr);
}

// https://developers.google.com/drive/api/v3/reference/files/get

/**
 *
 * @param {GoogleDriveConfig} c
 * @returns {FileSystemGoogleDrive}
 */
export function makeFileSystemGoogleDrive(c) {
  let res = new FileSystemGoogleDrive(c);
  return res;
}
